import { Link } from "react-router-dom";
import { format } from 'date-fns';

import { utilsService } from "@services/utils.service";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import LaunchIcon from "@material-ui/icons/Launch";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      width: theme.spacing(1),
      height: theme.spacing(1),
      position: 'absolute'
    },
    "&::-webkit-scrollbar-track": {
      background: '#f0f1f2',
      borderRadius: '100px'
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: `1px solid slategrey`,
    },
  },
  tableContainer: {
    minWidth: 650,
  },
  date: {
    whiteSpace: 'nowrap'
  }
}));

const formatAction = (action: string, object: string) => {
  let s = '';
  switch (action) {
    case 'contractInteraction': break;
    default: s = action; break;
  }
  if (s && object) {
    return s + ' ' + object;
  } else if (s) {
    return s;
  }
  return object;
};

const TxnLink = ({ network, txnHash }) => {
  const prefix = utilsService.getNetworkTxnLink(network);
  const url = (prefix ? `${prefix}${txnHash}` : '');
  return (
    <>
      {url && (
        <Link to={{ pathname: url }} style={{ color: "#000" }} target='_blank'>
          {utilsService.shrinkWalletAddress(txnHash)}
        </Link>
      )}
    </>
  );
};

const TxnLinkIcon = ({ network, txnHash }) => {
  const prefix = utilsService.getNetworkTxnLink(network);
  const url = (prefix ? `${prefix}${txnHash}` : '');
  return (
    <>
      {url && (
        <Link to={{ pathname: url }} style={{ color: "#bbc2c9" }} target='_blank'>
          <LaunchIcon style={{ marginLeft: 2 }} />
        </Link>
      )}
    </>
  );
};

export default function BasicTable({ data }) {
  const classes = useStyles();
  return (
    <TableContainer className={classes.root}>
      <Table className={classes.tableContainer}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Time (local)</TableCell>
            <TableCell align='center'>Network</TableCell>
            <TableCell align='center'>Action</TableCell>
            <TableCell align='center'>Contract</TableCell>
            <TableCell align='right'>Sent</TableCell>
            <TableCell align='right'>Received</TableCell>
            <TableCell align='center'>Fee</TableCell>
            <TableCell align='center'>Txn Hash</TableCell>
            <TableCell align='center'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item: any, index: number) => (
            <TableRow key={index}>
              <TableCell align='center' className={classes.date}>{format(new Date(item.txnDate), 'dd-MMM-yyyy')}<br />{format(new Date(item.txnDate), 'H:mm a')}</TableCell>
              <TableCell align='center'>{utilsService.getNetworkName(item.network)}<br />{utilsService.shrinkWalletAddress(item.walletAddress)}</TableCell>
              <TableCell align='center'><span dangerouslySetInnerHTML={{ __html: formatAction(item.action, item.object) }}></span></TableCell>
              <TableCell align='center'>{item.contract?.name}<br />{utilsService.shrinkWalletAddress(item.contract?.address)}</TableCell>
              <TableCell align='right'>
                {item.assetsSent?.filter((x: any) => x.amount || x.asset?.symbol).map((sent: any, index: number) => (
                  <div key={index}>{utilsService.truncate(sent.asset?.symbol, 12)} <b>{utilsService.shrinkNumber(sent.amount)}</b></div>
                ))}
              </TableCell>
              <TableCell align='right'>
                {item.assetsReceived?.filter((x: any) => x.amount || x.asset?.symbol).map((recv: any, index: number) => (
                  <div key={index}>{utilsService.truncate(recv.asset?.symbol, 12)} <b>{utilsService.shrinkNumber(recv.amount)}</b></div>
                ))}
              </TableCell>
              <TableCell align='center'>{item.fee?.amount ? item.fee?.currency : ''} {item.fee?.amount ? Number.parseFloat(item.fee.amount).toFixed(6) : ''}</TableCell>
              <TableCell align='center'>
              <TxnLink network={item.network} txnHash={item.txnHash} />
              </TableCell>
              <TableCell align='center'>
                <Tooltip title='Txn details'>
                <TxnLinkIcon network={item.network} txnHash={item.txnHash} />
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
