
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Menu, MenuItem, Typography } from "@material-ui/core";
import ArrowDown from '@assets/images/arrowDown.svg';
import IconsCollectionBadge from "@shared/UI/IconsCollectionBadge";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  select: {
    height: 62,
    width: '100%',
    background: '#F4F2F0',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    padding: '0 21px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titles: {
    marginRight: 8,
  },
  extraTitle: {
    fontSize: '0.8125rem',
    color: '#808191',
    [theme.breakpoints.down('xs')]: {
      width: '280px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  disabled: {
    background: 'rgba(0, 0, 0, 0.1)',
  },
  selectedItemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

interface ITradeSelect {
  data: {
    value: string,
    title: string,
    extraTitle?: string;
    mainIcon?: string;
    protocolIcon?: string;
    placeholder?: boolean;
  }[];
  value: string;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e) => void;
  loading?: boolean;
  error?: boolean;
}

export const TradeSelect = ({ data, value, title, disabled, onChange, loading, error }: ITradeSelect) => {
  const classes = useStyles();

  const [selectedElement, setSelectedElement] = useState(value);

  let selectedValue = data.find(item => item.value === selectedElement);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled || error || loading) return;
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setSelectedElement(value);
  }, [value])

  const renderSelect = () => {
    if (!loading && error) {
      return <Typography style={{ color: '#70777F' }}>Something went wrong.</Typography>
    }

    if (loading) {
      return <Typography style={{ color: '#70777F' }}>Loading...</Typography>
    }

    if (!loading && !error) {
      return (
        <div className={classes.selectedItemWrapper}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {selectedValue?.mainIcon && (
              <IconsCollectionBadge mainIcon={selectedValue.mainIcon} protocolIcon={selectedValue.protocolIcon} />
            )}
            <div className={classes.titles}>
              <Typography style={{
                fontSize: selectedValue?.placeholder ? '1rem' : '1.125rem',
                color: selectedValue?.placeholder ? '#70777F' : '#333042',
              }}>
                {selectedValue?.title}
              </Typography>
              {selectedValue?.extraTitle && <Typography className={classes.extraTitle}>
                {selectedValue?.extraTitle}
              </Typography>}
            </div>
          </div>
          <img src={ArrowDown} alt="" />
        </div>
      );
    }
  };

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {
          data.map((item, index) => <MenuItem selected={item.value === selectedElement}
            style={item.placeholder ? { color: '#70777F' } : {}}
            key={index}
            onClick={() => {
              if (onChange) onChange(item);
              setSelectedElement(item.value);
              setAnchorEl(null);
            }}>
            {item?.mainIcon && (
              <IconsCollectionBadge mainIcon={item.mainIcon} protocolIcon={item.protocolIcon} />
            )}
            <div className={classes.titles}>
              <Typography style={{ fontSize: '1.125rem' }}>{item?.title}</Typography>
              {item?.extraTitle && <Typography className={classes.extraTitle}>
                {item?.extraTitle}
              </Typography>}
            </div>
          </MenuItem>)
        }
      </Menu>
      {title && <Typography style={{ fontSize: '0.8125rem', marginBottom: 4, fontWeight: 500, color: disabled ? 'rgba(0, 0, 0, 0.38)' : '' }}>{title}</Typography>}
      <div className={clsx(classes.select, disabled && classes.disabled)} onClick={handleClick as any}>
        {renderSelect()}
      </div>
    </>
  );
};
