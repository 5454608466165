
import { useState } from "react";

import ModuleLayout from "@shared/Layouts/ModuleLayout/ModuleLayout";
import PaperLayout from "@shared/Layouts/PaperLayout/PaperLayout";
import CanvasModal from "@shared/UI/CanvasModal";
import CanvasButton from "@shared/UI/CanvasButton";

import { TradeModal } from "./TradeModal/TradeModal";
import { TradeTable } from "./TradeTable";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const mobileView = 'sm';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down(mobileView)]: {
      flexDirection: 'column',
    },
  },
  headerTitle: {
    fontSize: '1.5rem',
    [theme.breakpoints.down(mobileView)]: {
      marginBottom: 20,
    },
  },
  headerFilters: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(mobileView)]: {
      flexDirection: 'column-reverse',
      width: '100%',
    },
  },
  draftBtn: {
    marginRight: 21,
    [theme.breakpoints.down(mobileView)]: {
      marginRight: 0,
      marginTop: 15
    },
  },
}));

export const Trade = () => {
  const [open, setOpen] = useState(false);
  const [timestamp, setTimestamp] = useState(0);

  const openModal = () => {
    setOpen(true);
  }

  const closeModal = () => {
    setOpen(false);
    setTimestamp(new Date().getTime());
  }

  const classes = useStyles();
  return (
    <ModuleLayout>
      <CanvasModal open={open}
        onClose={() => closeModal()}
        content={<TradeModal closeModal={() => closeModal()} />}
        options={{
          title: "Create Trade",
          maxWidth: "lg",
        }}
        setOpen={setOpen}
      />
      <PaperLayout title={""} options={{
        renderHeader: (<div className={classes.header}>
          <Typography variant="h2" className={classes.headerTitle}>Trades</Typography>
          <div className={classes.headerFilters}>
            <CanvasButton style={{ width: 213 }} onClick={() => openModal()} >Create trade</CanvasButton>
          </div>
        </div>)
      }}>
        <TradeTable openModal={openModal} refreshTimestamp={timestamp} />
      </PaperLayout>
    </ModuleLayout>
  );
};
