import React, { useEffect, useState, useCallback, useMemo } from "react";
import DashboardDiagrams from "@pages/Dashboard/components/DashboardDiagram/DashboardDiagrams";

import { utilsService } from "@services/utils.service";
import { xlsxBalance } from "@services/excel";

import "./Dashboard.scss";
import "./components/Tables/CategoryTable.scss";
import { DashboardWithTables } from "./DashboardWithTables";
import DashboardCategories from "./DashboardCategories";
import { GrandTotalBalance } from "./components/GrandTotalBalance/GrandTotalBalance";
import PortfolioPerformanceChart from "../Analytics/components/portfolio/PortfolioPerformanceChart";
import DashboardSummaryTotal from "./components/DashboardSummaryTotal/DashboardSummaryTotal";

import FiatIcon from "@assets/images/Wallet.svg";
import OffchainIcon from "@assets/images/Wallet.svg";
import ExchangeIcon from "@assets/images/Wallet.svg";
import WalletIcon from "@assets/images/Wallet.svg";
import DepositIcon from "@assets/images/Graph.svg";
import DebtIcon from "@assets/images/Danger.svg";
import PoolIcon from "@assets/images/Category.svg";
import StackedIcon from "@assets/images/Ticket-Star.svg";
import ClaimableIcon from "@assets/images/Star.svg";
import OtherIcon from "@assets/images/invest-icon.svg";

import useAuthStore from "@hooks/globalStores/useAuthStore";
import useWalletStore from "@hooks/globalStores/useWalletStore";
import ModuleLayout from "@shared/Layouts/ModuleLayout/ModuleLayout";
import RefreshButton from "@shared/UI/RefreshButton";
import DashboardSkeleton from "@pages/Dashboard/components/DashboardSkeleton/DashboardSkeleton";
import useGetDashboardDetails from "@hooks/fetchers/Dashboard/useGetDashboardDetails";

export const findCategoryByName = (data: any, criteria: string, filters?: string[] | undefined) => {
  const network = data && data.categories && data.categories.find(({ name }: { name: string }) => name === criteria);
  return filters && !filters.includes("allNetworks") && network
    ? {
      ...network,
      networks: network.networks.filter(({ name }: any) => filters.includes(name)),
    }
    : network;
};

export const calculateBalances = (data: any) => {
  const fiat = findCategoryByName(data, "fiat");
  let fiatTotal = +(fiat ? fiat.totalBalance.toFixed(2) : 0);

  const offchain = findCategoryByName(data, "offchain");
  let offchainTotal = +(offchain ? offchain.totalBalance.toFixed(2) : 0);

  const exchange = findCategoryByName(data, "exchange");
  let exchangeTotal = +(exchange ? exchange.totalBalance.toFixed(2) : 0);

  const wallet = findCategoryByName(data, "wallet");
  let walletTotal = +(wallet ? wallet.totalBalance.toFixed(2) : 0);

  const deposit = findCategoryByName(data, "deposit");
  let depositTotal = +(deposit ? deposit.totalBalance.toFixed(2) : 0);

  const pool = findCategoryByName(data, "pool");
  let poolsTotal = +(pool ? pool.totalBalance.toFixed(2) : 0);

  const claimable = findCategoryByName(data, "claimable");
  let yieldFarmingTotal = +(claimable ? claimable.totalBalance.toFixed(2) : 0);

  const staked = findCategoryByName(data, "staked");
  let stakedTotal = +(staked ? staked.totalBalance.toFixed(2) : 0);

  const debt = findCategoryByName(data, "debt");
  let debtTotal = -(debt ? debt.totalBalance.toFixed(2) : 0);

  const other = findCategoryByName(data, "other");
  let otherTotal = +(other ? other.totalBalance.toFixed(2) : 0);

  return {
    fiatTotal,
    offchainTotal,
    exchangeTotal,
    walletTotal,
    depositTotal,
    poolsTotal,
    yieldFarmingTotal,
    debtTotal,
    stakedTotal,
    otherTotal,
  };
};

interface category {
  name: string;
  networks: INetwork[];
}

export interface INetwork {
  name: string;
  value: number;
  image?: string;
}

function Dashboard() {
  const { parsedSelectedWallets } = useWalletStore();
  const { currentUser, isAuthorized } = useAuthStore();
  const [totalBalances, setTotalBalances]: any = useState({});
  const [networks, setNetworks] = useState<INetwork[]>([]);
  const [filters, setFilters] = useState<string[]>([]); // state for filter tables in categories
  const [timestamp, setTimestamp] = useState(Date.now()); // update this to trigger a reload
  const [isDownloading, setIsDownloading] = useState(false);

  const query = useMemo(() => new URLSearchParams(window.location.search), []);
  const walletId = useMemo(() => query.get("walletId") || "", [query]);

  const { userData, totalBalanceUSD, message, isLoading } = useGetDashboardDetails(
    isAuthorized,
    walletId,
    parsedSelectedWallets,
    timestamp
  );

  const doRefresh = () => {
    setTimestamp(Date.now()); // update timestamp when refresh button is pressed
  };

  const doDownload = () => {
    setIsDownloading(true);
    setTimeout(() => {
      xlsxBalance(userData).finally(() => setIsDownloading(false));
    }, 500); // UI responsiveness
  }

  const calculateSum = useCallback(
    (data: any) => {
      if (filters[0] === "allNetworks") {
        return data?.totalBalance?.toFixed(2);
      } else {
        const sum = filters.reduce((prevItem: number, item: any) => {
          if (data?.networks.find((nw: { name: string }) => nw.name === item)) {
            const wallets = data?.networks.find((network: { name: string }) => network.name === item);
            const totalSum = wallets?.data.reduce((prevItem: number, item: { balanceUSD: number }) => {
              prevItem += item.balanceUSD;
              return prevItem;
            }, 0);
            prevItem += totalSum;
          }

          return prevItem;
        }, 0);
        return sum.toFixed(2);
      }
    },
    [filters]
  );

  const getSumBalanceUSD = (data: category[]) => {
    const sum = utilsService.getNetworkTotals();
    data?.forEach((category: any) => {
      const { networks } = category;
      networks.forEach((network: any) => {
        const { name, data } = network;
        data.forEach((token: any) => {
          //   @ts-ignore
          sum[name] += token.balanceUSD;
        });
      });
    });
    const networks: INetwork[] = Object.entries(sum)
      .map(([name, value]) => ({
        name,
        value,
        image: utilsService.getWalletImage(name),
      }))
      .filter((item) => !!item.value);
    setNetworks(networks);
  };

  useEffect(() => {
    if (userData && !isLoading) {
      // checkSimilarSymbols(userData);
      setTotalBalances(calculateBalances(userData));
      getSumBalanceUSD(userData?.categories);
    }
  }, [userData, isLoading]);

  const fiat = useMemo(() => {
    return findCategoryByName(userData, "fiat", filters);
  }, [userData, filters]);
  const offchain = useMemo(() => {
    return findCategoryByName(userData, "offchain", filters);
  }, [userData, filters]);
  const exchange = useMemo(() => {
    return findCategoryByName(userData, "exchange", filters);
  }, [userData, filters]);
  const wallet = useMemo(() => {
    return findCategoryByName(userData, "wallet", filters);
  }, [userData, filters]);
  const deposit = useMemo(() => {
    return findCategoryByName(userData, "deposit", filters);
  }, [userData, filters]);
  const pool = useMemo(() => {
    return findCategoryByName(userData, "pool", filters);
  }, [userData, filters]);
  const claimable = useMemo(() => {
    return findCategoryByName(userData, "claimable", filters);
  }, [userData, filters]);
  const staked = useMemo(() => {
    return findCategoryByName(userData, "staked", filters);
  }, [userData, filters]);
  const debt = useMemo(() => {
    return findCategoryByName(userData, "debt", filters);
  }, [userData, filters]);
  const other = useMemo(() => {
    return findCategoryByName(userData, "other", filters);
  }, [userData, filters]);

  const summaryData = useMemo(() => {
    return [
      {
        name: "Fiat",
        value: calculateSum(fiat),
        img: FiatIcon,
        rawName: "fiat",
        filters,
      },
      {
        name: "Offchain",
        value: calculateSum(offchain),
        img: OffchainIcon,
        rawName: "offchain",
        filters,
      },
      {
        name: "Exchange",
        value: calculateSum(exchange),
        img: ExchangeIcon,
        rawName: "exchange",
        filters,
      },
      {
        name: "Wallet",
        value: calculateSum(wallet),
        img: WalletIcon,
        rawName: "wallet",
        filters,
      },
      {
        name: "Deposit",
        value: calculateSum(deposit),
        img: DepositIcon,
        rawName: "deposit",
        filters,
      },
      {
        name: "Liquidity Pools",
        value: calculateSum(pool),
        img: PoolIcon,
        rawName: "pool",
        filters,
      },
      {
        name: "Yield Farming",
        value: calculateSum(claimable),
        img: ClaimableIcon,
        rawName: "claimable",
        filters,
      },
      {
        name: "Staked",
        value: calculateSum(staked),
        img: StackedIcon,
        rawName: "staked",
        filters,
      },
      {
        name: "Debt",
        value: calculateSum(debt),
        img: DebtIcon,
        rawName: "debt",
        filters,
      },
      {
        name: "Other",
        value: calculateSum(other),
        img: OtherIcon,
        rawName: "other",
        filters,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateSum, fiat, offchain, exchange, wallet, deposit, pool, debt, staked, claimable, other]);

  return (
    <ModuleLayout
      options={{
        wallets: true,
        isLoading: isLoading,
        totalBalanceUSD: totalBalanceUSD,
        userData: userData,
      }}
    >
      <RefreshButton message={message} isRefreshing={isLoading} onRefresh={doRefresh} isDownloading={isDownloading} onDownload={doDownload} />
      {isAuthorized && (
        <PortfolioPerformanceChart parentIsLoading={isLoading} />
      )}
      {(isLoading || !userData) && <DashboardSkeleton />}
      {!isLoading && userData && userData.status === "complete" && (
        <>
          {!isLoading && userData && userData.status === "complete" && (
            <DashboardDiagrams
              assetAllocationData={totalBalances}
              platformAllocationData={userData.protocolsTotal}
              networkAllocationData={userData.networksTotal}
              totalBalance={userData.totalBalanceUSD}
              isSummary={currentUser.isSummary}
            />
          )}
          {networks && <DashboardCategories title="Networks" data={networks} setFilters={setFilters} />}
          {currentUser.isSummary ? (
            <DashboardSummaryTotal title="Total" data={summaryData} />
          ) : (
            <DashboardWithTables
              calculateSum={calculateSum}
              filters={filters}
              fiat={fiat}
              offchain={offchain}
              exchange={exchange}
              wallet={wallet}
              deposit={deposit}
              pool={pool}
              claimable={claimable}
              debt={debt}
              staked={staked}
              other={other}
            />
          )}
          <GrandTotalBalance userData={userData} />
        </>
      )}
    </ModuleLayout>
  );
}

export default React.memo(Dashboard);
