import useSWR from 'swr';
import Cookies from 'js-cookie';
import { useEffect } from "react";


const FIXER_ACCESS_KEY = process.env.REACT_APP_FIXER_ACCESS_KEY || 'e71d27128a6eb00038bb6dac33ef3221';
const FIXER_ENDPOINT = 'https://data.fixer.io/api';
const FIXER_DEFAULT_CURRENCIES = process.env.REACT_APP_FIXER_DEFAULT_CURRENCIES || 'AUD,EUR,GBP,NZD,SGD,USD,BTC,ETH';
const FIXER_RATES_LIVE_TIME = Number(process.env.REACT_APP_FIXER_RATES_LIVE_TIME) || 3600 * 1000;

const FIXER_URL = `${FIXER_ENDPOINT}/latest?format=1&access_key=${FIXER_ACCESS_KEY}&symbols=${FIXER_DEFAULT_CURRENCIES}&base=USD`;

const currencyFetcher = (_) => fetch(FIXER_URL).then(response => response.json());

const options = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
};

const setCurrencyRatesToCookies = (value) => {
    value = JSON.stringify(value);
    let now = new Date();
    let time = now.getTime();
    time += FIXER_RATES_LIVE_TIME;
    now.setTime(time);
    document.cookie =
        'rates=' + value +
        '; expires=' + now.toUTCString() +
        '; path=/';
};


export default function useGetCurrencyList() {
    const { data, error } = useSWR(!Cookies.get('rates') ? 'getCurrencyList' : null, currencyFetcher, options);

    useEffect(() => {
        if (data?.['rates']) setCurrencyRatesToCookies(data?.['rates']);
    }, [data])

    return {
        rates: data?.['rates'] || (Cookies.get('rates') ? JSON.parse(Cookies.get('rates')) : null),
        loading: !error && !(data || Cookies.get('rates')),
        error: error,
    };
}
