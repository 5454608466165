import { useMemo, useState } from "react";
import clsx from "clsx";

import AllAssetsIcon from "@assets/images/all-assets-icon.svg";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { DashboardTable } from "@pages/Dashboard/components/Tables/DashboardTable";
import IconsCollectionBadge from "../../../../../shared/UI/IconsCollectionBadge";
import { getSortData } from "../../../../../utils/sortingData";
import QuestionIcon from "@assets/images/question.svg";
import { utilsService } from "@services/utils.service";

export const PoolTable = ({
  sortTables,
  isPhone,
  ratioWidthTablet,
  renderHeaderTablet,
  pool,
  filters,
  calculateSum,
}) => {
  const [tableError, setTableError] = useState(false);
  const { formatNumberByCurrency } = useCurrencyStore();

  const total: number = useMemo(
    () => (filters ? calculateSum(pool) : pool?.totalBalance?.toFixed(2)),
    [filters, pool, calculateSum]
  );

  const sortDataPoolArray = useMemo(() => {
    if (pool) {
      try {
        const dataPoolArray = pool?.networks.map((item: any) => {
          const dataPool = item.data
            .filter((x) => (x.balance ? x.balance : 0) >= 0.00005)
            .sort((a, b) => b.balanceUSD - a.balanceUSD)
            .reduce(
              (acc: any, value: any) => {
                return {
                  dataArray: [
                    ...acc.dataArray,
                    {
                      label:
                        value.label && value.label !== "label"
                          ? value.label
                          : value.symbol
                          ? value.symbol
                          : value?.tokens?.length
                          ? value.tokens[0].symbol
                          : "No name",
                      mainIcon: value.imageUrl
                        ? value.imageUrl
                        : value?.tokens?.length
                        ? value.tokens[0].tokenAddress
                        : value.tokenAddress
                        ? value.tokenAddress
                        : QuestionIcon,
                      balance: (value.balance
                        ? value.balance
                        : value?.tokens?.length
                        ? value.tokens[0].balance
                        : 0
                      ).toFixed(4),
                      value: value.balanceUSD.toFixed(2),
                    },
                  ],
                  sum: acc.sum + value.balanceUSD,
                };
              },
              {
                dataArray: [],
                sum: 0,
              }
            );
          const sortDataPool = getSortData(dataPool?.dataArray, sortTables?.[`liquidity pools${item.name}`]);

          return [
            ...sortDataPool,
            {
              label: `Total Assets: ${sortDataPool.length}`,
              mainIcon: "",
              protocolIcon: "",
              secondIcon: "",
              balance: "",
              apy: "",
              value: dataPool.sum.toFixed(2),
              isEndRow: true,
            },
          ];
        });
        return dataPoolArray;
      } catch (e) {
        console.log("----poolError");
        console.log(e);
        setTableError(true);
      }
    }
  }, [pool, sortTables]);
  const currentColumnsPool = useMemo(() => {
    if (isPhone) {
      return [
        {
          label: "Assets",
          prop: "assets",
          width: 400 * ratioWidthTablet,
          fixed: "left",
          render: (value: any) => {
            return (
              <div className={clsx("icons-badge", value.isEndRow && "end-row-icons-badge")}>
                <IconsCollectionBadge
                  mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                  protocolIcon={value.protocolIcon}
                  secondIcon={value.secondIcon}
                />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Value",
          prop: "value",
          width: 320 * ratioWidthTablet,
          render: (value: any) => {
            return <div className={clsx({ resultSum: value.isEndRow })}>{formatNumberByCurrency(value.value)}</div>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Balance",
          prop: "balance",
          width: 550 * ratioWidthTablet,
          render: (value: any) => {
            return <>{value.balance && <span className='table-text'>{`${value.balance}`}</span>}</>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: "Assets",
          prop: "assets",
          width: 388 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx("icons-badge", value.isEndRow && "end-row-icons-badge-desktop")}>
                <IconsCollectionBadge
                  mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                  protocolIcon={value.protocolIcon}
                  secondIcon={value.secondIcon}
                />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Balance",
          prop: "balance",
          width: 500 * ratioWidthTablet,
          render: (value: any) => {
            return <>{value.balance && <span className='table-text'>{`${value.balance}`}</span>}</>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Value",
          prop: "value",
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <div className={clsx({ resultSum: value.isEndRow })}>{formatNumberByCurrency(value.value)}</div>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);
  const poolCategoryName = useMemo(() => {
    return pool?.networks.map((item: any) => utilsService.getNetworkName(item?.name));
  }, [pool?.networks]);

  if (tableError) return <></>;

  if (pool && Number(total) !== 0) {
    return (
      <DashboardTable
        dataArray={sortDataPoolArray}
        columns={currentColumnsPool}
        categoryNameArray={poolCategoryName}
        title='Liquidity Pools'
        className='dashboard-table--pools'
        titleValue={total}
      />
    );
  } else {
    return <></>;
  }
};
