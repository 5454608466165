import React from 'react';

import {
    createStyles,
    InputBase, MenuItem,
    Select, withStyles,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";


const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
            color: (props: any) => {
                return !!props.isplaceholder ? '#70777F' : '#333042';
            },
        },
        input: {
            borderRadius: 8,
            position: 'relative',
            backgroundColor: 'rgba(244, 242, 240, 1)',
            fontSize: '1rem',
            padding: '8px 26px 10px 24px',
            height: 30,
            display: 'flex',
            alignItems: 'center',
            color: (props: any) => !props.value ? 'rgba(51,48,66, 0.5)' : '#333042',
            '&:focus': {
                backgroundColor: 'rgba(244, 242, 240, 1)',
                borderRadius: 8,
                color: (props: any) => !props.value ? 'rgba(51,48,66, 0.5)' : '#333042',
            },
        },
    }),
)(InputBase);

interface SelectProps {
    value: string;
    items: {
        value: string;
        title: string;
    }[];
    onChange?: (
        event: React.ChangeEvent<{ name?: string; value: unknown }>,
        child: React.ReactNode
    ) => void;
    style?: React.CSSProperties,
    className?: string;
    placeholder?: string;
}


export default function CanvasSelect({ value, onChange, items, placeholder, ...rest }: SelectProps) {
    return  <Select
        value={value}
        onChange={onChange}
        style={rest.style}
        className={rest.className}
        displayEmpty
        fullWidth={true}
        input={<BootstrapInput value={value} isplaceholder={Boolean(value === '' && placeholder).toString()} />}
        {...rest}
    >
        <MenuItem disabled value="" style={{ color: '#70777F !important' }}>{placeholder}</MenuItem>
        {
            items.map((item, index) => <MenuItem key={index} value={item.value}>
                {item.title}
            </MenuItem>)
        }
    </Select>
}
