import { format } from 'date-fns';
import { makeStyles, Typography } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

import useAuthStore from "@hooks/globalStores/useAuthStore";
import { CurrencySelector } from "../UserGreeting/UserGreeting";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import CanvasSkeleton from "../../../UI/CanvasSkeleton";
import { ChartSwitchWithLabel } from "@pages/Dashboard/components/DashboardDiagram/DashboardPieChart/DashboardPieChart";
import useHideZeros from "@hooks/globalStores/useHideZeros";

interface ITotalBalance {
  totalBalanceUSD: number;
  updatedAt: string;
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  skeleton: {
    width: "300px",
    minWidth: "100px",
    height: 50,
  },
  container: {
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
  },
  dangerIconWrapper: {
    display: "flex",
    alignItems: "center",
  },
  toggleSwitch: {
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      display: "none",
      margin: 0,
    },
  },
  progress: {
    marginLeft: theme.spacing(3)
  }
}));

export const TotalBalance = ({ totalBalanceUSD, updatedAt, isLoading }: ITotalBalance) => {
  const { isAuthorized } = useAuthStore();
  const { formatNumberByCurrency } = useCurrencyStore();
  const classes = useStyles();
  const { setIsHideZero, isHideZero } = useHideZeros();

  return (
    <div className={classes.container}>
      {isLoading && <CanvasSkeleton className={classes.skeleton} />}
      {!isLoading && (totalBalanceUSD !== 0) && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant={"h1"} color="secondary">
            Total: {formatNumberByCurrency(totalBalanceUSD)}
          </Typography>
          {isLoading && <div className={classes.progress}><CircularProgress color="primary" size={35} /></div>}
        </div>
      )}
      {updatedAt && (
        <Typography variant={"h6"} color="secondary">
          {format(new Date(updatedAt), 'dd-MMM-yyyy HH:mm')}
        </Typography>
      )}
      {!isAuthorized && (
        <div style={{ display: "flex" }}>
          <ChartSwitchWithLabel checked={isHideZero} className={classes.toggleSwitch} onChange={(e) => setIsHideZero(e.target.checked)} description={"Hide zero balance tokens"} />
          <CurrencySelector />
        </div>
      )}
    </div>
  );
};
