import React, { ReactElement, useState } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Card, CardActionArea, CardContent, ClickAwayListener, Tooltip, Typography, withStyles } from "@material-ui/core";
import CanvasModal from "@shared/UI/CanvasModal";
import SummaryModal from "@pages/Dashboard/components/DashboardSummaryTotal/SummaryModal/SummaryModal";
import ArrowDown from "@assets/images/arrow-right-white.svg";
import InfoCircle from "@assets/images/info-circle.svg";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";

interface Props {
  item: {
    name: string;
    value: number;
    img: string;
    rawName: string;
    filters;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: "1",
    minWidth: "328px",
    height: "110px",
    background: "#FFFFFF",
    boxShadow: "0 4px 1px -3px #b3b1ad",
    //   boxShadow: '0px 4px 20px -5px rgba(0, 0, 0, 0.1)',
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "381.38px",
    },
  },
  imageContainer: {
    width: "60px",
    height: "60px",
    backgroundColor: "#FDE3D3",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 20,
  },
  image: {
    width: "50%",
    height: "50%",
  },
  textContainer: {
    fontFamily: "Cera Pro",
    fontWeight: 500,
    display: "flex",
    flexDirection: "column",
  },
  name: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    marginBottom: 5,
  },
  value: {
    fontSize: "22px",
  },
  cardContent: {
    padding: "0 22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
  },
  leftCardContent: {
    display: "flex",
  },
  rightCardContent: {
    position: "absolute",
    right: 22,
  },
  arrowWrapper: {
    height: 37,
    width: 37,
    background: "#F37222",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogContainer: {
    justifyContent: "flex-end",
  },
  dialogPaper: {
    height: "100%",
    margin: 0,
    maxHeight: "100%",
  },
}));

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#333042",
    width: 278,
    height: 91,
    display: "flex",
    alignItems: "center",
    padding: "0 12px 0 20px",
    fontSize: "0.8125rem",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },
}))(Tooltip);

const TOOLTIP_TITLE = {
  fiat: "Fiat Currencies held within an Exchange Trust Account",
  vault: "Fireblocks Vault Assets",
  offchain: "Offchain Assets",  
  exchange: "Digital Assets Tokens that are held within an Exchange (e.g. Kraken, Binance, FTX ...etc)",
  wallet: "Digital Assets Tokens that are natively held within the portfolio (e.g. ETH, BTC ... etc)",
  deposit: "Deposits of Digital Assets to DeFi Platforms (e.g. AAVE) to generate yield",
  pool: "Digital Assets that have been deployed to market-making strategies for yield (i.e. Liquidity Providing)",
  claimable: "Digital Assets that have been earned from yield farming strategies on DeFi Platforms",
  staked: "Digital Assets that have been staked to strategies within DeFi Projects for yield",
  debt: "Digital Assets that have been borrowed using a DeFi Project",
  other: "A collection of Digital Assets that do not fit into the other categories"
};

export default function SummaryItem({ item }: Props): ReactElement {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { formatNumberByCurrency } = useCurrencyStore();

  return (
    <>
      <CanvasModal
        open={modalOpen}
        content={<SummaryModal value={item.value} filters={item.filters} name={item.name} rawName={item.rawName} />}
        options={{
          title: item.name,
          maxWidth: "lg",
          classes: {
            container: classes.dialogContainer,
            paper: classes.dialogPaper,
          },
          renderTitle: (
            <div style={{ display: "flex" }}>
              <Typography variant="h3" style={{ marginRight: 10, fontWeight: 500 }}>{item.name}</Typography>
              <Typography variant={"h3"} color="primary">
                {formatNumberByCurrency(item.value)}
              </Typography>
            </div>
          ),
          style: {
            padding: "0 30px 20px",
          },
        }}
        setOpen={setModalOpen}
      />
      <Card
        className={classes.container}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <CardActionArea style={{ height: "100%" }}>
          <CardContent className={classes.cardContent} style={{ paddingBottom: 0 }}>
            <div className={classes.leftCardContent}>
              <div className={classes.imageContainer}>
                <img src={item?.img} className={classes.image} alt={`${item.name}`} />
              </div>
              <div className={classes.textContainer}>
                <span className={classes.name}>
                  {item.name}
                  <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
                    <LightTooltip
                      title={TOOLTIP_TITLE[item.rawName]}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      onClose={() => setTooltipOpen(false)}
                      open={tooltipOpen}
                      placement="top"
                    >
                      <img
                        src={InfoCircle}
                        style={{ marginLeft: 7 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setTooltipOpen(true);
                        }}
                        alt="info"
                      />
                    </LightTooltip>
                  </ClickAwayListener>
                </span>
                {formatNumberByCurrency(item.value, {
                  className: classes.value,
                  toFixed: 2,
                })}
              </div>
            </div>
            <div className={classes.rightCardContent}>
              <div className={classes.arrowWrapper}>
                <img src={ArrowDown} alt="arrow" />
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
}
