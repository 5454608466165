import React, { Dispatch, useState } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Checkbox, IconButton, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core";

import CopyIcon from "@assets/images/copy-icon.png";
import canvasLogo from "@assets/images/canvas-logo.svg";
import threeDots from "@assets/images/3-dots.svg";
import WalletPreloader from "./WalletPreloader/WalletPreloader";
import { utilsService } from "@services/utils.service";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    height: 130,
    background: "#fff",
    boxShadow: "0 4px 1px -3px #b3b1ad",
    borderRadius: 20,
    padding: "0 22px",
    alignItems: "center",
    minWidth: 328,
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      maxWidth: "381.38px",
    },
  },
  checkboxWrapper: {
    width: 50,
  },
  walletInfo: {
    display: "flex",
    alignItems: "center",
  },
  infoWrapper: {},
  imageWrapper: {
    marginRight: 22,
  },
  image: {
    width: 50,
    height: 50,
  },
  editWrapper: {
    display: "flex",
    alignSelf: "flex-start",
    paddingTop: 15,
    position: "absolute",
    right: 10,
  },
  copyText: {
    display: "flex",
    alignItems: "center",
  },
  dot: {
    background: "#200E32",
    height: 4,
    width: 4,
    borderRadius: "50%",
    marginRight: 3.5,
    "&:last-child": {
      marginRight: 0,
    },
  },
  addressTitle: {
    width: 121,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      width: 100,
    },
  },
  mainTitle: {
    textTransform: "capitalize",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "170px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  logoWrapper: {
    border: "1.75px solid #BFB2B2",
    borderRadius: "50%",
    width: 54,
    height: 54,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface IWalletItem {
  address: string;
  name: string;
  checked: boolean;
  onDelete: (address: string) => void;
  onRename: (address: string) => void;
  copied: string;
  setCopied: Dispatch<React.SetStateAction<string>>;
  walletNumber?: number;
  title?: string;
  onCheckboxChange: (address: string) => void;
  checkboxDisabled?: boolean;
  loading?: boolean;
}

const CanvasLogo = () => {
  const classes = useStyles();
  return (
    <div className={classes.logoWrapper}>
      <img src={canvasLogo} alt="Canvas" />
    </div>
  );
};

export default function WalletItem({
  name,
  address,
  checked = true,
  onDelete,
  onRename,
  copied,
  setCopied,
  walletNumber,
  title,
  onCheckboxChange,
  checkboxDisabled,
  loading,
}: IWalletItem) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickPopover = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const renderWalletInfo = () => {
    if (name === "canvas") {
      return <Typography variant="h3">CANVAS</Typography>;
    }

    return (
      <div className={classes.infoWrapper}>
        <Typography className={classes.mainTitle} style={{ textTransform: "capitalize" }} variant="h3">
          {title ? title : name}
        </Typography>
        <div className={classes.copyText}>
          <Typography className={classes.addressTitle} variant="subtitle1">
            {utilsService.shrinkWalletAddress(address)}
          </Typography>

          <Tooltip title={copied === address ? "Copied" : "Copy"} placement="top">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setCopied(address);
                navigator.clipboard.writeText(address);
              }}
            >
              <img src={CopyIcon} alt="copy-icon" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        justifyContent: "flex-start",
        cursor: name === "canvas" ? "auto" : "pointer",
        position: "relative",
      }}
      className={classes.container}
      onClick={() => {
        if (name !== "canvas") onCheckboxChange(address);
      }}
    >
      <div className={classes.checkboxWrapper}>{name !== "canvas" && <Checkbox checked={checked} disabled={checkboxDisabled} size="small" style={{ marginRight: 13 }} />}</div>
      <div className={classes.walletInfo}>
        <div className={classes.imageWrapper}>
          {name === "canvas" ? <CanvasLogo /> : <img src={utilsService.getWalletImage(name)} alt={`${name}`} className={classes.image} />}
        </div>
        {renderWalletInfo()}
      </div>
      <div className={classes.editWrapper} style={{ display: name === "canvas" ? "none" : "flex" }}>
        <Tooltip title="Edit" placement="top">
          <IconButton onClick={handleClickPopover}>
            <img src={threeDots} alt="" />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={(e: any) => {
            e.stopPropagation();
            setAnchorEl(null);
          }}
        >
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              onRename(address);
              setAnchorEl(null);
            }}
          >
            Rename
          </MenuItem>
          {walletNumber === 1 ? (
            <Tooltip placement="top" title="At least one wallet should exist.">
              <span>
                <MenuItem
                  disabled={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(address);
                    setAnchorEl(null);
                  }}
                >
                  Delete
                </MenuItem>
              </span>
            </Tooltip>
          ) : (
            <MenuItem
              style={{ color: "#FF0000" }}
              disabled={walletNumber === 1}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(address);
                setAnchorEl(null);
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </div>
      {checked && loading && <WalletPreloader />}
    </div>
  );
}
