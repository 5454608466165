import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { utilsService } from "@services/utils.service";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import CopyIcon from "@assets/images/copy-icon.png";
import canvasLogo from "@assets/images/canvas-logo.svg";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        height: 60,
        alignItems: 'center',
        maxWidth: 380,
    },
    checkboxWrapper: {
        width: 50,
    },
    walletInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    infoWrapper: {},
    imageWrapper: {
        marginRight: 22,
        '& img': {
            width: 54,
            height: 54,
        },
    },
    image: {
        [theme.breakpoints.down('sm')]: {
            width: 50,
            height: 50,
        },
    },
    editWrapper: {
        display: 'flex',
        alignSelf: 'flex-start',
        paddingTop: 15,
    },
    copyText: {
        display: 'flex',
        alignItems: 'center',
    },
    dot: {
        background: '#200E32',
        height: 4,
        width: 4,
        borderRadius: '50%',
        marginRight: 3.5,
        '&:last-child': {
            marginRight: 0,
        }
    },
    addressTitle: {
        width: 121,
        textTransform: 'lowercase',
        marginRight: 6,
        [theme.breakpoints.down('sm')]: {
            marginRight: 10,
            fontSize: '1rem',
            width: 100,
        },
    },
    mainTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem',
        },
    },
    canvasContainer: {
        marginBottom: 32,
        display: 'flex',
        alignItems: 'center',
    },
    logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 54,
        height: 54,
        border: '2px solid #BFB2B2',
        borderRadius: '50%',
        marginRight: 22,
        '& img': {
            width: 30,
        },
    },
    }),
);


interface ISummaryWalletItem {
    name?: string;
    address?: string;
    copied?: string;
    setCopied?: (value: string) => void;
    canvasBlockhead?: boolean;
    title?: string;
}

export default function SummaryWalletItem( props: ISummaryWalletItem) {
    const { name, address, copied, setCopied, canvasBlockhead, title } = props;
    const classes = useStyles();

    if (canvasBlockhead) {
        return <div className={classes.canvasContainer}>
            <div className={classes.logoWrapper}>
                <img src={canvasLogo} alt=""/>
            </div>
            <Typography variant="h3">CANVAS</Typography>
        </div>;
    }

    if (name && address && setCopied) {
        return (
            <div className={classes.container}>
                <div className={classes.imageWrapper}>
                    <img src={utilsService.getWalletImage(name)} alt={name}/>
                </div>
                <div className={classes.infoWrapper}>
                    <Typography className={classes.mainTitle} style={{ textTransform: 'capitalize' }} variant="h3">
                        { title ? title : name }
                    </Typography>
                    <div className={classes.copyText}>
                        <Typography className={classes.addressTitle} variant="subtitle1">
                            {`${address.slice(0,6)}...${address.substring(address.length - 4, address.length)}`}
                        </Typography>

                        <Tooltip title={copied === address ? 'Copied' : 'Copy'} placement="top">
                            <IconButton style={{ padding: 5 }} onClick={() => {
                                setCopied(address);
                                navigator.clipboard.writeText(address);
                            }}>
                                <img src={CopyIcon} alt="copy-icon"/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    }

    return null;
}
