import React, { createContext, useEffect, useState } from "react";

import {
  addUserWallet,
  deleteUserWallet,
  getUserWallets,
  IUserWallet,
  renameUserWallet,
} from "@services/wallet.service";

import useAuthStore from "@hooks/globalStores/useAuthStore";
import { utilsService } from "@services/utils.service";

export interface IWalletContext {
  wallets: IUserWallet[];
  loading: boolean;
  handleDeleteWallet;
  handleAddWallet;
  handleRenameWallet;
  selectedWallets;
  setSelectedWallets;
  selectedTransactionsWallets;
  setSelectedTransactionsWallets;
  selectAllWallets;
  unSelectAllWallets;
}

const parseWalletAddresses = (wallets) => {
  let finalParsedStr = "";
  let allWalletsIsSelected = true;
  Object.keys(wallets).forEach((key) => {
    if (!wallets[key]) {
      allWalletsIsSelected = false;
    }

    if (wallets[key]) {
      finalParsedStr += !finalParsedStr ? `?addresses=${key}` : `&addresses=${key}`;
    }
  });

  if (allWalletsIsSelected) finalParsedStr = "";

  return finalParsedStr;
};

export const WalletContext = createContext<any>(null);

export const WalletProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [wallets, setWallets] = useState<IUserWallet[]>([]);
  const [selectedWallets, _setSelectedWallets] = useState({});
  const [parsedSelectedWallets, _setParsedSelectedWallets] = useState("");
  const { isAuthorized } = useAuthStore();

  const setSelectedWallets = (wallets) => {
    localStorage.setItem("selectedWallets", JSON.stringify(wallets));
    _setSelectedWallets(wallets);
    _setParsedSelectedWallets(parseWalletAddresses(wallets));
  };

  const selectAllWallets = () => {
    const data = wallets.reduce((prev, current) => ({ ...prev, [current.address]: true }), {});
    setSelectedWallets(data);
  };

  const unSelectAllWallets = () => {
    setSelectedWallets([]);
  };

  const loadUserWalletList = () => {
    setLoading(true);
    getUserWallets()
      .then((res) => {
        if (!localStorage.getItem("selectedWallets")) {
          setSelectedWallets(res.reduce((prev, current) => ({ ...prev, [current.address]: true }), {}));
        } else {
          setSelectedWallets(JSON.parse(localStorage.getItem("selectedWallets") as string));
        }
        setWallets(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log('getUserWallets', err);
        utilsService.notify({
          message: err?.response?.data?.message ? err.response.data.message : "Something went wrong.",
        });
      });
  };

  const handleAddWallet = (address: string, callback) => {
    addUserWallet(address)
      .then((res) => {
        loadUserWalletList();
        setLoading(false);
        callback(null, true);
      })
      .catch((err) => {
        setLoading(false);
        callback(err, true);
      });
  };

  const handleRenameWallet = (address: string, title: string, callback) => {
    renameUserWallet(address, title)
      .then(() => {
        loadUserWalletList();
        setLoading(false);
        callback(null, true);
      })
      .catch((err) => {
        setLoading(false);
        callback(err, true);
      });
  };

  useEffect(() => {
    if (isAuthorized) {
      loadUserWalletList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  const handleDeleteWallet = (address: string, callback) => {
    deleteUserWallet(address)
      .then(() => {
        const finalWalletRes = wallets.filter((item) => item.address !== address);
        setWallets(finalWalletRes);
        setSelectedWallets(finalWalletRes.reduce((prev, current) => ({ ...prev, [current.address]: true }), {}));
        callback(null, true);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  return (
    <WalletContext.Provider
      value={{
        handleAddWallet,
        handleDeleteWallet,
        handleRenameWallet,
        wallets,
        loading,
        selectedWallets,
        setSelectedWallets,
        parsedSelectedWallets,
        selectAllWallets,
        unSelectAllWallets,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
