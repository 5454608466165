import { format, startOfMonth, startOfDay, startOfHour, addMonths, addDays, addHours } from 'date-fns';

export const getDates = (from: string, to: string) => {
  const months: any[] = [];
  if (from && to) {
    const dt_from = new Date(from);
    const dt_to = new Date(to);
    const t_from = dt_from.getTime();
    const t_to = dt_to.getTime();
    for (const month of getMonths(dt_from, new Date(to))) {
      let start_day = month;
      let end_day = addMonths(month, 1);
      if (start_day.getTime() < t_from) start_day = startOfDay(dt_from);
      if (end_day.getTime() > t_to) end_day = startOfDay(addDays(dt_to, 1));
      const days: any[] = [];
      for (const day of getDays(start_day, end_day)) {
        let start_hour = day;
        let end_hour = addDays(day, 1);
        if (start_hour.getTime() < t_from) start_hour = startOfHour(dt_from);
        if (end_hour.getTime() > t_to) end_hour = startOfHour(addHours(dt_to, 1));
        const hours: any[] = [];
        for (const hour of getHours(start_hour, end_hour)) {
          hours.push({
            label: format(hour, 'dd-MMM-yyyy H:mm a'),
            timestamp: hour.getTime(),
            iso: hour.toISOString()
          });
        }
        days.push({
          label: format(day, 'dd-MMM-yyyy'),
          hours: hours
        });
      }
      months.push({
        label: format(month, 'MMMM yyyy'),
        days: days
      });
    }
  }
  return months;
}

const getMonths = (from: Date, to: Date) => {
  const months: Date[] = [];
  const start = startOfMonth(new Date(from));
  const end = addMonths(startOfMonth(new Date(to)), 1);
  for (let dt = start; dt < end; dt = addMonths(dt, 1)) {
    months.push(dt);
  }
  return months;
}

const getDays = (from: Date, to: Date) => {
  const days: Date[] = [];
  const start = startOfDay(new Date(from));
  const end = startOfDay(new Date(to));
  for (let dt = start; dt < end; dt = addDays(dt, 1)) {
    days.push(dt);
  }
  return days;
}

const getHours = (from: Date, to: Date) => {
  const days: Date[] = [];
  const start = startOfHour(new Date(from));
  const end = startOfHour(new Date(to));
  for (let dt = start; dt < end; dt = addHours(dt, 1)) {
    days.push(dt);
  }
  return days;
}
