import useSWR from 'swr';
import api from "@services/api";
import useAuthStore from "@hooks/globalStores/useAuthStore";

const options = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
};

const request = api("trade");
const getTradeDestinations = (_) => request(`/destinations`);

export default function useApiTradeDestinations() {
    const { isAuthorized } = useAuthStore();
    const { data, error, isValidating } = useSWR(isAuthorized ? 'getTradeDestinations': null, getTradeDestinations, options)
    return {
        data: data?.data || [],
        isLoading: isValidating,
        error: error,
    }
}
