import { useEffect } from "react";
import useAuthStore from "@hooks/globalStores/useAuthStore";
import { useHistory } from "react-router-dom";

export const Home = () => {
  const history = useHistory();
  const { isAuthorized } = useAuthStore();
  
  useEffect(() => {
    if (isAuthorized) {
      // console.log('history.push', '/d/overview');
      history.push('/d/overview');
    } else { 
      // console.log('history.push', '/signin');
      history.push('/signin');
    }
  }, [isAuthorized, history]);

  // console.log('home');
  return <></>;
};
export default Home;
