import {
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

export interface MenuItemData {
    icon: string;
    name: string;
    onClick?: any;
    isActive?: (value: boolean) => void;
    className?: string;
}

export interface MenuItemProps {
    menuItem: MenuItemData;
    id?: string;
    className?: string;
    openSideBar?: boolean;
    isDrawerOpen?: boolean;
}

interface IMenuItemStylesProps {
    isDrawerOpen?: boolean;
}


const useStyles = makeStyles(() =>
    ({
        listItem: {
            height: 56,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: (props: IMenuItemStylesProps) => props.isDrawerOpen ? '0 30px' : 0,
            borderRadius: 10,
            marginBottom: 10,
            '&:hover': {
                background: '#F37222',
                borderRadius: 10,
                '& span': {
                    color: '#fff',
                },
            },
        },
        isActive: {
            background: '#F37222',
            '& span': {
                color: '#fff',
            },
        },
        listText: {
            display: (props: IMenuItemStylesProps) => props.isDrawerOpen ? 'flex' : 'none',
            color: '#C2C1C6',
        },
        listIcon: {
            minWidth: (props: IMenuItemStylesProps) => props.isDrawerOpen ? 41 : 25,
        },
    })
);

export function LogoutItem(props: MenuItemProps) {
    const { isDrawerOpen } = props;
    const classes = useStyles({ isDrawerOpen });

    const handleClick = (event) => {
        if (props.menuItem.onClick) {
            props.menuItem.onClick(event);
        }
    };


    return (
        <ListItem
            className={clsx(classes.listItem)}
            onClick={handleClick}
            button
        >
            <ListItemIcon className={classes.listIcon}>
                <img src={props.menuItem.icon} alt={""} />
            </ListItemIcon>
            <ListItemText
                primary={props.menuItem.name}
                className={classes.listText}
            />
        </ListItem>
    );
}
