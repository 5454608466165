import React from "react";
import InputMask from 'react-input-mask';

import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import CanvasSelect from "../../../shared/UI/CanvasSelect";
import { countryList, languageList } from "@pages/Settings/AccountSettings/data";
import CanvasButton from "../../../shared/UI/CanvasButton";

const useStyles = makeStyles(() => ({
    root: {
    },
    actions: {
        marginTop: 35,
        display: "flex",
        justifyContent: 'flex-end',
    },
}));

interface FormTypes {
    firstName: string;
    lastName: string;
    userName: string;
    about: string;
    phoneNumber: string;
    url: string;
    country: string;
    language: string;
}

const PROFILE_FORM_INPUTS = [
    {
        name: 'First Name',
        placeholder: 'Enter your first name',
        value: 'firstName',
        required: true,
        grid: {
            md: 6,
            sm: 12,
            xs: 12,
        },
    },
    {
        name: 'Last Name',
        placeholder: 'Enter your last name',
        value: 'lastName',
        required: true,
        grid: {
            md: 6,
            sm: 12,
            xs: 12,
        },
    },
    {
        name: 'User Name',
        placeholder: 'Enter your user name',
        value: 'userName',
        grid: {
            md: 6,
            sm: 12,
            xs: 12,
        },
    },
    {
        name: 'About',
        placeholder: 'Enter your info',
        value: 'about',
        grid: {
            md: 12,
            sm: 12,
            xs: 12,
        },
        rows: 4,
        multiline: true,
    },
];

const PERSONAL_FORM_INPUTS = [
    {
        name: 'Phone Number',
        placeholder: 'Enter your phone number',
        value: 'phoneNumber',
        grid: {
            md: 6,
            sm: 12,
            xs: 12,
        },
        mask: 'phone',
    },
    {
        name: 'URL',
        placeholder: 'Enter your URL',
        value: 'url',
        grid: {
            md: 6,
            sm: 12,
            xs: 12,
        },
    },
    {
        name: 'Country',
        placeholder: 'Enter your country',
        value: 'country',
        select: true,
        selectData: countryList
            .map(item => ({ title: item.name, value: item.name,})),
        grid: {
            md: 6,
            sm: 12,
            xs: 12,
        },
    },
    {
        name: 'Language',
        placeholder: 'Enter your language',
        value: 'language',
        select: true,
        selectData: languageList.map(item => ({ title: item.language, value: item.language,})),
        grid: {
            md: 6,
            sm: 12,
            xs: 12,
        },
    },
];

export const AccountSettings = () => {
    const classes = useStyles();
    const { handleSubmit, register, control, formState: { errors } } = useForm<FormTypes>({
        defaultValues: {
            firstName: '',
            lastName: '',
            userName: '',
            about: '',
            phoneNumber: '',
            url: '',
            country: '',
            language: '',
        },
    });

    const onSave = (value) => {
        console.log("value", value);
    };

    return (
        <form onSubmit={handleSubmit(onSave)}>
            <div style={{ marginBottom: 49 }}>
                <Typography variant="h4"  style={{ marginBottom: 25 }}>Profile Information</Typography>
                <Grid container spacing={3}>
                    {
                        PROFILE_FORM_INPUTS.map((item: any, index) =>
                            <Grid key={index} item {...item.grid as any}>
                                <Typography variant="h6" style={{ marginBottom: 6, fontSize: '0.8125rem' }}>{item.name}</Typography>
                                <TextField
                                    rows={item.rows}
                                    multiline={item.multiline}
                                    placeholder={item.placeholder}
                                    fullWidth={true}
                                    error={Boolean(errors?.[item.value])}
                                    helperText={errors?.[item.value]?.message}
                                    {...register(item.value as any,  item.required && { required: 'This field is required.' })}
                                    variant="outlined" />
                            </Grid>)
                    }
                </Grid>
            </div>
            <div>
                <Typography variant="h4"  style={{ marginBottom: 25 }}>Personal Information</Typography>
                <Grid container spacing={3}>
                    {
                        PERSONAL_FORM_INPUTS.map((item, index) =>
                            <Grid key={index} item {...item.grid as any}>
                                <Typography variant="h6" style={{ marginBottom: 6, fontSize: '0.8125rem' }}>{item.name}</Typography>
                                { item.select ? <Controller
                                    control={control}
                                    name={item.value as any}
                                    render={({ field: { onChange, value } }) => (
                                        <CanvasSelect placeholder={item.placeholder}
                                                      onChange={onChange}
                                                      value={value}
                                                      items={item.selectData as any} />
                                    )}/>  : (!item.mask ? <TextField
                                    placeholder={item.placeholder}
                                    fullWidth={true}
                                    {...register(item.value as any)}
                                    variant="outlined" /> : <Controller
                                        name={item.value as any}
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value } }) => (
                                            <InputMask mask="+9 999 999 99 99" value={value} onChange={onChange}>
                                                {
                                                    inputProps => (
                                                        <TextField
                                                            placeholder={item.placeholder}
                                                            fullWidth={true}
                                                            {...inputProps}
                                                            variant="outlined" />
                                                    )
                                                }
                                            </InputMask>
                                        )}/>)
                                }
                            </Grid>)
                    }
                </Grid>
            </div>
            <div className={classes.actions}>
                <CanvasButton size="medium" variant="outlined" style={{ marginRight: 15, width: 150 }}>Cancel</CanvasButton>
                <CanvasButton type="submit" size="medium" style={{ width: 150 }}>Save</CanvasButton>
            </div>
        </form>
    );
};
