import { useMemo, useState } from "react";
import clsx from "clsx";

import AllAssetsIcon from "@assets/images/all-assets-icon.svg";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { DashboardTable } from "@pages/Dashboard/components/Tables/DashboardTable";
// import { EmptyDashboard } from "@pages/Dashboard/EmptyDashboard";
import IconsCollectionBadge from "../../../../../shared/UI/IconsCollectionBadge";
import { getSortData } from "../../../../../utils/sortingData";
import { utilsService } from "@services/utils.service";

export const DepositTable = ({
  sortTables,
  isPhone,
  ratioWidthTablet,
  renderHeaderTablet,
  deposit,
  filters,
  calculateSum,
}) => {
  const [tableError, setTableError] = useState(false);
  const { formatNumberByCurrency } = useCurrencyStore();

  const total: number = useMemo(
    () => (filters ? calculateSum(deposit) : deposit?.totalBalance?.toFixed(2)),
    [filters, deposit, calculateSum]
  );

  const sortDataDepositArray = useMemo(() => {
    if (deposit) {
      try {
        const dataDepositArray = deposit?.networks.map((item: any) => {
          const dataDeposit = item.data
            .sort((a, b) => b.balanceUSD - a.balanceUSD)
            .reduce(
              (acc: any, value: any) => {
                return {
                  dataArray: [
                    ...acc.dataArray,
                    {
                      label: value.label,
                      mainIcon: value.imageUrl,
                      protocolIcon: value.protocolImageUrl,
                      balance: value.balance.toFixed(4),
                      price: value.price.toFixed(4),
                      apy: value.apy,
                      value: value.balanceUSD.toFixed(2),
                    },
                  ],
                  sum: acc.sum + value.balanceUSD,
                };
              },
              {
                dataArray: [],
                sum: 0,
              }
            );

          const sortDataDeposit = getSortData(dataDeposit?.dataArray, sortTables?.[`deposits${item.name}`]);
          return [
            ...sortDataDeposit,
            {
              label: `Total Assets: ${sortDataDeposit.length}`,
              mainIcon: "",
              balance: "",
              price: "",
              apy: "",
              value: dataDeposit.sum.toFixed(2),
              isEndRow: true,
            },
          ];
        });
        return dataDepositArray;
      } catch (e) {
        console.log("----depositError");
        console.log(e);
        setTableError(true);
      }
    }
  }, [deposit, sortTables]);
  const currentColumnsDeposit = useMemo(() => {
    if (isPhone) {
      return [
        {
          label: "Assets",
          prop: "assets",
          width: 400 * ratioWidthTablet,
          fixed: "left",
          render: (value: any) => {
            return (
              <div className="icons-badge">
                <div className={clsx("icons-badge", value.isEndRow && "end-row-icons-badge")}>
                  <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} protocolIcon={value.protocolIcon} />
                  <span>{value.label}</span>
                </div>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Value",
          prop: "value",
          width: 350 * ratioWidthTablet,
          render: (value: any) => {
            return <div className={clsx({ resultSum: value.isEndRow })}>{formatNumberByCurrency(value.value)}</div>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Balance",
          prop: "balance",
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <span className="table-text">{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 200 * ratioWidthTablet,
          render: (value: any) => {
            return (<>{!value.isEndRow && (<span className='table-text'>{formatNumberByCurrency(value.price, { toFixed: 4 })}</span>)}</>);
          },
          renderHeader: renderHeaderTablet,
      },
        {
          label: "APY",
          prop: "apy",
          width: 150 * ratioWidthTablet,
          render: (value: any) => {
            return <span className="table-text">{value.apy ? (value.apy*100).toFixed(2) + "%" : "-"}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: "Assets",
          prop: "assets",
          width: 388 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx("icons-badge", value.isEndRow && "end-row-icons-badge-desktop")}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} protocolIcon={value.protocolIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Balance",
          prop: "balance",
          width: 250 * ratioWidthTablet,
          render: (value: any) => {
            return <span className="table-text">{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 200 * ratioWidthTablet,
          render: (value: any) => {
            return (<>{!value.isEndRow && (<span className='table-text'>{formatNumberByCurrency(value.price, { toFixed: 4 })}</span>)}</>);
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "APY",
          prop: "apy",
          width: 150 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <>
                {!value.isEndRow && <span className="table-text">{value.apy ? (value.apy * 100).toFixed(2) + "%" : "-"}</span>}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Value",
          prop: "value",
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <div className={clsx({ resultSum: value.isEndRow })}>{formatNumberByCurrency(value.value)}</div>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);

  const depositCategoryName = useMemo(() => {
    return deposit?.networks.map((item: any) => utilsService.getNetworkName(item?.name));
  }, [deposit?.networks]);

  if (tableError) return <></>;

  if (deposit && Number(total) !== 0) {
    return (
      <DashboardTable
        dataArray={sortDataDepositArray}
        columns={currentColumnsDeposit}
        categoryNameArray={depositCategoryName}
        title="Deposits"
        className="dashboard-table--deposit"
        titleValue={total}
      />
    );
  } else {
    return <></>; // <EmptyDashboard title='Deposits' titleValue={0} />;
  }
};
