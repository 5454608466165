import CoinIcon from "@assets/images/coin-icon.png";
import { memo } from "react";
import clsx from "clsx";

interface IconsCollectionBadgeProps {
  mainIcon: string;
  secondIcon?: string;
  protocolIcon?: string;
  align?: string;

}

const IconsCollectionBadge = ({
  mainIcon,
  protocolIcon,
  secondIcon,
  align,
}: IconsCollectionBadgeProps) => {
  const addDefaultSrc = (event: any) => {
    event.target.src = CoinIcon;
  };

  return (
    <div className={clsx("paired-image-wrapper", { "paired-image-wrapper-right": align === 'right' })}>
      <img
        src={mainIcon}
        onError={addDefaultSrc}
        alt={""}
        className={"paired-image-sml"}
      />
      {protocolIcon && (
        <div className="badge-image-wrapper">
          <img
            src={protocolIcon}
            alt={""}
            onError={addDefaultSrc}
            className={clsx("badge-image", !secondIcon && "no-second-icon")}
          />
        </div>
      )}
      {secondIcon && (
        <img
          src={secondIcon}
          onError={addDefaultSrc}
          alt={""}
          className={"paired-image overlapping-image"}
        />
      )}
    </div>
  );
};

export default memo(IconsCollectionBadge);
