import { useMemo } from 'react';
import { makeStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2),
  },
  grid: {
    width: '100%'
  },
}));

export const ReportsTable = ({ data, doReport }) => {
  const months = useMemo(() => [...data].reverse(), [data]);
  const classes = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell} align="left"><b>Month</b></TableCell>
          <TableCell className={classes.cell} align="right"><b></b></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {months.map((month: any) => (
          <TableRow key={month.label}>
            <TableCell className={classes.cell}>{month.label}</TableCell>
            <TableCell className={classes.cell}>
              <Grid container spacing={1} className={classes.grid} justifyContent='flex-end'>
                <Grid item><Button variant='outlined' color='primary' onClick={() => doReport('liquid', month.label, month.days[0]?.hours[0]?.iso)}>Liquid Assets</Button></Grid>
                <Grid item><Button variant='outlined' color='primary' onClick={() => doReport('illiquid', month.label, month.days[0]?.hours[0]?.iso)}>Illiquid Assets</Button></Grid>
                <Grid item><Button variant='outlined' color='primary' onClick={() => doReport('transactions', month.label, month.days[0]?.hours[0]?.iso)}>Transactions</Button></Grid>
              </Grid>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
