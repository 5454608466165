
export const isSmallPhoneBreakPoint = "max-width: 560px";
export const isPhoneBreakPoint = "(max-width: 700px)";
export const isSmallLaptopBreakPoint =
  "(max-width: 870px) and (min-width: 700px)";
export const isLaptopBreakPoint = "(max-width: 1170px) and (min-width: 870px)";
export const isSmallDesktopBreakPoint =
  "(max-width: 1250px) and (min-width: 1170px)";
export const isDesktopBreakPoint =
  "(min-width: 1250px) and (max-width: 1600px)";
export const isLargeDesktopBreakPoint = "min-width: 1600px";

export const isPhoneSize = "(max-width: 700px)";
export const isLaptopSize = "(max-width: 1170px) and (min-width: 700px)";
export const isDesktopSize = "(min-width: 1170px)";
