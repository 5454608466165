import React, { useMemo, useState } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import ChartProgress from "@pages/Dashboard/components/DashboardSummaryTotal/SummaryModal/СhartBreakdown/ChartProgress/ChartProgress";
import { networkColorsDictionary } from "../../../../../../utils/colors";
import { ChartSwitchWithLabel } from "@pages/Dashboard/components/DashboardDiagram/DashboardPieChart/DashboardPieChart";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";

const useStyles = makeStyles((theme: Theme) => ({
  chartWrapper: {
    display: "flex",
    padding: "0 0 0 28px",
    alignItems: "center",
    marginBottom: 44,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  chart: {
    marginRight: 100,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      marginBottom: 30,
      width: "100%",
    },
  },
  chartInfo: {
    width: 252,
    border: "1px solid #EAE6E2",
    display: "flex",
    borderRadius: 28,
    padding: 16,
    flexDirection: "column",
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(128, 129, 145, 0.5) #fff",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  chartRow: {},
  progressColor: {
    color: "red",
  },
  percent: {
    display: "flex",
    justifyContent: "space-between",
  },
  percentItem: {
    color: "rgba(128, 129, 145, 0.5)",
    weight: 500,
  },
  chartInfoRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  progressRoot: {
    background: "blue",
  },
  platformTitle: {
    marginBottom: 44,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 25,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

interface ISummaryChartBreakdown {
  chartData: {
    name: string;
    color: string;
    percent: number;
  }[];
  title: string;
  filters?: string[];
}

const networkAllocationChartColors = ["#FA4439", "#F7931B", "#B6509E", "#09F9BF", "#F61B9A"];

let keys = Object.keys(networkColorsDictionary);
let randomColor = networkColorsDictionary[keys[(keys.length * Math.random()) << 0]];

export default function ChartBreakdown({ chartData, title, filters }: ISummaryChartBreakdown) {
  const classes = useStyles();
  const { formatNumberByCurrency } = useCurrencyStore();
  const [showPercent, setShowPercent] = useState(false);

  const managedPlatforms = useMemo(() => {
    let data = chartData.filter(x => x.percent > 0).sort((a, b) => b.percent - a.percent);
    if (filters) {
      data = filters.includes("allNetworks")
        ? data
        : data.filter((item) => filters?.includes(item["networkName"] ? item["networkName"] : item.name));
    }

    if (title === "Network Breakdown") {
      data.forEach((item, index) => {
        if (networkColorsDictionary[item.name.toLowerCase()]) {
          item["color"] = networkColorsDictionary[item.name.toLowerCase()];
        } else {
          item["color"] = randomColor;
        }
      });
    } else {
      let count = 0;
      data.forEach((item, index) => {
        item["color"] = networkAllocationChartColors[count];

        if (count === networkAllocationChartColors.length - 1) {
          count = 0;
        } else {
          count++;
        }
      });
    }

    return data;
  }, [chartData, filters, title]);

  if (managedPlatforms.length) {
    return (
      <>
        <div className={classes.header}>
          <Typography className={classes.platformTitle} color="primary" variant="subtitle1">
            {title}
          </Typography>
          <div>
            <ChartSwitchWithLabel
              checked={true}
              defaultSign={"$"}
              onChange={(e: any) => setShowPercent(Boolean(!e.target.checked))}
            />
          </div>
        </div>
        <div className={classes.chartWrapper}>
          <div className={classes.chart}>
            <div>
              {managedPlatforms.map((item, index) => (
                <ChartProgress key={index} color={item.color} value={item.percent * 100} />
              ))}
            </div>

            <div className={classes.percent}>
              {[0, 25, 50, 75, 100].map((item, index) => (
                <span key={index} className={classes.percentItem}>
                  {item}%
                </span>
              ))}
            </div>
          </div>
          <div className={classes.chartInfo}>
            {managedPlatforms.map((item, index) => (
              <div key={index} className={classes.chartInfoRow}>
                <div>
                  <div style={{ width: 10, height: 10, borderRadius: "50%", background: item.color }} />
                </div>
                <span style={{ marginLeft: 10, textTransform: "capitalize" }}>
                  {showPercent && `${item.name} ${(item.percent * 100).toFixed(2)}%`}
                  {!showPercent && `${item.name} ${formatNumberByCurrency(item["balanceUSD"])}`}
                </span>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  return null;
}
