import React, { useMemo } from "react";

import { makeStyles, Typography } from "@material-ui/core";
import CanvasTable from "../../../shared/UI/CanvasTable";

const useStyles = makeStyles(() => ({
    contentItem: {
        marginBottom: 37,
    },
    tableDate: {
        marginBottom: 21,
    },
    rowWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    assetIconWrapper: {
        marginRight: 30,
    },
    assetName: {
        textTransform: 'capitalize',
    },
    tableContainer: {
        borderRadius: 20,
        border: 'none',
        '& table:first-child': {
            '& tr': {
                '& td:first-child, th:first-child': {
                    backgroundColor: '#fff',
                    position: 'sticky',
                    left: -1,
                    zIndex: 3,
                },
                '& th:first-child': {
                    zIndex: 50
                }
            }
        },
        '& th': {
            background: 'transparent',
            fontSize: '0.8125rem',
            color: '#808191',
        },
    },
    tableCell: {
        border: 'none',
    },
}));


export const BorrowTable = ({ data }: { data: any }) => {
    const classes = useStyles();

    const currentTableColumns = useMemo(() => {
        return [
            {
                title: 'Asset',
                field: 'asset',
                sorting: false,
                cellStyle: {
                    minWidth: 200,
                },
                render: rowData => <div className={classes.rowWrapper}>
                    {
                        rowData['assetIcon'] && <div className={classes.assetIconWrapper}>
                            <img style={{ width: 50, height: 50 }} src={rowData['assetIcon']} alt=""/>
                        </div>
                    }
                    <Typography className={classes.assetName}>{rowData['assetName']}</Typography>
                </div>,
            },
            {
                title: 'Interest Rate',
                field: 'interestRate',
                sorting: false,
                cellStyle: {
                    minWidth: 150,
                },
            },
            {
                title: 'Holdings',
                field: 'holdings',
                sorting: false,
                cellStyle: {
                    minWidth: 200,
                },
            },
            {
                title: 'Liquidity',
                field: 'liquidity',
                sorting: false,
                cellStyle: {
                    minWidth: 200,
                },
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className={classes.contentItem}>
            <CanvasTable classes={{
                tableContainer: classes.tableContainer,
                tableCell: classes.tableCell,
            }}
                         data={data}
                         columns={currentTableColumns} />
        </div>
    );
};
