import React from 'react';

import CloseIcon from "@assets/images/close-icon.svg";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';

import { Dialog, IconButton, Typography, useMediaQuery } from "@material-ui/core";
import CanvasButton from "./CanvasButton";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    background: '#fff',
    borderRadius: 20,
    padding: 20,
    minHeight: 100,
    [theme.breakpoints.up('md')]: {
      minWidth: 481,
    },
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    background: '#E4E4E4',
    height: 1,
    margin: '18px 0 0',
  },
  contentWrapper: {
    margin: '27px 0 20px',
  },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface ModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  content: React.ReactElement;
  options: {
    title: string;
    action: {
      title: string;
      onAccept?: () => void;
      loading?: boolean;
    },
  };
}

export default function CanvasConfirmationModal({ open, setOpen, content, options }: ModalProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const handleClose = () => setOpen(false);

  return <Dialog
    fullScreen={fullScreen}
    open={open}
    onClose={handleClose}
    aria-labelledby="responsive-dialog-title"
  >
    <Fade in={open}>
      <div className={classes.root}>
        <div className={classes.titleWrapper}>
          <Typography variant="h3">{options.title}</Typography>
          <IconButton onClick={handleClose}><img src={CloseIcon} alt="close-btn" /></IconButton>
        </div>
        <div className={classes.divider} />
        <div className={classes.contentWrapper}>{content}</div>
        <div className={classes.actionWrapper}>
          <CanvasButton
            size="medium"
            variant="outlined"
            style={{ marginRight: 10 }}
            onClick={() => setOpen(false)}>
            Close
          </CanvasButton>
          <CanvasButton loading={options.action.loading}
            size="medium"
            onClick={options.action.onAccept}>
            {options.action.title}
          </CanvasButton>
        </div>
      </div>
    </Fade>
  </Dialog>
}
