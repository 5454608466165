import useSWR from 'swr';
import api from "@services/api";

const request = api('portfolio');
const apiPortfolioHistory = (_, dateFrom: string, dateTo: string, ticks: string) => request(`history?after=${dateFrom}&before=${dateTo}&ticks=${ticks}`);

let retryTime = 5000;

export default function useGetPortfolioHistory(dateFrom: string, dateTo: string, ticks: string) {
  // console.log('useGetPortfolioHistory', dateFrom, dateTo, ticks);
  const { data, error, isValidating, mutate } = useSWR(['apiPortfolioHistory', dateFrom, dateTo, ticks], apiPortfolioHistory, {
    refreshInterval: 0,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
      console.log('apiPortfolioHistory', error);
      if (retryCount >= 5) return;
      setTimeout(() => {
        revalidate({ retryCount });
      }, retryTime);
    },
  });
  return {
    data: data,
    error: error,
    isLoading: isValidating,
    mutate: mutate    
  }
}
