import React, { createContext, useEffect, useState } from "react";
import { getLogoutLink, getMe } from "@services/user.service";
import { utilsService } from "@services/utils.service";

export interface IUser {
  email: string;
  isEmailVerified: boolean;
  isSummary: boolean;
  name: string;
  nickname: string;
  picture: string;
  updatedAt: string;
  isAuthorized: boolean;
  firstName?: string;
}

interface IAuthContext {
  loading: boolean;
  isAuthorized: boolean;
  idToken?: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  currentUser: IUser,
  setCurrentUser: (user: IUser) => void,
  setIdToken: (token: string) => void;
  setAccessToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  logout: () => void;
}

const USER_DEFAULT: IUser = {
  email: "",
  isEmailVerified: false,
  isSummary: false,
  name: "",
  nickname: "",
  picture: "",
  updatedAt: "",
  isAuthorized: false,
  firstName: "",
};

const CONTEXT_DEFAULT = {
  loading: false,
  isAuthorized: false,
  idToken: '',
  accessToken: '',
  refreshToken: '',
  currentUser: USER_DEFAULT,
  setCurrentUser: () => { },
  setIdToken: () => { },
  setAccessToken: () => { },
  setRefreshToken: () => { },
  logout: () => { },
};

export const AuthContext = createContext<IAuthContext>(CONTEXT_DEFAULT);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const isAuthorized = localStorage.getItem("accessToken")
  const [idToken, _setIdToken] = useState(localStorage.getItem("idToken"));
  const [accessToken, _setAccessToken] = useState(localStorage.getItem("accessToken"));
  const [refreshToken, _setRefreshToken] = useState(localStorage.getItem("refreshToken"));
  const [currentUser, setCurrentUser] = useState<IUser>(USER_DEFAULT)
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (accessToken && !currentUser.isAuthorized) {
      setLoading(true)
      getMe()
        .then(res => {
          setLoading(false);
          setCurrentUser({ ...res, isAuthorized: true });
        })
        .catch(() => setLoading(false));
    }
  }, [accessToken, currentUser])

  const setIdToken = (token: string) => {
    localStorage.setItem("idToken", token);
    _setIdToken(token);
  };

  const setAccessToken = (token: string) => {
    localStorage.setItem("accessToken", token);
    _setAccessToken(token);
  };

  const setRefreshToken = (token: string) => {
    localStorage.setItem("refreshToken", token);
    _setRefreshToken(token);
  };

  const logout = () => {
    getLogoutLink().then((res: any) => {
      localStorage.clear();
      _setIdToken(null);
      _setAccessToken(null);
      _setRefreshToken(null);
      setCurrentUser(USER_DEFAULT);
      window.location.href = res;
    }).catch(() => {
      utilsService.notify({ message: 'Unable to logout' });
    });
  };


  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        isAuthorized: Boolean(isAuthorized),
        idToken,
        accessToken,
        refreshToken,
        setIdToken,
        setAccessToken,
        setRefreshToken,
        logout,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
