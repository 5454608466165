import React, { useEffect, useState, useMemo } from "react";
import ModuleLayout from "../../shared/Layouts/ModuleLayout/ModuleLayout";
import useAuthStore from "@hooks/globalStores/useAuthStore";

import PortfolioPerformanceChart from "@pages/Analytics/components/portfolio/PortfolioPerformanceChart";
import NetworkPerformanceChart from "@pages/Analytics/components/network/NetworkPerformanceChart";
import CategoryPerformanceChart from "@pages/Analytics/components/category/CategoryPerformanceChart";
import ProtocolPerformanceChart from "@pages/Analytics/components/protocol/ProtocolPerformanceChart";
import CategoryBarChart from "@pages/Analytics/components/category/CategoryBarChart";
import NetworkBarChart from "@pages/Analytics/components/network/NetworkBarChart";
import ProtocolBarChart from "@pages/Analytics/components/protocol/ProtocolBarChart";

import DashboardDiagrams from "@pages/Dashboard/components/DashboardDiagram/DashboardDiagrams";
import useGetDashboardDetails from "@hooks/fetchers/Dashboard/useGetDashboardDetails";
import useWalletStore from "@hooks/globalStores/useWalletStore";
import { calculateBalances } from "@pages/Dashboard/Dashboard";
import RefreshButton from "../../shared/UI/RefreshButton";
// import { INetwork } from "@pages/Dashboard/Dashboard";
// import { utilsService } from "@services/utils.service";
// interface category {
//   name: string;
//   networks: INetwork[];
// }

export const Analytics: React.FC = () => {
  const [totalBalances, setTotalBalances]: any = useState({});
  const [timestamp, setTimestamp] = useState(Date.now()); // update this to trigger a reload
  // const [networks, setNetworks] = useState<INetwork[]>([]);
  const { currentUser, isAuthorized } = useAuthStore();
  const { parsedSelectedWallets } = useWalletStore();

  const query = useMemo(() => new URLSearchParams(window.location.search), []);
  const walletId = useMemo(() => query.get("walletId") || "", [query]);

  const { userData, message, isLoading } = useGetDashboardDetails(isAuthorized, walletId, parsedSelectedWallets, timestamp);

  // const getSumBalanceUSD = (data: category[]) => {
  //   const sum = utilsService.getNetworkTotals();
  //   data?.forEach((category: any) => {
  //     const { networks } = category;
  //     networks.forEach((network: any) => {
  //       const { name, data } = network;
  //       data.forEach((token: any) => {
  //         //   @ts-ignore
  //         sum[name] += token.balanceUSD;
  //       });
  //     });
  //   });
  //   const networks: INetwork[] = Object.entries(sum)
  //     .map(([name, value]) => ({
  //       name,
  //       value,
  //       image: utilsService.getWalletImage(name),
  //     }))
  //     .filter((item) => !!item.value);
  //   setNetworks(networks);
  // };

  useEffect(() => {
    if (userData && !isLoading) {
      setTotalBalances(calculateBalances(userData));
      // getSumBalanceUSD(userData?.categories);
    }
  }, [userData, isLoading]);

  const doRefresh = () => {
    setTimestamp(Date.now()); // update timestamp when refresh button is pressed
  };
  return (
    <ModuleLayout options={{ wallets: true }}>
      <RefreshButton isRefreshing={isLoading} message={message} onRefresh={doRefresh} />
      {isAuthorized && (
        <PortfolioPerformanceChart parentIsLoading={isLoading} />
      )}
      {userData && (
        <>
          <DashboardDiagrams
            assetAllocationData={totalBalances}
            platformAllocationData={userData.protocolsTotal}
            networkAllocationData={userData.networksTotal}
            totalBalance={userData.totalBalanceUSD}
            isSummary={currentUser.isSummary}
          />
          <CategoryBarChart categories={userData.categoriesTotal} />
          <NetworkBarChart networks={userData.networksTotal} />
          <ProtocolBarChart protocols={userData.protocolsTotal} />
        </>
      )}
      {isAuthorized && (
        <>
          <CategoryPerformanceChart />
          <NetworkPerformanceChart />
          <ProtocolPerformanceChart />
        </>
      )}
      {/*
      {isAuthorized && <NetworksChart networks={networks} />}
      */}
    </ModuleLayout>
  );
};
