import React from "react";



import ModuleLayout from "../../shared/Layouts/ModuleLayout/ModuleLayout";
import { makeStyles } from "@material-ui/core";
import PaperLayout from "../../shared/Layouts/PaperLayout/PaperLayout";
import ProfileIcon from '@assets/images/profile-icon.svg';
import TabLayout from "../../shared/Layouts/TabLayout/TabLayout";
import { BorrowTable } from "@pages/Borrow/BorrowTable/BorrowTable";
import Arbitrum from "@assets/images/sample-icon.png";
import { ComingSoonPage } from "../../shared/ComingSoonPage/ComingSoonPage";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
}));

export const Borrow = () => {
    const classes = useStyles();

    const data = [
        {
            assetName: "Dai",
            assetIcon: Arbitrum,
            interestRate: '6.53%',
            holdings: '0 DAI',
            liquidity: '$888.94M',
        },
        {
            assetName: "Dai",
            assetIcon: Arbitrum,
            interestRate: '6.53%',
            holdings: '0 DAI',
            liquidity: '$888.94M',
        },
        {
            assetName: "Dai",
            assetIcon: Arbitrum,
            interestRate: '6.53%',
            holdings: '0 DAI',
            liquidity: '$888.94M',
        },
        {
            assetName: "Dai",
            assetIcon: Arbitrum,
            interestRate: '6.53%',
            holdings: '0 DAI',
            liquidity: '$888.94M',
        },
    ];

    const data1 = [
        {
            assetName: "Dai",
            assetIcon: Arbitrum,
            interestRate: '6.53%',
            holdings: '0 DAI',
            liquidity: '$888.94M',
        },
        {
            assetName: "Dai",
            assetIcon: Arbitrum,
            interestRate: '6.53%',
            holdings: '0 DAI',
            liquidity: '$888.94M',
        },
    ];

    const TAB_DATA = [
        {
            name: 'All Products',
            icon: ProfileIcon,
            value: 'allProfile',
            content: <BorrowTable data={data} />,
            contentTitle: 'Borrow',
            count: data.length,
        },
        {
            name: 'Borrow Currency',
            icon: ProfileIcon,
            value: 'borrowCurrency',
            content: <BorrowTable data={data1} />,
            contentTitle: 'Borrow',
            count: data1.length,
        },
        {
            name: 'Borrow Crypto',
            icon: ProfileIcon,
            value: 'borrowCrypto',
            content: <BorrowTable data={data} />,
            contentTitle: 'Borrow',
            count: data.length,
        },
    ];

    return (
        <ModuleLayout>
            <PaperLayout className={classes.root}>
                <ComingSoonPage isBlur={true} />
                <TabLayout divider={false} title={"Products category"} data={TAB_DATA} initialSelectedTab={"allProfile"} />
            </PaperLayout>
        </ModuleLayout>
    );
};
