import { useEffect, useState } from 'react';
import api from '@services/api';

const MAX_PERIOD = 7 * 24 * 60 * 60 * 1000; // request max 1 week of data

const request = api('wallets');

const getData: any = async (series: string, from: string, to: string, ticks: string) => {
  // console.log('getHistory', series, from, to, ticks);
  return await request(`/balances/history?series=${series}&dateFrom=${from}&dateTo=${to}&ticks=${ticks}`);
};

const combineData: any = (data: any[]) => {
  // console.log('combineData', data);
  const chart: any = {
    addresses: [],
    networks: [],
    categories: [],
    protocols: [],
    chart: [],
  }
  for (const item of data) {
    if (item.addresses) {
      for (const a of item.addresses) {
        let found = false;
        for (const b of chart.addresses) {
          if (a === b) {
            found = true;
            break;
          }
        }
        if (!found) {
          chart.addresses.push(a);
        }
      }
    }
    if (item.networks) {
      for (const a of item.networks) {
        let found = false;
        for (const b of chart.networks) {
          if (a === b) {
            found = true;
            break;
          }
        }
        if (!found) {
          chart.networks.push(a);
        }
      }
    }
    if (item.categories) {
      for (const a of item.categories) {
        let found = false;
        for (const b of chart.categories) {
          if (a === b) {
            found = true;
            break;
          }
        }
        if (!found) {
          chart.categories.push(a);
        }
      }
    }
    if (item.protocols) {
      for (const a of item.protocols) {
        let found = false;
        for (const b of chart.protocols) {
          if (a === b) {
            found = true;
            break;
          }
        }
        if (!found) {
          chart.protocols.push(a);
        }
      }
    }
  }
  chart.chart = data.map((x: any) => x.chart).flat();
  chart.chart.sort((a: any, b: any) => (new Date(a.date).getTime()) - (new Date(b.date).getTime()));
  return chart;
};


/*

const getRange: any = async (series: string, from: string, to: string, ticks: string) => {
  const tsFrom = new Date(from).getTime();
  const tsTo = new Date(to).getTime();
  const promises: any[] = [];
  for (let tf = tsFrom; tf < tsTo; tf += MAX_PERIOD) {
    let tt = tf + MAX_PERIOD;
    if (tt > tsTo) tt = tsTo;
    const isoFrom = new Date(tf).toISOString();
    const isoTo = new Date(tt).toISOString();
    promises.push(getData(series, isoFrom, isoTo, ticks));
  }
  const data = await Promise.all(promises);
  return combineData(data);
};

const getRangeSequential: any = async (series: string, from: string, to: string, ticks: string) => {
  const tsFrom = new Date(from).getTime();
  const tsTo = new Date(to).getTime();
  const data: any[] = [];
  for (let tf = tsFrom; tf < tsTo; tf += MAX_PERIOD) {
    let tt = tf + MAX_PERIOD;
    if (tt > tsTo) tt = tsTo;
    const isoFrom = new Date(tf).toISOString();
    const isoTo = new Date(tt).toISOString();
    const results = await getData(series, isoFrom, isoTo, ticks);
    data.push(results);
  }
  return combineData(data);
};
*/

let CURRENT_TIMESTAMP = 0;

const getRangePartial: any = async (series: string, from: string, to: string, ticks: string, timestamp: number, callback: any) => {
  const tsFrom = new Date(from).getTime();
  const tsTo = new Date(to).getTime();
  const data: any[] = [];
  let tt = tsTo;
  while (tt > tsFrom) {
    const promises: any[] = [];
    for (let i = 0; i < 5; i++) { // load data in groups of 5
      let tf = tt - MAX_PERIOD;  // only download max allowed
      if (tf < tsFrom) tf = tsFrom;
      tf = tf - (60 * 60 * 1000); // an extra hour in case data doesn't exist
      const isoFrom = new Date(tf).toISOString();
      const isoTo = new Date(tt).toISOString();
      console.log(i, isoFrom, isoTo);
      promises.push(getData(series, isoFrom, isoTo, ticks));
      tt -= MAX_PERIOD;
      if (tt <= tsFrom) break;
    }
    const results = await Promise.all(promises);
    if (timestamp !== CURRENT_TIMESTAMP) return;
    for(const result of results) data.push(result);
    callback(combineData(data));
  }
  return combineData(data);
};

export default function useGetBalanceHistory(series: string, from: string, to: string, ticks: string) {
  // console.log('useGetBalanceHistory', 'series', series, 'from', from, 'to', to, 'ticks', ticks);
  const [data, setData] = useState<any[]>();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (to && from && series) {
      try {
        setIsLoading(true);
        setError(undefined);
        CURRENT_TIMESTAMP = new Date().getTime();
        getRangePartial(series, from, to, ticks, CURRENT_TIMESTAMP, (d: any) => setData(d)).then((newData: any) => {
          // console.log('history', newData);
          setData(newData);
          setIsLoading(false);
        });
      } catch (e: any) {
        setError(e);
        setIsLoading(false);
      };
    }
  }, [series, from, to, ticks]);

  return {
    data: data as any,
    isLoading: isLoading,
    error: error,
  };
}
