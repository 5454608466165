import useSWR from 'swr';
import api from "@services/api";
import useAuthStore from "@hooks/globalStores/useAuthStore";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const request = api("trade");
const apiTradeWallets = (_) => request(`/wallets`);

export default function useApiTradeWallets() {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating } = useSWR(isAuthorized ? 'apiTradeWallets' : null, apiTradeWallets, options)
  return {
    data: data?.data || [],
    isLoading: isValidating,
    error: error,
  }
}
