import React, { useEffect, useState, useMemo } from "react";

import { utilsService } from "@services/utils.service";
import { xlsxNFT } from "@services/excel";

import useAuthStore from "@hooks/globalStores/useAuthStore";
import useWalletStore from "@hooks/globalStores/useWalletStore";
import useGetNFTDetails from "@hooks/fetchers/NFT/useGetNFTDetails";
import ModuleLayout from "@shared/Layouts/ModuleLayout/ModuleLayout";
import CanvasSkeleton from "@shared/UI/CanvasSkeleton";
import CanvasNotFound from "@shared/UI/CanvasNotFound";
import RefreshButton from "@shared/UI/RefreshButton";
import { NFTItem } from "./NFTItem";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  titleWrapper: {
    display: "flex",
    marginBottom: 37,
  },
  paper: {
    width: 381,
    height: 381,
  },
  skeleton: {
    maxWidth: 381,
    minWidth: 320,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
    },
    height: 300,
  },
  formPaper: {
    padding: "0 22px",
    background: '#fff',
    height: '90vh',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
  },
  network: {
    marginTop: theme.spacing(3)
  }
})
);

export const NFTs = React.memo(() => {
  const { parsedSelectedWallets } = useWalletStore();
  const { isAuthorized } = useAuthStore();
  const [timestamp, setTimestamp] = useState(Date.now()); // update this to trigger a reload
  const [isDownloading, setIsDownloading] = useState(false);
  const query = useMemo(() => new URLSearchParams(window.location.search), []);
  const walletId = useMemo(() => query.get("walletId") || "", [query]);

  const { nftData, totalBalanceUSD, message, isLoading } = useGetNFTDetails(
    isAuthorized,
    walletId,
    parsedSelectedWallets,
    timestamp
  );

  useEffect(() => {
    console.log('nftData', nftData);
  }, [nftData]);

  const doRefresh = () => {
    setTimestamp(Date.now()); // update timestamp when refresh button is pressed
  };

  const doDownload = () => {
    setIsDownloading(true);
    setTimeout( () => {
      xlsxNFT(nftData).finally(()=> setIsDownloading(false));
    }, 500); // UI responsiveness
  }

  const classes = useStyles();
  return (
    <ModuleLayout options={{
      wallets: true,
      totalBalanceUSD: totalBalanceUSD,
      isLoading: isLoading,
    }}>
      <RefreshButton message={message} isRefreshing={isLoading} onRefresh={doRefresh} isDownloading={isDownloading} onDownload={doDownload} />
      <div style={{ paddingBottom: 24 }}>
        <Grid container spacing={3}>
          {isLoading && Array.from(new Array(12)).map((_, index) =>
            <Grid key={index} item xl={3} lg={4} sm={6} xs={12}>
              <CanvasSkeleton className={classes.skeleton} />
            </Grid>)
          }
          {!isLoading && !nftData && <Grid item xs={12}><Typography variant="h5">Something went wrong. Try later</Typography></Grid>}
          {!isLoading && nftData && nftData.status === 'not-found' && <CanvasNotFound title={"No existing NFTs"} />}
          {!isLoading && nftData && nftData.networks && nftData.networks.map((network: any) =>
            <>
              <Grid key={network.name} item xs={12}><Typography variant="h2" className={classes.network}>{utilsService.getNetworkName(network.name)}</Typography></Grid>
              {network.items.map((item: any) => (
                <Grid key={item.addressId} item xl={3} lg={4} sm={6} xs={12}>
                  <NFTItem item={item} />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </div>
    </ModuleLayout>
  );
});

