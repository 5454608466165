import "./ChartSwitch.scss"

export function ChartSwitch(props: any) {
  return (
    <label className={"chart-switch"}>
      <input type={"checkbox"} id={props.id} onClick={props.onChange} defaultChecked={props.checked}/>
      <div className={"slider-thumb round"}/>
    </label>
  )
}
