import { List, makeStyles, Typography } from '@material-ui/core';
import useAuthStore from '@hooks/globalStores/useAuthStore';
import useQuery from '@hooks/useQuery';
import activityIcon from '@assets/images/overview-icon.svg';
import historyIcon from '@assets/images/history-icon.svg';
// import reportIcon from '@assets/images/report-icon.svg';
import tradeIcon from '@assets/images/trade-icon.svg';
import transactionIcon from '@assets/images/transaction-icon.svg';
// import investIcon from '@assets/images/invest-icon.svg';
// import walletIcon from '@assets/images/wallet-icon.svg';
// import rewardsIcon from '@assets/images/rewards-icon.svg';
// import settingsIcon from '@assets/images/settings-icon.svg';
import analyticsIcon from '@assets/images/analytics-icon.svg';
import researchIcon from '@assets/images/research-icon.svg';
import logoutIcon from '@assets/images/logout-icon.svg';
import loginIcon from '@assets/images/login-icon.svg';
import nftIcon from '@assets/images/nft-icon.svg';

import { MenuItem, MenuItemData } from './MenuItem/MenuItem';
import canvasLogo from '@assets/images/canvas-logo.svg';
import clsx from 'clsx';
import { LogoutItem } from './LogoutItem/LogoutItem';
import { useHistory } from 'react-router-dom';

import { version } from '../../../../../version';
const APP_NAME = 'Prime';
const APP_VERSION = version;

console.log(`${APP_NAME} v${APP_VERSION}`)

interface IMenu {
  isOpen?: boolean;
  onClose?: () => void;
  isMobile?: boolean;
}

const useStyles = makeStyles(() => ({
  containerItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: (props: IMenu) => (props.isOpen ? '0 30px' : '0 10px'),
  },
  divider: {
    height: 1,
    background: '#5C5968',
  },
  logoWrapper: {
    display: 'flex',
    height: 105,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderBottom: '1px solid #5C5968',
    marginBottom: 33,
    cursor: 'pointer',
    padding: 10,
  },
  mobileLogo: {
    padding: 10,
  },
  logoImgContainer: {},
  logoTitle: {
    marginTop: 8,
    color: '#fff',
    letterSpacing: 1,
  },
  logoutWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  menuWrapper: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80vh',
    overflow: 'scroll',
    display: 'flex',
    scrollbarColor: 'rgb(51, 48, 66) rgb(51, 48, 66)',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  version: {
    fontSize: '0.85em',
    textAlign: 'center',
    color: '#999',
    fontWeight: 400
  }
}));

export function Menu({ isOpen, onClose, isMobile }: IMenu) {
  const classes = useStyles({ isOpen });

  const { isAuthorized, logout } = useAuthStore();
  const query = useQuery();
  const history = useHistory();

  const overviewUrl = query.get('walletId') ? '/overview' : isAuthorized ? '/d/overview' : '/signin';
  const mainIconUrl = overviewUrl;

  const doSignIn = async () => {
    // try {
    //   const link = await getLoginLink();
    //   window.location.href = link;
    // } catch (e) { }
  };

  const menu: Array<MenuItemData> = [
    {
      icon: activityIcon,
      name: 'Overview',
      to: overviewUrl,
      onClick: onClose,
      isPublic: true,
    },
    {
      icon: nftIcon,
      name: 'NFTs',
      to: '/d/nfts',
      onClick: onClose,
      isPublic: false,
    },
    {
      icon: transactionIcon,
      name: 'Transactions',
      to: '/d/transactions',
      onClick: onClose,
      isPublic: false,
    },
    {
      icon: analyticsIcon,
      name: 'Analytics',
      to: '/d/analytics',
      onClick: onClose,
      isPublic: false,
    },
    {
      icon: historyIcon,
      name: 'Snapshots',
      to: '/d/snapshots',
      onClick: onClose,
      isPublic: false,
    },
    // {
    //   icon: reportIcon,
    //   name: 'Reports',
    //   to: '/d/reports',
    //   onClick: onClose,
    //   isPublic: false,
    // },         
    {
      icon: tradeIcon,
      name: 'Trade',
      to: '/d/trade',
      onClick: onClose,
      isPublic: false,
    },
    {
      icon: researchIcon,
      name: 'Research',
      to: '/d/research',
      onClick: onClose,
      isPublic: false,
    },
    // {
    //   icon: tradeIcon,
    //   name: 'Exchange',
    //   to: '/d/exchange',
    //   onClick: onClose,
    //   isPublic: false,
    // },
    // {
    //   icon: investIcon,
    //   name: 'Invest',
    //   to: '/d/invest',
    //   onClick: onClose,
    //   isPublic: false,
    // },
    // {
    //   icon: walletIcon,
    //   name: 'Borrow',
    //   to: '/d/borrow',
    //   onClick: onClose,
    //   isPublic: false,
    // },
    // {
    //   icon: rewardsIcon,
    //   name: 'Rewards',
    //   to: '/d/rewards',
    //   onClick: onClose,
    //   isPublic: false,
    // },
    // {
    //   icon: settingsIcon,
    //   name: 'Settings',
    //   to: '/d/settings',
    //   onClick: onClose,
    //   isPublic: false,
    // },
  ];

  return (
    <>
      <div className={clsx(classes.logoWrapper, isMobile && classes.mobileLogo)}>
        <div className={classes.logoImgContainer} onClick={() => history.push(mainIconUrl)}>
          <img src={canvasLogo} alt='logo' />
        </div>
        {isOpen && (
          <>
            <Typography variant='h3' className={classes.logoTitle}> CANVAS</Typography>
            <div className={classes.version}>{APP_NAME} v{APP_VERSION}</div>
          </>
        )}
      </div>
      <div className={classes.menuWrapper}>
        <List className={classes.containerItem}>
          {menu.filter((menuItem) => isAuthorized || menuItem.isPublic).map((menuItem) => (
            <MenuItem
              isDrawerOpen={isOpen}
              menuItem={menuItem}
              key={menuItem.name}
              id={menuItem.name}
              className={menuItem.className || ''}
            />
          ))}
        </List>
        {isAuthorized && (
          <List className={clsx(classes.logoutWrapper, classes.containerItem)}>
            <LogoutItem
              isDrawerOpen={isOpen}
              menuItem={{
                icon: logoutIcon,
                name: 'Logout',
                onClick: logout,
              }}
              key={'Logout'}
              id={'Logout'}
              openSideBar={true}
            />
          </List>
        )}
        {!isAuthorized && (
          <List className={clsx(classes.logoutWrapper, classes.containerItem)}>
            <LogoutItem
              isDrawerOpen={isOpen}
              menuItem={{
                icon: loginIcon,
                name: 'Login',
                onClick: doSignIn,
              }}
              key={'Login'}
              id={'Login'}
              openSideBar={true}
            />
          </List>
        )}
      </div>
    </>
  );
}
