import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import React, { useCallback, useState } from "react";
import { Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { ChartSwitch } from "@pages/Dashboard/components/DashboardDiagram/DashboardPieChart/ChartSwitch/ChartSwitch";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { utilsService } from "@services/utils.service";

const useStyles = makeStyles((theme) => ({
  chartWrapper: {
    "& .recharts-layer:hover": {
      cursor: "pointer",
    },
    position: "relative",
  },
  pieSector: {
    border: "1px solid red",
  },
  chartSwitchWrapper: {
    display: "flex",
    alignItems: "center",
  },
  infoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  expandLegend: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconWrapper: {
    width: 18,
    height: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    background: "#DFE2E6",
    "& img": {
      width: 8,
      height: 8,
    },
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
  legend: {
    border: "1px solid #EAE6E2",
    borderRadius: 20,
    padding: theme.spacing(2, 3),
    marginTop: 10,
  },
  valueNumber: {
    // marginLeft: 15,
  },
  itemName: {
    fontWeight: 700,
    marginRight: 15,
    textAlign: "left",
  },
  percentageNumber: {
    color: "gray",
    marginLeft: 3,
  },
  legendGrid: {
    cursor: "pointer",
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
  },
  legencCircleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  legendCircle: {
    height: 10,
    width: 10,
    borderRadius: "50%",
    marginRight: 10,
    marginTop: 6,
  },
  pieChartValue: {
    fontSize: "1.375rem",
    fontWeight: 400
  },
  pieChartValueName: {
    fontSize: "1rem",
    fontWeight: 700
  },
  pieChartValuePercent: {
    fontSize: "0.8rem",
    fontWeight: 500
  },  
  switchDescr: {
    color: "rgba(128, 129, 145, 0.5)",
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  mockBlock: {
    width: 140,
    height: 20,
    [theme.breakpoints.down("sm")]: {
      width: 100,
    },
  },
  pieChartContainer: {
    "& svg>g>.recharts-pie-sector .recharts-sector:hover": {
      color: "black",
      filter: "drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.25))",
    },
  },
}));

export function ActiveShape(props: any) {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percentMode,
    highlightIdx,
    activeIndex,
  } = props;
  const { formatNumberByCurrency } = useCurrencyStore();
  const classes = useStyles({});

  return (
    <g>
      <text className={classes.pieChartValue} x={cx} y={cy - 20} dy={8} textAnchor='middle' fill={"#333042"}>
        {`${payload.name === "Debt" || payload.value < 0 ? "-" : ""}${formatNumberByCurrency(Math.abs(payload.value), {
          isAbbreviateNumber: true,
        })}`}
      </text>
      <text className={classes.pieChartValueName} x={cx} y={cy + 2} dy={8} textAnchor='middle' fill={"#333042"}>
        {utilsService.capitalizeLetter(payload.name)}
      </text>
      <text className={classes.pieChartValuePercent} x={cx} y={cy + 25} dy={8} textAnchor='middle' fill={"#333042"}>
        {percentMode && payload.percentage && `${payload.percentage.toFixed(2)}%`}
      </text>

      <Sector
        className={classes.pieSector}
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        filter={highlightIdx === activeIndex ? "drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.25))" : ""}
      />
    </g>
  );
}
interface IToggle {
  onChange: (e) => void;
  defaultSign?: string;
  description?: string;
  checked?: boolean;
  styles?: {
    root?: React.CSSProperties;
    title?: React.CSSProperties;
  };
  className?: string;
}

export const ChartSwitchWithLabel = ({
  onChange,
  defaultSign = "%",
  description,
  checked,
  styles,
  className,
}: IToggle) => {
  const classes = useStyles({});

  return (
    <div className={clsx(classes.chartSwitchWrapper, className)} style={styles?.root}>
      <Typography style={styles?.title} className={classes.switchDescr} variant='h6'>
        {description ? description : `Show as ${defaultSign}`}
      </Typography>
      <ChartSwitch onChange={onChange} checked={checked} />
    </div>
  );
};

export function DashboardPieChart(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  // const [showLegend, setShowLegend] = useState({ [props.name]: true });
  const [highlight, setHighlight] = useState("");
  const classes = useStyles({});
  const theme = useTheme();
  const mobileResolution = useMediaQuery(theme.breakpoints.down("sm"));

  const { formatNumberByCurrency } = useCurrencyStore();

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const onClick = (index) => {
    setHighlight(index as any);
    setActiveIndex(index);
  };

  const data = props?.data?.results.sort((a, b) => b.value - a.value);

  return (
    <div className={classes.chartWrapper}>
      <ResponsiveContainer width={"100%"} className={classes.pieChartContainer} height={300}>
        <PieChart height={300}>
          <Pie
            data={data}
            activeIndex={activeIndex}
            activeShape={
              <ActiveShape
                activeIndex={activeIndex}
                highlightIdx={highlight}
                percentMode={props.percentMode}
                totalAssetBalance={props.totalAssetBalance}
              />
            }
            dataKey='value'
            nameKey='name'
            cx='50%'
            cy='50%'
            innerRadius={90}
            outerRadius={130}
            onMouseEnter={onPieEnter}
            paddingAngle={0}
            fill='#82ca9d'
          >
            {props.data?.results.map((entry: any, index: number) => {
              return <Cell key={`cell-${index}`} strokeWidth={0} fill={entry.color} />;
            })}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className={classes.infoWrapper}>{!mobileResolution && <div className={classes.mockBlock} />}</div>
      <Grid container className={classes.legend}>
        {props.data?.results.map((item, index: number) => (
          <Grid item lg={6} md={12} sm={12} xs={12} key={index}>
            {item.percentage > 5 && (
              <Grid
                container
                alignItems='flex-start'
                onClick={() => {
                  onClick(index);
                }}
                className={classes.legendGrid}
              >
                <Grid item>
                  <div style={{ background: item.color }} className={classes.legendCircle} />
                </Grid>
                <Grid item>
                  <Typography variant={"h6"} className={classes.itemName}>
                    {utilsService.capitalizeLetter(item.name)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={"h6"} className={classes.valueNumber}>
                    {`${item.name === "Debt" ? "- " : ""}${formatNumberByCurrency(item.value)}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={"h6"} className={classes.percentageNumber}>
                    ({item.percentage > 5 ? item.percentage.toFixed(2) : "< 0.01"}%)
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
