import api from "./api";

const request = api('transactions');

export const getTransactions: any = async (dateFrom: string, isAuthorized: boolean, walletId: string, parsedSelectedWallets: string) => {
  console.log('getTransactions', dateFrom, isAuthorized, walletId, parsedSelectedWallets);
  if (walletId) {
    // public wallet address
    return await request(`history/${walletId}?before=${dateFrom}&count=1000`);
  } else if (isAuthorized) {
    // logged in user with selection of wallet addresses
    return await request(`history?before=${dateFrom}${parsedSelectedWallets?.replace('?', '&')}&count=1000`);
  }
};