import React from "react";

import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

interface IPaperLayout {
    children?: React.ReactNode;
    title?: string;
    options?: {
        renderHeader?: React.ReactNode;
        renderAdditionalHeaderDetails?: React.ReactNode;
    }
    className?: string;
    style?: React.CSSProperties,
}

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        borderRadius: 20,
        padding: '20px 24px 33px',
        position: 'relative',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    headerTitle: {
        fontSize: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 11,
        },
    },
}));

const PaperLayout = ({ children, title, options, style, className }: IPaperLayout) => {
    const classes = useStyles();

    const renderHeader = () => {
        if (options?.renderHeader) {
            return options?.renderHeader;
        }

        if (title) {
            return <div className={classes.header}>
                <Typography variant="h2" className={classes.headerTitle}>{title}</Typography>
                {options?.renderAdditionalHeaderDetails}
            </div>;
        }

        return null;
    }

    return (
        <div className={clsx(classes.root, className)} style={style}>
            {renderHeader()}
            {children}
        </div>
    );
};

export default PaperLayout;
