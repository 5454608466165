import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 150,
        },
}),
);

export default function SummaryModalSkeleton() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    );
}
