import { useState, useEffect, useCallback } from "react";

import { utilsService } from "@services/utils.service";
import { executeTrade, getTradeStatus, parseTradeProgress } from "@services/trade.service";

import CanvasButton from "@shared/UI/CanvasButton";

import { TradeInfo } from "./TradeInfo";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2)
  },
  subtitle: {
    marginBottom: theme.spacing(1)
  },
  grid: {
    marginBottom: theme.spacing(3)
  },
  item: {
    textAlign: 'right'
  }
}));

export const TradeExecute = ({ setMode, closeModal, quote, setQuote }) => {
  const classes = useStyles();
  const [transactionTimestamp, setTransactionTimestamp] = useState(0);
  const [prevTransactionTimestamp, setPrevTransactionTimestamp] = useState(0);
  const [executeLoading, setExecuteLoading] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [intervalId, setIntervalId] = useState(0);

  useEffect(() => {
    // clean-up on unMount
    return () => {
      if (intervalId !== 0) {
        clearInterval(intervalId);
        setIntervalId(0);
      }
    };
  }, [intervalId]);

  const doTransaction = useCallback(() => {
    // console.log('doTransaction', quote.execute);
    setTransactionLoading(true);
    getTradeStatus(quote.execute?.id).then(res => {
      setTransactionLoading(false);
      if (res.status) {
        const id = quote.execute?.id;
        const status = res.data;
        const progress = parseTradeProgress(status);
        setQuote((q: any) => ({ ...q, execute: { id, status, progress } }));
        if (quote.execute?.progress === 100 && intervalId !== 0) {
          clearInterval(intervalId);
          setIntervalId(0);
        }
        if (quote.execute?.status === 'CANCELLED') {
          utilsService.notify({ message: 'Trade cancelled', status: 'warning' });
        }    
        if (quote.execute?.status === 'FAILED') {
          utilsService.notify({ message: 'Trade failed. Please try again ...', status: 'warning' });
        }                
        if (quote.execute?.status === 'COMPLETED') {
          utilsService.notify({ message: 'Trade executed successfully', status: 'success' });
          closeModal();
        }
      }
    }).catch((e) => {
      console.log(e);
      setTransactionLoading(false);
      utilsService.notify({ message: e.message });
    });
  }, [quote, setQuote, closeModal, intervalId]);

  useEffect(() => {
    if (transactionTimestamp !== prevTransactionTimestamp) {
      setPrevTransactionTimestamp(transactionTimestamp);
      if (!transactionLoading) {
        doTransaction();
      }
    }
  }, [transactionTimestamp, prevTransactionTimestamp, transactionLoading, doTransaction]);

  const triggerTransaction = () => {
    // console.log('Poll Transaction ...')
    setTransactionTimestamp(new Date().getTime());
  };

  const onSubmit = () => {
    console.log('quote', quote);
    if (quote?.quoteId) {
      setExecuteLoading(true);
      executeTrade(quote.quoteId).then(res => {
        setExecuteLoading(false);
        if (res.status) {
          utilsService.notify({ message: 'Trade submitted', status: 'success' });
          const id = res.data.id;
          const status = res.data.status;
          const progress = parseTradeProgress(status);
          setQuote((q: any) => ({ ...q, execute: { id, status, progress } }));
          if (intervalId === 0) {
            const intId = setInterval(() => triggerTransaction(), 4000);
            setIntervalId((intId as unknown) as number);
          }
        } else {
          utilsService.notify({ message: res.message, status: 'error' });
        }
      }).catch((e) => {
        console.log(e);
        setExecuteLoading(false);
        utilsService.notify({ message: e.message });
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.content}>
          <TradeInfo
            quote={quote}
            allowanceLoading={false}
            allowanceRefresh={() => { }}
            transactionProgress={quote.execute?.progress}
            transactionStatus={quote.execute?.status}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <CanvasButton variant={"outlined"} size={"large"} fullWidth={true} onClick={() => setMode('quote')}>Back</CanvasButton>
      </Grid>
      <Grid item xs={6}>
        <CanvasButton 
          size={"large"}
          fullWidth={true}
          disableElevation
          loading={executeLoading}
          disabled={!quote?.quoteId || executeLoading || (quote.execute?.progress > 0 && quote.execute?.progress < 100) || (quote.execute?.status === "COMPLETED")}
          onClick={() => onSubmit()}
        >Execute Trade</CanvasButton>
      </Grid>
    </Grid>
  );
}