import { useEffect, useState, useCallback } from "react";

import { utilsService } from "@services/utils.service";
import { approveTrade, getTradeAllowance, getTradeStatus, parseTradeProgress } from "@services/trade.service";

import CanvasButton from "@shared/UI/CanvasButton";

import { TradeInfo } from "./TradeInfo";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2)
  },
  subtitle: {
    marginBottom: theme.spacing(1)
  },
  grid: {
    marginBottom: theme.spacing(3)
  },
  item: {
    textAlign: 'right'
  }
}));

export const TradeApprove = ({ setMode, setQuote, quote }) => {
  const classes = useStyles();
  const [refreshTimestamp, setRefreshTimestamp] = useState(0);
  const [prevRefreshTimestamp, setPrevRefreshTimestamp] = useState(0);
  const [transactionTimestamp, setTransactionTimestamp] = useState(0);
  const [prevTransactionTimestamp, setPrevTransactionTimestamp] = useState(0);
  const [allowanceLoading, setAllowanceLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [intervalId, setIntervalId] = useState(0);

  const doRefresh = useCallback(() => {
    console.log('quote', quote);
    setAllowanceLoading(true);
    getTradeAllowance(quote.quoteId).then(res => {
      setAllowanceLoading(false);
      if (res.status) {
        // utilsService.notify({ message: 'Trade Allowance received', status: 'success' });
        setQuote((q: any) => ({ ...q, allowance: res.data }));
        if (res.data.isApproved) {
          if (intervalId !== 0) {
            utilsService.notify({ message: 'Trade Approved', status: 'success' });
            clearInterval(intervalId);
            setIntervalId(0);
          }
          setMode("execute");
        }
      } else {
        utilsService.notify({ message: res.message, status: 'error' });
      }
    }).catch((e) => {
      console.log(e);
      setAllowanceLoading(false);
      utilsService.notify({ message: e.message });
    });
  }, [quote, setQuote, setMode, intervalId]);

  useEffect(() => {
    if (refreshTimestamp !== prevRefreshTimestamp) {
      setPrevRefreshTimestamp(refreshTimestamp);
      if (!allowanceLoading) {
        doRefresh();
      }
    }
  }, [refreshTimestamp, prevRefreshTimestamp, allowanceLoading, doRefresh]);

  const triggerRefresh = () => {
    console.log('Refresh allowance ...')
    setRefreshTimestamp(new Date().getTime());
  }

  useEffect(() => {
    triggerRefresh();
  }, []);

  useEffect(() => {
    // clean-up on unMount
    return () => {
      if (intervalId !== 0) {
        clearInterval(intervalId);
        setIntervalId(0);
      }
    };
  }, [intervalId]);

  useEffect(() => {
    if (quote.approve?.progress === 100) {
      if (quote.approve?.progress === 'CANCELLED' || quote.approve?.progress === 'FAILED') {
        if (intervalId !== 0) {
          clearInterval(intervalId);
          setIntervalId(0);
        }
      }
    }
  }, [quote.approve, intervalId]);

  const doTransaction = useCallback(() => {
    // console.log('doTransaction', quote.approve);
    setTransactionLoading(true);
    getTradeStatus(quote.approve?.id).then(res => {
      setTransactionLoading(false);
      if (res.status) {
        const id = quote.approve?.id;
        const status = res.data;
        const progress = parseTradeProgress(status);
        setQuote((q: any) => ({ ...q, approve: { id, status, progress } }));
      }
    }).catch((e) => {
      console.log(e);
      setTransactionLoading(false);
      utilsService.notify({ message: e.message });
    });
  }, [quote, setQuote]);

  useEffect(() => {
    if (transactionTimestamp !== prevTransactionTimestamp) {
      setPrevTransactionTimestamp(transactionTimestamp);
      if (!transactionLoading) {
        doTransaction();
      }
    }
  }, [transactionTimestamp, prevTransactionTimestamp, transactionLoading, doTransaction]);

  const triggerTransaction = () => {
    // console.log('Poll Transaction ...')
    setTransactionTimestamp(new Date().getTime());
  };

  const pollTransaction = () => {
    triggerRefresh();
    triggerTransaction();
  };

  const onSubmit = () => {
    // console.log('quote', quote);
    setApproveLoading(true);
    approveTrade(quote.quoteId).then(res => {
      setApproveLoading(false);
      if (res.status) {
        utilsService.notify({ message: 'Allowance Submitted', status: 'success' });
        const id = res.data.id;
        const status = res.data.status;
        const progress = parseTradeProgress(status);
        // console.log('set transaction', id, status, progress);
        setQuote((q: any) => ({ ...q, approve: { id, status, progress } }));
        if (intervalId === 0) {
          const intId = setInterval(() => pollTransaction(), 4000);
          setIntervalId((intId as unknown) as number);
        }
      } else {
        utilsService.notify({ message: res.message, status: 'error' });
      }
    }).catch((e) => {
      console.log(e);
      setApproveLoading(false);
      utilsService.notify({ message: e.message });
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.content}>
          <TradeInfo
            quote={quote}
            allowanceLoading={allowanceLoading}
            allowanceRefresh={triggerRefresh}
            transactionProgress={quote.approve?.progress}
            transactionStatus={quote.approve?.status}
          />
        </div>
      </Grid>
      <Grid item xs={5}>
        <CanvasButton variant={"outlined"} size={"large"} fullWidth={true} onClick={() => setMode('quote')}>Back</CanvasButton>
      </Grid>
      <Grid item xs={7}>
        <CanvasButton
          size={"large"}
          fullWidth={true}
          disableElevation
          loading={approveLoading}
          disabled={!quote || (quote.approve?.progress > 0 && quote.approve?.progress < 100)}
          onClick={() => onSubmit()}
        >Approve Quote</CanvasButton>
      </Grid>
    </Grid>
  );
}