import { useMemo, useState } from "react";
import clsx from "clsx";

import AllAssetsIcon from "@assets/images/all-assets-icon.svg";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { DashboardTable } from "@pages/Dashboard/components/Tables/DashboardTable";
import IconsCollectionBadge from "../../../../../shared/UI/IconsCollectionBadge";
import { getSortData } from "../../../../../utils/sortingData";
import { utilsService } from "@services/utils.service";

export const DebtTable = ({
  sortTables,
  isPhone,
  ratioWidthTablet,
  renderHeaderTablet,
  debt,
  filters,
  calculateSum,
}) => {
  const [tableError, setTableError] = useState(false);
  const { formatNumberByCurrency } = useCurrencyStore();

  const total: number = useMemo(
    () => (filters ? calculateSum(debt) : debt?.totalBalance?.toFixed(2)),
    [filters, debt, calculateSum]
  );

  const debtCategoryName = useMemo(() => {
    return debt?.networks.map((item: any) => utilsService.getNetworkName(item?.name));
  }, [debt?.networks]);

  const currentColumnsDebt = useMemo(() => {
    if (isPhone) {
      return [
        {
          label: "Liability",
          prop: "liability",
          width: 400 * ratioWidthTablet,
          fixed: "left",
          render: (value: any) => {
            return (
              <div className={clsx("icons-badge", value.isEndRow && "end-row-icons-badge")}>
                <IconsCollectionBadge
                  mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                  protocolIcon={value.protocolIcon}
                />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Value",
          prop: "value",
          width: 380 * ratioWidthTablet,
          render: (value: any) => {
            const isNegative = Math.sign(value.value) === -1;
            return (
              <>
                {isNegative && "("}
                {formatNumberByCurrency(Math.abs(value.value))}
                {isNegative && ")"}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Balance",
          prop: "balance",
          width: 380 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: "Liability",
          prop: "liability",
          width: 388 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx("icons-badge", value.isEndRow && "end-row-icons-badge-desktop")}>
                <IconsCollectionBadge
                  mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                  protocolIcon={value.protocolIcon}
                />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Balance",
          prop: "balance",
          width: 500 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Value",
          prop: "value",
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            const isNegative = Math.sign(value.value) === -1;
            return (
              <>
                {isNegative && "("}
                {formatNumberByCurrency(Math.abs(value.value))}
                {isNegative && ")"}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);
  const sortDataDebtArray = useMemo(() => {
    if (debt) {
      try {
        const dataDebtArray = debt?.networks.map((item: any) => {
          const dataDebt = item.data.reduce(
            (acc: any, value: any) => {
              return {
                dataArray: [
                  ...acc.dataArray,
                  {
                    label: value.label,
                    mainIcon: value.imageUrl,
                    protocolIcon: value.protocolImageUrl,
                    balance: value.balance.toFixed(4),
                    value: value.balanceUSD.toFixed(2),
                  },
                ],
                sum: acc.sum + value.balanceUSD,
              };
            },
            {
              dataArray: [],
              sum: 0,
            }
          );

          const sortDataDebt = getSortData(dataDebt?.dataArray, sortTables?.[`debt${item.name}`]);
          return [
            ...sortDataDebt,
            {
              label: `Total Assets: ${sortDataDebt.length}`,
              mainIcon: "",
              protocolIcon: "",
              value: dataDebt.sum.toFixed(2),
              isEndRow: true,
            },
          ];
        });
        return dataDebtArray;
      } catch (e) {
        console.log("----debtError");
        console.log(e);
        setTableError(true);
      }
    }
  }, [debt, sortTables]);

  if (tableError) return <></>;

  if (debt && (total < 0 || total > 0)) {
    return (
      <DashboardTable
        dataArray={sortDataDebtArray}
        columns={currentColumnsDebt}
        categoryNameArray={debtCategoryName}
        title='Debt'
        className='dashboard-table--debt'
        titleValue={total}
      />
    );
  } else {
    return <></>;
  }
};
