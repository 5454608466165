import { useState, useMemo, useCallback } from "react";

import { TableSortLabel } from "@material-ui/core";
import SortIcon from "../../shared/UI/SortIcon/SortIcon";
import {
  isDesktopBreakPoint,
  isPhoneBreakPoint,
  isSmallDesktopBreakPoint,
  isSmallLaptopBreakPoint,
  isSmallPhoneBreakPoint,
  isLaptopBreakPoint,
} from "../../utils/functions";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { FiatTable } from "@pages/Dashboard/components/Tables/FiatTable/FiatTablet";
import { OffchainTable } from "@pages/Dashboard/components/Tables/OffchainTable/OffchainTablet";
import { ExchangeTable } from "@pages/Dashboard/components/Tables/ExchangeTable/ExchangeTablet";
import { WalletTable } from "@pages/Dashboard/components/Tables/WalletTable/WalletTablet";
import { DepositTable } from "@pages/Dashboard/components/Tables/DepositTable/DepositTable";
import { PoolTable } from "@pages/Dashboard/components/Tables/PoolTable/PoolTable";
import { ClaimableTable } from "@pages/Dashboard/components/Tables/ClaimableTable/ClaimableTable";
import { StakedTable } from "@pages/Dashboard/components/Tables/StakedTable/StakedTable";
import { OtherTable } from "@pages/Dashboard/components/Tables/OtherTable/OtherTable";
import { DebtTable } from "@pages/Dashboard/components/Tables/DebtTable/DebtTable";

interface PropsList {
  calculateSum: (data: any) => number;
  filters: Array<string>;
  fiat: any;
  offchain: any;
  exchange: any;
  wallet: any;
  deposit: any;
  pool: any;
  claimable: any;
  debt: any;
  staked: any;
  other: any;
}

interface SortTables {
  sort: "asc" | "desc";
  type: string;
}

interface SortAllTables {
  [key: string]: SortTables;
}

export const DashboardWithTables = ({
  calculateSum,
  filters,
  fiat,
  offchain,
  exchange,
  wallet,
  deposit,
  pool,
  claimable,
  debt,
  staked,
  other,
}: PropsList) => {
  const isDesktop = useMediaQuery(isDesktopBreakPoint);
  const isSmallDesktop = useMediaQuery(isSmallDesktopBreakPoint);
  const isLaptop = useMediaQuery(isLaptopBreakPoint);
  const isSmallLaptop = useMediaQuery(isSmallLaptopBreakPoint);
  const isPhone = useMediaQuery(isPhoneBreakPoint);
  const isSmallPhone = useMediaQuery(isSmallPhoneBreakPoint);

  const [sortTables, setSortTables] = useState<SortAllTables>({});

  const ratioWidthTablet = useMemo(() => {
    if (isSmallPhone) {
      return 0.2;
    }
    if (isPhone) {
      return 0.5;
    }
    if (isSmallLaptop) {
      return 0.65;
    }
    if (isLaptop) {
      return 0.65;
    }
    if (isSmallDesktop) {
      return 0.65;
    }
    if (isDesktop) {
      return 0.7;
    }
    return 1;
  }, [isPhone, isDesktop, isLaptop, isSmallDesktop, isSmallLaptop, isSmallPhone]);

  const handleSort = useCallback((table: string, type: string) => {
    setSortTables((prev: any) => {
      const prevType = prev[table]?.type;
      const prevSort = prev[table]?.sort;

      if (!prev?.[table] || (prevType && prevType !== type)) {
        return {
          ...prev,
          [table]: { type: type, sort: "asc" },
        };
      }

      if (prevType === type) {
        if (prevSort === "asc") {
          return {
            ...prev,
            [table]: {
              type: prevType,
              sort: "desc",
            },
          };
        } else {
          const currentPrev = { ...prev };
          delete currentPrev[table];
          return currentPrev;
        }
      }
    });
  }, []);

  const renderHeaderTablet = useCallback(
    (column: any) => {
      const tableId = column.columnKey;
      const sortTable = sortTables?.[tableId];
      const columnName = column.prop;

      return (
        <TableSortLabel
          direction={sortTable?.sort}
          IconComponent={() => <SortIcon active={sortTable?.type === columnName} direction={sortTable?.sort} />}
          onClick={() => handleSort(tableId, columnName)}
        >
          {column.label}
        </TableSortLabel>
      );
    },
    [handleSort, sortTables]
  );

  return (
    <div className="tableStyle">
      <FiatTable
        sortTables={sortTables}
        isPhone={isPhone}
        ratioWidthTablet={ratioWidthTablet}
        renderHeaderTablet={renderHeaderTablet}
        fiat={fiat}
        filters={filters}
        calculateSum={calculateSum}
      />  
      <OffchainTable
        sortTables={sortTables}
        isPhone={isPhone}
        ratioWidthTablet={ratioWidthTablet}
        renderHeaderTablet={renderHeaderTablet}
        offchain={offchain}
        filters={filters}
        calculateSum={calculateSum}
      />            
      <ExchangeTable
        sortTables={sortTables}
        isPhone={isPhone}
        ratioWidthTablet={ratioWidthTablet}
        renderHeaderTablet={renderHeaderTablet}
        exchange={exchange}
        filters={filters}
        calculateSum={calculateSum}
      />
      <WalletTable
        sortTables={sortTables}
        isPhone={isPhone}
        ratioWidthTablet={ratioWidthTablet}
        renderHeaderTablet={renderHeaderTablet}
        wallet={wallet}
        filters={filters}
        calculateSum={calculateSum}
      />
      <DepositTable
        sortTables={sortTables}
        isPhone={isPhone}
        ratioWidthTablet={ratioWidthTablet}
        renderHeaderTablet={renderHeaderTablet}
        deposit={deposit}
        filters={filters}
        calculateSum={calculateSum}
      />
      <PoolTable
        sortTables={sortTables}
        isPhone={isPhone}
        ratioWidthTablet={ratioWidthTablet}
        renderHeaderTablet={renderHeaderTablet}
        pool={pool}
        filters={filters}
        calculateSum={calculateSum}
      />
      <ClaimableTable
        sortTables={sortTables}
        isPhone={isPhone}
        ratioWidthTablet={ratioWidthTablet}
        renderHeaderTablet={renderHeaderTablet}
        claimable={claimable}
        filters={filters}
        calculateSum={calculateSum}
      />
      <StakedTable
        sortTables={sortTables}
        isPhone={isPhone}
        ratioWidthTablet={ratioWidthTablet}
        renderHeaderTablet={renderHeaderTablet}
        staked={staked}
        filters={filters}
        calculateSum={calculateSum}
      />
      <DebtTable
        sortTables={sortTables}
        isPhone={isPhone}
        ratioWidthTablet={ratioWidthTablet}
        renderHeaderTablet={renderHeaderTablet}
        debt={debt}
        filters={filters}
        calculateSum={calculateSum}
      />
      <OtherTable
        sortTables={sortTables}
        isPhone={isPhone}
        ratioWidthTablet={ratioWidthTablet}
        renderHeaderTablet={renderHeaderTablet}
        other={other}
        filters={filters}
        calculateSum={calculateSum}
      />
    </div>
  );
};
