import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import { format } from "date-fns";

export const xlsxBalance = async (data: any) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet("Portfolio");
  worksheet.columns = [
    { header: "Wallet", key: "wallet", width: 50 },
    { header: "Address", key: "address", width: 50 },
    { header: "Network", key: "network", width: 20 },
    { header: "Category", key: "category", width: 20 },
    { header: "Protocol", key: "protocol", width: 20 },
    { header: "Name", key: "name", width: 70 },
    { header: "Symbol", key: "symbol", width: 20 },
    { header: "Balance", key: "balance", width: 30 },
    { header: "Price", key: "price", width: 30 },
    { header: "Value", key: "value", width: 30 },
    { header: "Updated (UTC)", key: "updatedAt", width: 30 },
  ];
  for (const category of data?.categories) {
    for (const network of category.networks) {
      for (const item of network.data) {
        worksheet.addRow({
          wallet: item.walletAddress,
          address: item.address,
          network: item.network,
          category: item.category,
          protocol: item.protocol,
          name: item.label,
          symbol: item.symbol,
          balance: item.balance,
          price: item.price,
          value: item.balanceUSD,
          updatedAt: item.updatedAt
        });
      }
    }
  }
  const filename = `CANVAS_Portfolio_Balance.${format(new Date(data.lastUpdate), 'yyyy-MM-dd-HH-00')}.xlsx`;
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(blob, filename);
  return filename;
};

export const xlsxTransactions = async (data: any) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet("Transaction History");
  worksheet.columns = [
    { header: "Date (UTC)", key: "date", width: 25 },
    { header: "Txn Hash", key: "txnHash", width: 80 },
    { header: "Network", key: "network", width: 15 },
    { header: "Address", key: "address", width: 50 },
    { header: "Action", key: "action", width: 20 },
    { header: "Object", key: "object", width: 15 },
    { header: "Contract Name", key: "contractName", width: 30 },
    { header: "Contract Type", key: "contractType", width: 30 },
    { header: "Contract Address", key: "contractAddress", width: 50 },
    { header: "Fee Amount", key: "feeAmount", width: 20 },
    { header: "Fee Currency", key: "feeCurrency", width: 15 },
    { header: "Sent Action", key: "sentAction", width: 20 },
    { header: "Sent Type", key: "sentType", width: 20 },
    { header: "Sent Address", key: "sentAddress", width: 50 },
    { header: "Sent Name", key: "sentName", width: 30 },
    { header: "Sent Symbol", key: "sentSymbol", width: 20 },
    { header: "Sent Amount", key: "sentAmount", width: 20 },
    { header: "Sent From", key: "sentFrom", width: 50 },
    { header: "Sent To", key: "sentTo", width: 50 },
    { header: "Received Action", key: "recvAction", width: 20 },
    { header: "Received Type", key: "recvType", width: 20 },
    { header: "Received Address", key: "recvAddress", width: 50 },
    { header: "Received Name", key: "recvName", width: 30 },
    { header: "Received Symbol", key: "recvSymbol", width: 20 },
    { header: "Received Amount", key: "recvAmount", width: 20 },
    { header: "Received From", key: "recvFrom", width: 50 },
    { header: "Received To", key: "recvTo", width: 50 },
  ];
  for (const item of data) {
    const results: any = {
      date: item.txnDate,
      txnHash: item.txnHash,
      network: item.network,
      address: item.walletAddress,
      action: item.action,
      object: item.object,
      contractName: item.contract?.name,
      contractType: item.contract?.type,
      contractAddress: item.contract?.address,
      feeAmount: item.fee?.amount,
      feeCurrency: item.fee?.currency,
    }
    if (item.assetsSent && item.assetsSent.length > 0) {
      results.sentAction = item.assetsSent[0].action;
      results.sentType = item.assetsSent[0].asset.type;
      results.sentAddress = item.assetsSent[0].asset.address;
      results.sentName = item.assetsSent[0].asset.name;
      results.sentSymbol = item.assetsSent[0].asset.symbol;
      results.sentAmount = item.assetsSent[0].amount;
      results.sentFrom = item.assetsSent[0].from;
      results.sentTo = item.assetsSent[0].to;
    }
    if (item.assetsReceived && item.assetsReceived.length > 0) {
      results.recvAction = item.assetsReceived[0].action;
      results.recvType = item.assetsReceived[0].asset.type;
      results.recvAddress = item.assetsReceived[0].asset.address;
      results.recvName = item.assetsReceived[0].asset.name;
      results.recvSymbol = item.assetsReceived[0].asset.symbol;
      results.recvAmount = item.assetsReceived[0].amount;
      results.recvFrom = item.assetsReceived[0].from;
      results.recvTo = item.assetsReceived[0].to;
    }
    worksheet.addRow(results);
    const maxLength = Math.max(item.assetsSent?.length || 0, item.assetsReceived?.length || 0);
    for (let i = 1; i < maxLength; i++) {
      const results2: any = {};
      if (item.assetsSent && item.assetsSent.length > i) {
        results2.sentAction = item.assetsSent[i].action;
        results2.sentType = item.assetsSent[i].asset.type;
        results2.sentAddress = item.assetsSent[i].asset.address;
        results2.sentName = item.assetsSent[i].asset.name;
        results2.sentSymbol = item.assetsSent[i].asset.symbol;
        results2.sentAmount = item.assetsSent[i].amount;
        results2.sentFrom = item.assetsSent[i].from;
        results2.sentTo = item.assetsSent[i].to;
      }
      if (item.assetsReceived && item.assetsReceived.length > i) {
        results.recvAction = item.assetsReceived[i].action;
        results.recvType = item.assetsReceived[i].asset.type;
        results.recvAddress = item.assetsReceived[i].asset.address;
        results.recvName = item.assetsReceived[i].asset.name;
        results.recvSymbol = item.assetsReceived[i].asset.symbol;
        results.recvAmount = item.assetsReceived[i].amount;
        results.recvFrom = item.assetsReceived[i].from;
        results.recvTo = item.assetsReceived[i].to;
      }
      worksheet.addRow(results2);
    }
  }
  const filename = `CANVAS_Transaction_History.${format(new Date(), 'yyyy-MM-dd-HH-00')}.xlsx`;
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(blob, filename);
  return filename;
};

export const xlsxTransactions2 = async (data: any, prefix: string) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet("Transaction History");
  worksheet.columns = [
    { header: "Date", key: "date", width: 25 },
    { header: "Token Symbol - Out", key: "sentSymbol", width: 20 },
    { header: "Token Name - Out", key: "sentName", width: 30 },
    { header: "Token Symbol - In", key: "recvSymbol", width: 20 },
    { header: "Token Name - In", key: "recvName", width: 30 },
    { header: "Type", key: "action", width: 20 },
    { header: "Price of Token (USD) - Out", key: "sentPrice", width: 20 },
    { header: "Amount (Qty) - Out", key: "sentAmount", width: 20 },
    { header: "Price of Token (USD) - In", key: "recvPrice", width: 20 },
    { header: "Amount (Qty) - In", key: "recvAmount", width: 20 },
    { header: "Total Value (USD)", key: "totalPrice", width: 20 },
    { header: "Fee (Qty)", key: "feeAmount", width: 20 },
    { header: "Fee Token Symbol", key: "feeCurrency", width: 15 },
    { header: "Fee Total Value (in Fiat)", key: "feePrice", width: 20 },
    { header: "Exchange", key: "exchange", width: 20 },
    { header: "Transaction Hash", key: "txnHash", width: 80 },
    { header: "Network", key: "network", width: 15 },
    { header: "Address", key: "address", width: 50 },
    { header: "Comment", key: "comment", width: 20 },
    { header: "Trade Addition", key: "addition", width: 20 },
  ];
  for (const item of data) {
    const results: any = {
      date: item.txnDate,
      txnHash: item.txnHash,
      network: item.network,
      address: item.walletAddress,
      action: item.action,
      object: item.object,
      contractName: item.contract?.name,
      contractType: item.contract?.type,
      contractAddress: item.contract?.address,
      feeCurrency: item.fee?.currency,
      feeAmount: item.fee?.amount,
      feePrice: (item.fee?.amount * item.fee?.priceUSD),
    }
    if (item.assetsSent && item.assetsSent.length > 0) {
      results.sentAction = item.assetsSent[0].action;
      results.sentType = item.assetsSent[0].asset.type;
      results.sentAddress = item.assetsSent[0].asset.address;
      results.sentName = item.assetsSent[0].asset.name;
      results.sentSymbol = item.assetsSent[0].asset.symbol;
      results.sentAmount = item.assetsSent[0].amount;
      results.sentPrice = item.assetsSent[0].priceUSD;
      results.sentFrom = item.assetsSent[0].from;
      results.sentTo = item.assetsSent[0].to;
    }
    if (item.assetsReceived && item.assetsReceived.length > 0) {
      results.recvAction = item.assetsReceived[0].action;
      results.recvType = item.assetsReceived[0].asset.type;
      results.recvAddress = item.assetsReceived[0].asset.address;
      results.recvName = item.assetsReceived[0].asset.name;
      results.recvSymbol = item.assetsReceived[0].asset.symbol;
      results.recvAmount = item.assetsReceived[0].amount;
      results.recvPrice = item.assetsReceived[0].priceUSD;
      results.recvFrom = item.assetsReceived[0].from;
      results.recvTo = item.assetsReceived[0].to;
    }
    if (!results.recvPrice && results.sentPrice > 0)
      results.totalPrice = results.sentPrice * results.sentAmount;
    else if (!results.sentPrice && results.recvPrice > 0)
      results.totalPrice = results.recvPrice * results.recvAmount;
    else if (results.sentPrice > 0 && results.recvPrice > 0)
      results.totalPrice = results.sentPrice * results.sentAmount;

    worksheet.addRow(results);
    const maxLength = Math.max(item.assetsSent?.length || 0, item.assetsReceived?.length || 0);
    for (let i = 1; i < maxLength; i++) {
      const results2: any = {};
      if (item.assetsSent && item.assetsSent.length > i) {
        results2.sentAction = item.assetsSent[i].action;
        results2.sentType = item.assetsSent[i].asset.type;
        results2.sentAddress = item.assetsSent[i].asset.address;
        results2.sentName = item.assetsSent[i].asset.name;
        results2.sentSymbol = item.assetsSent[i].asset.symbol;
        results2.sentAmount = item.assetsSent[i].amount;
        results2.sentPrice = item.assetsSent[i].priceUSD;
        results2.sentFrom = item.assetsSent[i].from;
        results2.sentTo = item.assetsSent[i].to;
      }
      if (item.assetsReceived && item.assetsReceived.length > i) {
        results2.recvAction = item.assetsReceived[i].action;
        results2.recvType = item.assetsReceived[i].asset.type;
        results2.recvAddress = item.assetsReceived[i].asset.address;
        results2.recvName = item.assetsReceived[i].asset.name;
        results2.recvSymbol = item.assetsReceived[i].asset.symbol;
        results2.recvAmount = item.assetsReceived[i].amount;
        results2.recvPrice = item.assetsReceived[i].priceUSD;
        results2.recvFrom = item.assetsReceived[i].from;
        results2.recvTo = item.assetsReceived[i].to;
      }
      worksheet.addRow(results2);
    }
  }
  const filename = `CANVAS_Transaction_History${prefix}.xlsx`;
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(blob, filename);
  return filename;
};

export const xlsxNFT = async (data: any) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet("NFTs");
  worksheet.columns = [
    { header: "Wallet", key: "wallet", width: 50 },
    { header: "Address", key: "address", width: 50 },
    { header: "Token ID", key: "tokenId", width: 20 },
    { header: "Network", key: "network", width: 20 },
    { header: "Collection", key: "collection", width: 20 },
    { header: "Media", key: "media", width: 70 },
    { header: "Name", key: "name", width: 70 },
    { header: "Floor Price", key: "price", width: 30 },
    { header: "Value (USD)", key: "value", width: 30 },
    { header: "Updated (UTC)", key: "updatedAt", width: 30 },
  ];
  for (const network of data.networks) {
    for (const item of network.items) {
      worksheet.addRow({
        wallet: item.walletAddress,
        address: item.address,
        tokenId: item.tokenId,
        network: item.network,
        collection: item.collection,
        name: item.name,
        media: item.mediaUrl,
        price: item.floorPrice,
        value: item.balanceUSD,
        updatedAt: item.updatedAt
      });
    }
  }
  const filename = `CANVAS_Portfolio_NFTs.${format(new Date(data.lastUpdate), 'yyyy-MM-dd-HH-00')}.xlsx`;
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(blob, filename);
  return filename;
};
