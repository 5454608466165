import { useState, useMemo } from "react";
import { addHours } from 'date-fns';

import useAuthStore from "@hooks/globalStores/useAuthStore";
import useWalletStore from "@hooks/globalStores/useWalletStore";
import useGetBalancePeriod from "@hooks/fetchers/Dashboard/useGetBalancePeriod";

import api from '@services/api';
import { getDates } from "@services/history";
import { xlsxBalance } from "@services/excel";

import RefreshButton from "@shared/UI/RefreshButton";
import ModuleLayout from "@shared/Layouts/ModuleLayout/ModuleLayout";
import CanvasSkeleton from "@shared/UI/CanvasSkeleton";
import BalanceModal from './BalanceModal';

import { makeStyles, Theme, Grid, Typography, IconButton } from "@material-ui/core";
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GetAppIcon from '@material-ui/icons/GetApp';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import LaunchIcon from '@material-ui/icons/Launch';
import TreeItem from '@material-ui/lab/TreeItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';


const request = api('wallets');
const getData: any = async (dateFrom: string, isAuthorized: boolean, walletId: string, parsedSelectedWallets: string) => {
  console.log('getData', dateFrom, isAuthorized, walletId, parsedSelectedWallets);
  if (walletId) {
    // public wallet address
    return await request(`${walletId}/balances?before=${dateFrom}`);
  } else if (isAuthorized) {
    // logged in user with selection of wallet addresses
    return await request(`balances?before=${dateFrom}${parsedSelectedWallets?.replace('?', '&')}`);
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(5)
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3)
  },
  tree: {
    width: '100%'
  },
  month: {
    fontWeight: 500
  },
  day: {
    color: 'rgba(128, 129, 145, 1)',
    fontWeight: 500
  },
  hour: {
    color: theme.palette.primary.main,
    fontSize: '0.9rem',
    fontWeight: 500
  },
}));

export const Snapshots = () => {
  const { parsedSelectedWallets } = useWalletStore();
  const { isAuthorized } = useAuthStore();
  const [timestamp, setTimestamp] = useState(Date.now()); // update this to trigger a reload
  const [isDownloading, setIsDownloading] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const query = useMemo(() => new URLSearchParams(window.location.search), []);
  const walletId = useMemo(() => query.get("walletId") || "", [query]);

  const { data, isLoading, message } = useGetBalancePeriod(
    isAuthorized,
    walletId,
    parsedSelectedWallets,
    timestamp
  );
  const months = useMemo(() => data ? getDates(data.dateFrom, data.dateTo) : [], [data]);

  const doRefresh = () => {
    setTimestamp(Date.now()); // update timestamp when refresh button is pressed
  };

  const doClick = (ds: string) => {
    const dt = addHours(new Date(ds), 1);
    setSelectedDate(dt.toISOString());
    setModalOpen(true);
  };

  const doDownload = (dateFrom: string) => {
    setIsDownloading(dateFrom);
    setTimeout(() => { // UI responsiveness
      const dt = addHours(new Date(dateFrom), 1).toISOString();
      getData(dt, isAuthorized, walletId, parsedSelectedWallets).then((newData: any) => {
        if (newData?.status === 'complete') {
          xlsxBalance(newData);
        } else if (newData?.status === 'not-found') {
        }
      }).catch((error: any) => {
        console.log(error);
      }).finally(() => {
        setIsDownloading('');
      });
    }, 200);
  }

  const getLastItemDate = (month: any) => {
    if (month?.days?.length > 0) {
      const lastDay = month.days[month.days.length - 1];
      if (lastDay?.hours?.length > 0) {
        const lastHour = lastDay.hours[lastDay.hours.length - 1];
        if (lastHour) {
          return lastHour.iso;
        }
      }
    }
    return '';
  }
  const classes = useStyles();
  return (
    <ModuleLayout options={{ wallets: true }}>
      <>
        <RefreshButton message={message} isRefreshing={isLoading} onRefresh={doRefresh} />
        {isLoading && (
          <CanvasSkeleton />
        )}
        {!isLoading && (
          <div className={classes.root}>
            <h4 className={classes.title}>Balance Snapshots</h4>
            {months && months.length > 0 && months.map((month: any) => (
              <Accordion key={month.label} >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item><Typography className={classes.month}>{month.label}</Typography></Grid>
                    <Grid item>
                      <IconButton size='small' color='primary' onClick={(e) => { e.stopPropagation(); doDownload(getLastItemDate(month)) }} disabled={isDownloading === getLastItemDate(month)}>
                        {isDownloading === getLastItemDate(month) && (
                          <HourglassEmptyIcon />
                        )}
                        {isDownloading !== getLastItemDate(month) && (
                          <GetAppIcon />
                        )}
                      </IconButton>
                      <IconButton size='small' color='primary' onClick={(e) => { e.stopPropagation(); doClick(getLastItemDate(month)) }} disabled={isDownloading === getLastItemDate(month)}>
                        <LaunchIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <TreeView
                    className={classes.tree}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                  >
                    {month.days && month.days.length > 0 && month.days.map((day: any) => (
                      <TreeItem key={day.label} nodeId={day.label} label={<span className={classes.day}>{day.label}</span>}>
                        {day.hours && day.hours.length > 0 && day.hours.map((hour: any) => (
                          <TreeItem key={hour.label} nodeId={hour.label} label={<span className={classes.hour}>{hour.label}</span>} onClick={() => doClick(hour.iso)} />
                        ))}
                      </TreeItem>
                    ))}
                  </TreeView>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
        <BalanceModal dateFrom={selectedDate} open={modalOpen} setOpen={setModalOpen} />
      </>
    </ModuleLayout>
  );
};
