import { useEffect, useState } from 'react';
import { format } from 'date-fns';

import useApiTradeTransactions from "@hooks/fetchers/Trade/useApiTradeTransactions";

import IconsCollectionBadgeSmall from "@shared/UI/IconsCollectionBadgeSmall";
import CanvasSkeleton from "@shared/UI/CanvasSkeleton";
import CoinIcon from "@assets/images/coin-icon.png";
import CopyIcon from "@assets/images/copy-icon.png";

import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import RefreshIcon from '@material-ui/icons/RefreshRounded';


const useStyles = makeStyles((theme) => ({
  overflow: {
    width: '100%',
    overflow: 'scroll',
  },
  table: {
    width: '100%',
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2),
  },
  label: {
    fontWeight: 500
  },
  symbol: {
    fontWeight: 500
  },
  address: {
    fontSize: '0.75rem',
    color: '#808191',
  },
  amount: {
    textTransform: 'uppercase',
    '& span': {
      fontWeight: 700
    }
  },
}));

const shrinkAddress = (uuid) => {
  if (uuid) {
    return `${uuid.substring(0, 6)}...${uuid.substring(uuid.length - 4)}`;
  }
  return '';
};

const numToFixed = (s: string, toFixed: number) => {
  const num = Number(s);
  if (!isNaN(num)) {
    return num.toFixed(toFixed);
  }
  return '';
};

const CopyTool = ({ text, label }) => {
  const [copied, setCopied] = useState("");
  return (
    <>
      <Tooltip title={copied === text ? "Copied!" : `Copy ${label}`} placement="top">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(text);
            setCopied(text);
            setTimeout(() => setCopied(''), 3000);
          }}
        >
          <img src={CopyIcon} alt="copy-icon" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export const TradeTable = ({ openModal, refreshTimestamp }) => {
  const { data, isLoading, mutate } = useApiTradeTransactions();
  useEffect(()=> { mutate() }, [mutate, refreshTimestamp]);
  const classes = useStyles();
  return (
    <>
      {isLoading && <CanvasSkeleton style={{ height: 250, marginTop: 5 }} />}
      {!isLoading && data && (
        <div className={classes.overflow}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell} align="left"><b>Wallet</b></TableCell>
                <TableCell className={classes.cell} align="left"><b>Sell</b></TableCell>
                <TableCell className={classes.cell} align="left"><b>Buy</b></TableCell>
                <TableCell className={classes.cell} align="left"><b>Transaction</b></TableCell>
                <TableCell className={classes.cell} align="center"><b>Updated</b></TableCell>
                <TableCell className={classes.cell} align="right">
                  <IconButton color="primary" onClick={() => openModal()}><AddIcon /></IconButton>
                  <IconButton color="primary" onClick={() => mutate()}><RefreshIcon /></IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell className={classes.cell}>
                    <div className={classes.label}>{row.operation}</div>
                    <div className={classes.address}>{shrinkAddress(row.walletAddress)}</div>
                  </TableCell>
                  <TableCell className={classes.cell}>

                    <Grid container spacing={0} alignItems='center' >
                      <Grid item style={{ textAlign: 'right' }}><IconsCollectionBadgeSmall mainIcon={row.fromIcon ? row.fromIcon : CoinIcon} /></Grid>
                      <Grid item style={{ textAlign: 'left' }}>
                        <div className={classes.amount}><span>{numToFixed(row.fromAmount, 4)}</span> {row.fromSymbol}</div>
                        <div className={classes.address}>{shrinkAddress(row.fromAddress)}</div>
                      </Grid>
                    </Grid>

                  </TableCell>
                  <TableCell className={classes.cell} align="center">

                    <Grid container spacing={0} alignItems='center' >
                      <Grid item style={{ textAlign: 'right' }}><IconsCollectionBadgeSmall mainIcon={row.toIcon ? row.toIcon : CoinIcon} /></Grid>
                      <Grid item style={{ textAlign: 'left' }}>
                        <div className={classes.amount}><span>{numToFixed(row.toAmount, 4)}</span> {row.toSymbol}</div>
                        <div className={classes.address}>{shrinkAddress(row.toAddress)}</div>
                      </Grid>
                    </Grid>

                  </TableCell>
                  <TableCell className={classes.cell} align="left">

                    <Grid container spacing={0} alignItems='center' >
                      <Grid item style={{ textAlign: 'left' }}>
                        <div className={classes.label}>{row.state}</div>
                        <div className={classes.address}>{shrinkAddress(row.txnHash)}</div>
                      </Grid>
                      <Grid item style={{ textAlign: 'left' }}>
                        <CopyTool text={row.txnHash} label={'Txn Hash'} />
                      </Grid>
                    </Grid>

                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    <div className={classes.label}>{format(new Date(row.updatedAt), 'dd-MMM-yyyy, H:mm a')}</div>
                    <div className={classes.address}>created: {format(new Date(row.createdAt), 'dd-MMM, H:mm a')}</div>
                    </TableCell>
                  <TableCell className={classes.cell} align="right" style={{ minWidth: '130px' }}>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </>
  );
};
