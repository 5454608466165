import { useEffect, useRef, useState, useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardActionArea, CardContent, CardMedia, CircularProgress, Grid, Typography } from "@material-ui/core";
import CanvasModal from "../../shared/UI/CanvasModal";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";

const DEFAULT_MEDIA_URL = 'https://assets.prod.cloud.canvas.co/nft/nft-icon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 381,
    minWidth: 320,
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "100%",
    },
  },
  actionArea: {
    position: "relative",
  },
  assetsIndicator: {
    background: "#fff",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nftFolderIcon: {
    height: 14,
    width: 14,
    marginLeft: 6,
  },
  item: {
    display: "flex",
    justifyContent: "center",
  },
  imageloaderWrapper: {
    width: "100%",
    height: '250px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardMedia: {
  },
  mediaWrapper: {
    background: "#666",
  },
  cardContent: {
    display: "flex",
    padding: theme.spacing(2, 4),
    background: "#fff",
    justifyContent: "space-between",
    boxShadow: "0 4px 1px -3px #b3b1ad",
    //   boxShadow: '0px 4px 20px -5px rgba(0, 0, 0, 0.1)',
  },
  name: {
    fontWeight: 700,
  },
  description: {
    padding: theme.spacing(1, 2, 2, 2),
    maxWidth: '600px',
  },
  collection: {
    paddingBottom: theme.spacing(1),
    textTransform: 'uppercase',
    fontSize: '0.75rem',
  },
  balance: {
    color: theme.palette.primary.main,
  },
  floorPrice: {
    paddingBottom: theme.spacing(1),
    textTransform: 'uppercase',
    fontSize: '0.75rem',    
  },
  itemsWrapper: {
    maxWidth: 800,
    height: 400,
    [theme.breakpoints.down("sm")]: {
      height: 550,
    },
  },
  modalGrid: {
    padding: theme.spacing(4, 2, 1, 2)
  },
  image: {
    width: '100%',
    maxWidth: '600px',
    height: 'auto',
    background: '#d2d2d2',
    border: 'solid 1px #eee'
  }
}));

export const NFTItem = ({ item }: any) => {
  const classes = useStyles();
  const [nftModal, setNFTModal] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null)!;
  const [imgLoading, setImgLoading] = useState(true);
  const { formatNumberByCurrency } = useCurrencyStore();

  const itemTitle = useMemo(() => `${item.collection || ''} ${item.collection && item.name ? '-' : ''} ${item.name || ''}`, [item]); 

  const handleClick = () => {
    setNFTModal(true);
  };

  const getMediaUrl = (url: string) => url ? url.replace('ipfs://', 'https://ipfs.io/ipfs/') : '';

  useEffect(() => {
    const loadHandler = () => setImgLoading(false);
    const errHandler = () =>  {
      setImgLoading(false);
      const img = imageRef?.current;
      if (img) { 
        img.src = DEFAULT_MEDIA_URL;
      }
    }
    const img = imageRef?.current;
    if (img) { 
      img.addEventListener("load", loadHandler);
      img.addEventListener("error", errHandler);
    }
    return () => {
      if (img) {
        img.removeEventListener("load", loadHandler);
        img.removeEventListener("error", errHandler);        
      } 
    };
  }, [imageRef]);

  return (
    <>
      <CanvasModal
        open={nftModal}
        content={
          <div>
            <img src={getMediaUrl(item.mediaUrl)} alt={item.name} className={classes.image} onError={(e) => e.currentTarget.src = DEFAULT_MEDIA_URL } />
            <Grid container justifyContent="space-between" spacing={3} className={classes.modalGrid}>
              <Grid item>
                <Typography variant="h5" className={classes.name}>{item.name}</Typography>
                <Typography variant="h6" className={classes.collection}>{item.collection}</Typography>
              </Grid>
              {item.balanceUSD !== 0 && (
                <Grid item style={{textAlign: 'right'}}>
                  <Typography variant="h5" className={classes.balance}>{formatNumberByCurrency(Number(item.balanceUSD))}</Typography>
                  <Typography variant="h6" className={classes.floorPrice}>{item.floorPrice.toFixed(4)} ETH</Typography>
                </Grid>
              )}
            </Grid>
            <Typography variant="h6" className={classes.description}>{item.description}</Typography>
          </div>
        }
        options={{
          title: itemTitle,
          maxWidth: "lg",
        }}
        setOpen={setNFTModal}
      />
      <Card className={classes.root} onClick={handleClick}>
        <CardActionArea className={classes.actionArea}>
          <div className={classes.mediaWrapper}>
            {imgLoading && (
              <div className={classes.imageloaderWrapper}>
                <CircularProgress />
              </div>
            )}
            <CardMedia
              ref={imageRef}
              component="img"
              className={classes.cardMedia}
              style={{ display: imgLoading ? "none" : "block" }}
              width='100%'
              height='250px'
              image={getMediaUrl(item.mediaUrl)}
              title={item.name}
              alt={item.name}
            />
          </div>
          <CardContent className={classes.cardContent}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" className={classes.name}>{item.name}</Typography>
              </Grid>
              {item.balanceUSD !== 0 && (
                <Grid item>
                  <Typography variant="h5" className={classes.balance}>{formatNumberByCurrency(Number(item.balanceUSD))}</Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};
