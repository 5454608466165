import { Redirect, Route } from "react-router-dom";

import useAuthStore from "@hooks/globalStores/useAuthStore";

// @ts-ignore
export default function PrivateRoute({ component: Component, ...rest }) {
    const { isAuthorized } = useAuthStore();

    return (
        <Route
            render={props =>
                isAuthorized ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/signin', state: { referer: props.location } }} />
                )
            }
            {...rest}
        />
    );
}
