import { makeStyles } from '@material-ui/core/styles';
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";

interface TableTotalBalanceProps {
  title: string;
  value: number;
}

const useStyles = makeStyles((theme) => ({
  tabHeader: {
    fontFamily: 'Cera Pro, sans-serif',
    marginBottom: 10,
    fontSize: '22px',
    fontWeight: 500,
  },
  numberText: {
    color: '#F37222',
  },
}));

export function CategoryTotalBalance({ title, value }: TableTotalBalanceProps) {
  const classes = useStyles();
  const isNegativeNumber = Math.sign(value) === -1;
  const { formatNumberByCurrency } = useCurrencyStore();

  return (
    <div className={classes.tabHeader}>
      {title}: {isNegativeNumber && <span className={classes.numberText}>(</span>}
      <span className={classes.numberText}>{formatNumberByCurrency(Math.abs(value))}</span>
      {isNegativeNumber && <span className={classes.numberText}>)</span>}
    </div>
  );
}
