import api from "./api";

const request = api("wallets");

interface IPageInfo {
  // TODO share this interface
  currentPage: number;
  perPage: number;
  totalPages: number;
  totalItems: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number | null;
  nextPage: number | null;
}

interface IWallet {
  id: number;
  address: string;
  status: string;
  createdAt?: string;
}

export const create = async (address: string): Promise<void> => {
  await request("", "post", undefined, { address });
};

export const getPage = async (): Promise<{
  items: IWallet[];
  pageInfo: IPageInfo;
}> => {
  const { items, pageInfo } = await request();
  return { items, pageInfo };
};

// export const getFirst = async (): Promise<IWallet | void> => {
//   const { items } = await request("", "get", { sort: "id", dir: "desc" });
//
//   return items[0];
// };

export const getAnauthorisedBalancesByWallet = async (
  walletID: number | string
): Promise<any> => {
  const data = await request(`${walletID}/balances`, "get");
  return data;
};

export const getAuthorisedBalancesBywallet = async (
  walletID: string
): Promise<any> => {
  const data = await request(`/balances`);

  return data;
};

export const getBalancesByCategories = async (
    walletID: string,
    categoryName: string,
): Promise<any> => {
  const data = await request(`/balances`, 'get', { category: categoryName });

  return data;
};



// get, add, edit dashboard user wallet list

export interface IUserWallet {
  address: string;
  type: string;
  title?: string;
}


export const getUserWallets = async (): Promise<IUserWallet[]> => {
  const data = await request(`/addresses`);
  return data;
};

export const addUserWallet = (address: string) => {
  return request(`/addresses`, 'post', undefined, { address });
};

export const deleteUserWallet = async (walletId: string): Promise<any> => {
  const data = await request(`/addresses/${walletId}`, 'delete');
  return data;
};

export const renameUserWallet = async (walletId: string, title: string): Promise<any> => {
  const data = await request(`/addresses/${walletId}`, 'patch', undefined, { title });
  return data;
};

// get nfts

export const getNFTByWallet = async (
    walletID?: number | string
): Promise<any> => {
  let url = `/nfts`;
  if (walletID) url = `${walletID}/nfts`;
  const data = await request(url, "get");
  return data;
};
