import {
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import useQuery from "@hooks/useQuery";
import clsx from "clsx";
import useAuthStore from "@hooks/globalStores/useAuthStore";

export interface MenuItemData {
    icon: string;
    name: string;
    to: string;
    onClick?: any;
    isActive?: (value: boolean) => void;
    className?: string;
    isPublic?: boolean;
}

export interface MenuItemProps {
    menuItem: MenuItemData;
    id?: string;
    className?: string;
    openSideBar?: boolean;
    isDrawerOpen?: boolean;
}

interface IMenuItemStylesProps {
    isDrawerOpen?: boolean;
}


const useStyles = makeStyles(() =>
    ({
        listItem: {
            height: 56,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: (props: IMenuItemStylesProps) => props.isDrawerOpen ? '0 30px' : 0,
            borderRadius: 10,
            marginBottom: 10,
            '&:hover': {
                background: '#F37222',
                borderRadius: 10,
                '& span': {
                    color: '#fff',
                },
            },
        },
        isActive: {
            background: '#F37222',
            '& span': {
                color: '#fff',
            },
        },
        listText: {
            display: (props: IMenuItemStylesProps) => props.isDrawerOpen ? 'flex' : 'none',
            color: '#C2C1C6',
        },
        listIcon: {
            minWidth: (props: IMenuItemStylesProps) => props.isDrawerOpen ? 41 : 25,

            '& img': {
              width: '24px',
              height: 'auto'
            }
        },
    })
);

export function MenuItem(props: MenuItemProps) {
    const { isDrawerOpen } = props;
    const classes = useStyles({ isDrawerOpen });
    const { isAuthorized } = useAuthStore();

    const path = useLocation();
    const query = useQuery();
    const currentPathName = props.menuItem.to.split("/");
    const isEqualPath = currentPathName.some((el) => {
        return !!el && el !== "d" && path.pathname.includes(el);
    });
    const history = useHistory();

    const handleClick = (event) => {
        if (props.menuItem.onClick) {
            props.menuItem.onClick(event);
        }

        if (props.menuItem.name === 'Logout') {
            return;
        }

        let redirectPage = props.menuItem.to;


        if (props.menuItem.to === '/signin') {
            redirectPage = props.menuItem.to;
        }

        if (!isAuthorized && query.get('walletId')) {
            redirectPage = `${props.menuItem.to}?walletId=${query.get('walletId')}`;
        }

        if (!isAuthorized && props.menuItem.name === 'Overview' && isEqualPath && query.get('walletId')) {
            redirectPage = '/signin';
        }

        history.push(redirectPage);
    };


    return (
        <ListItem
            className={clsx(classes.listItem, isEqualPath && classes.isActive)}
            onClick={handleClick}
            button
        >
            <ListItemIcon className={classes.listIcon}>
                <img src={props.menuItem.icon} alt={""} />
            </ListItemIcon>
            <ListItemText
                primary={props.menuItem.name}
                className={classes.listText}
            />
        </ListItem>
    );
}
