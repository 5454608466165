export const colorDictionary: { [name: string]: string } = {
  aave: "#47A6BF",
  "aave-amm": "#8E6EA8",
  "aave-v2": "#B6509E",
  aavegotchi: "#6F88B3",
  abracadabra: "#A44EED",
  adamant: "#CACAC9",
  alchemix: "#FBDDC7",
  "alpha-v1": "#10235A",
  "alpha-v2": "#1940AB",
  augur: "#34E7A7",
  autofarm: "#4958C5",
  "b-protocol": "#23A455",
  badger: "#F2A62E",
  "balancer-v1": "#7D8A9C",
  "balancer-v2": "#000000",
  bancor: "#5194FE",
  barnbridge: "#FA4439",
  beefy: "#5A8F69",
  bella: "#2EC0D1",
  belt: "#FBB251",
  bitcoin: "#F7931B",
  bzx: "#2079D5",
  compound: "#2ED395",
  convex: "#3A3A3A",
  cover: "#6A7AFF",
  cream: "#69E2DC",
  "cream-iron-bank": "#219995",
  curve: "#8F0600",
  "defi-swap": "#152B5A",
  defisaver: "#37B06E",
  derivadex: "#EF88AA",
  deversifi: "#6436FF",
  dforce: "#5EAEE7",
  dfyn: "#F15253",
  dhedge: "#27A4D0",
  dodo: "#FDF701",
  dsd: "#216A9F",
  dydx: "#6966FF",
  "88mph": "#803ACD",
  element: "#8FD8E7",
  ellipsis: "#5387E9",
  epns: "#E2087F",
  esd: "#2DD562",
  futureswap: "#33E0B8",
  harvest: "#F2B436",
  hegic: "#1D935F",
  idle: "#1D36FF",
  inverse: "#5448A8",
  "keeper-dao": "#6C46D6",
  "kyber-dmm": "#29A1E7",
  linkswap: "#295BDA",
  liquity: "#2EB6EA",
  loopring: "#1E42FF",
  maker: "#2CB8A8",
  mintr: "#34D1FF",
  mooniswap: "#3A619D",
  mushroom: "#C80000",
  nft: "#9AB7F3",
  "1inch": "#A1B0CA",
  opyn: "#3FFEF9",
  other: "#F0512A",
  pancakeswap: "#D1884F",
  pickle: "#48C248",
  pooltogether: "#32025E",
  popsicle: "#84B5CE",
  quickswap: "#0A174A",
  rari: "#5B5B5B",
  "rari-fuse": "#D146B9",
  realt: "#F2A91E",
  reflexer: "#1D8889",
  ribbon: "#FA385C",
  sablier: "#FAC153",
  saddle: "#3808D6",
  sfinance: "#EB3C00",
  shell: "#E6B1F5",
  smoothy: "#F1A1AD",
  snowswap: "#92E0FF",
  superfluid: "#124A1F",
  sushiswap: "#C908C2",
  "sushiswap-bentobox": "#BB10C4",
  "sushiswap-kashi": "#9E1EC7",
  swerve: "#86FCE7",
  synthetix: "#34D1FF",
  "the-graph": "#5D41D3",
  tokensets: "#4D24FF",
  tokens: "#42BDB5",
  uniswap: "#CA06E8",
  "uniswap-v2": "#F61B9A",
  "uniswap-v3": "#DD0FC6",
  unit: "#CB5BF4",
  value: "#2174EB",
  venus: "#F4BB50",
  vesper: "#4138AC",
  waultswap: "#17252A",
  xsigma: "#DE679A",
  yaxis: "#0C4368",
  yearn: "#1F255F",
  Canvas: "#F37222",
  WereOn: "#C7FE01",
};

export const chartColors = [
  "#47A6BF",
  "#8E6EA8",
  "#B6509E",
  "#6F88B3",
  "#A44EED",
  "#CACAC9",
  "#FA4439",
  "#10235A",
  "#1940AB",
  "#34E7A7",
  "#4958C5",
  "#23A455",
  "#F2A62E",
  "#7D8A9C",
  "#000000",
  "#5194FE",
  "#FA4439",
  "#5A8F69",
  "#2EC0D1",
  "#FBB251",
  "#F7931B",
  "#2079D5",
  "#2ED395",
  "#3A3A3A",
  "#6A7AFF",
  "#69E2DC",
  "#219995",
  "#8F0600",
  "#152B5A",
  "#37B06E",
  "#EF88AA",
  "#6436FF",
  "#5EAEE7",
  "#F15253",
  "#27A4D0",
  "#FDF701",
  "#216A9F",
  "#6966FF",
  "#803ACD",
  "#8FD8E7",
  "#5387E9",
  "#E2087F",
  "#2DD562",
  "#33E0B8",
  "#F2B436",
  "#1D935F",
  "#1D36FF",
  "#5448A8",
  "#6C46D6",
  "#29A1E7",
  "#295BDA",
  "#2EB6EA",
  "#1E42FF",
  "#2CB8A8",
  "#34D1FF",
  "#3A619D",
  "#C80000",
  "#9AB7F3",
  "#A1B0CA",
  "#3FFEF9",
  "#F0512A",
  "#D1884F",
  "#48C248",
  "#32025E",
  "#84B5CE",
  "#0A174A",
  "#5B5B5B",
  "#D146B9",
  "#F2A91E",
  "#1D8889",
  "#FA385C",
  "#FAC153",
  "#3808D6",
  "#EB3C00",
  "#E6B1F5",
  "#F1A1AD",
  "#92E0FF",
  "#124A1F",
  "#C908C2",
  "#BB10C4",
  "#9E1EC7",
  "#86FCE7",
  "#34D1FF",
  "#5D41D3",
  "#4D24FF",
  "#42BDB5",
  "#CA06E8",
  "#F61B9A",
  "#DD0FC6",
  "#CB5BF4",
  "#2174EB",
  "#F4BB50",
  "#4138AC",
  "#17252A",
  "#DE679A",
  "#0C4368",
  "#1F255F",
  "#F37222",
  "#C7FE01",
];

export const defaultColors = ["#837DFF", "#EA23FB", "#03A9F1", "#E42C64", "#09F9BF", "#484B50"];

export const categoriesColors = {
  pool: "#837DFF",
  fiat: "#EAFFFB",
  exchange: "#EA23FB",
  wallet: "#03A9F1",
  staked: "#09F9BF",
  deposit: "#484B50",
  claimable: "#F4BB50",
  debt: "#E42C64",
  collateral: "#0C4368",
  other: "#C7FE01",
};

export const protocolColors = {
  olympus: "#c37f3c",
  other: "#d6a15c",
  synthetix: "#f1cd71",
  klima: "#f5da79",
  lido: "#fbea7b",
  tokens: "#eae384",
  // debt: "#f28482",
  // collateral: "#0C4368",
};

export const assetColors = {
  deposits: "#eae4e9",
  debt: "#fff1e6",
  wallet: "#fde2e4",
};

export const networkColorsDictionary = {
  // arbitrum: "#DE679A",
  arbitrum: "#F0D290",
  bitcoin: "#B6509E",
  // optimism: "#216A9F",
  // optimism: "#09F9BF",
  optimism: "#781D42",
  bsc: "#F61B9A",
  // polygon: "#84B5CE",
  // polygon: "#F7931B",
  polygon: "#A3423C",
  fantom: "#F4BB50",
  avalanche: "#C7FE01",
  moonriver: "#09F9BF",
  solana: "#B6509E",
  // ethereum: "#92E0FF",
  // ethereum: "#FA4439",
  ethereum: "#F37222",
  totalBalance: "#781D42",
};
