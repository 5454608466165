import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSWRConfig } from "swr";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import { utilsService } from "@services/utils.service";
import WalletItem from "./WalletItem/WalletItem";
import CanvasModal from "../../../UI/CanvasModal";
import CanvasButton from "../../../UI/CanvasButton";
import { IUserWallet } from "@services/wallet.service";
import CanvasConfirmationModal from "../../../UI/CanvasConfirmationModal";
import useWalletStore from "@hooks/globalStores/useWalletStore";
import CanvasSkeleton from "../../../UI/CanvasSkeleton";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import WAValidator from "multicoin-address-validator";

const useStyles = makeStyles((theme) => ({
  walletList: {
    display: "flex",
  },
  walletCreate: {
    marginBottom: theme.spacing(3),
  },
  skeleton: {
    maxWidth: "380px",
    minWidth: "328px",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "100%",
    },
  },
  walletsWrapper: {
    marginBottom: 38,
  },
  backgroundShadow: {
    minHeight: "100vh",
    width: "100%",
    background: "rgba(0,0,0,0.3)",
    zIndex: 1200,
    position: "fixed",
    left: 0,
    top: 0,
  },
  label: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
    color: "rgba(128, 129, 145, 0.5)",
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  buttonGroup: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));

interface FormTypes {
  walletAddress: string;
}

export default function DashboardWallets({ walletsLoading }) {
  const classes = useStyles();
  const [canvasModalIsOpen, setCanvasModalIsOpen] = useState(false);
  const {
    handleSubmit,
    setError,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormTypes>({
    defaultValues: { walletAddress: "" },
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [copied, setCopied] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState("");
  const [renameModal, setRenameModal] = useState("");
  const {
    wallets,
    handleAddWallet,
    handleDeleteWallet,
    handleRenameWallet,
    loading,
    setSelectedWallets,
    selectedWallets,
    selectAllWallets,
    unSelectAllWallets
  } = useWalletStore();
  const { mutate } = useSWRConfig();
  const [hasWallets, setHasWallets] = useState(true);

  useEffect(() => {
    setHasWallets(!(!loading && !Boolean(wallets.length)));
  }, [wallets, loading]);

  const onSubmit = handleSubmit((data) => {
    if (wallets.some((item) => item.address.toLowerCase() === data.walletAddress.toLowerCase())) {
      setError("walletAddress", {
        type: "manual",
        message: `Wallet already exists`,
      });

      return;
    }

    let isValidAddress = false;

    for (let currency of WAValidator.getCurrencies()) {
      if (WAValidator.validate(data.walletAddress, currency.symbol)) {
        isValidAddress = true;
      }
    }

    if (!isValidAddress) {
      setError("walletAddress", {
        type: "manual",
        message: `Invalid wallet address`,
      });
      return;
    }

    setSubmitLoading(true);

    handleAddWallet(data.walletAddress, (err, _) => {
      if (err) {
        setSubmitLoading(false);
        let customMessage = "";
        if (err.response.data.message === "Duplicate address") customMessage = "Something went wrong";

        setError("walletAddress", {
          type: "manual",
          message: customMessage ? customMessage : err.response.data.message,
        });
        return;
      }

      setSubmitLoading(false);
      setCanvasModalIsOpen(false);
      setValue("walletAddress", "");
      mutate("getAuthorizedBalance");
      mutate("getAuthorizedNFTS");
      setSelectedWallets({ ...selectedWallets, [data.walletAddress]: true });
    });
  });

  const onRenameWallet = handleSubmit((data) => {
    setSubmitLoading(true);
    handleRenameWallet(renameModal, data.walletAddress, (err, _) => {
      if (err) {
        setSubmitLoading(false);
        if (err.response.data.message === "Address is not valid") {
          setError("walletAddress", {
            type: "manual",
            message: err.response.data.message,
          });
        } else {
          utilsService.notify({
            message: err?.response?.data?.message ? err.response.data.message : "Something went wrong.",
          });
        }
        return;
      }

      setSubmitLoading(false);
      setRenameModal("");
      setValue("walletAddress", "");
    });
  });

  const onDeleteWallet = (address: string) => {
    setDeleteLoading(true);

    handleDeleteWallet(address, (err, _) => {
      if (err) {
        setDeleteLoading(false);
        utilsService.notify({ message: "Something went wrong." });
        return;
      }
      setDeleteLoading(false);
      setConfirmationModal("");
      mutate("getAuthorizedBalance");
      mutate("getAuthorizedNFTS");
    });
  };

  return (
    <div className={classes.walletsWrapper}>
      {!hasWallets && <div className={classes.backgroundShadow} />}
      <CanvasModal
        open={canvasModalIsOpen}
        content={
          <>
            <Typography variant="h6" style={{ marginBottom: 13 }}>
              Track Any Wallet
            </Typography>
            <TextField
              placeholder={"Enter your wallet address and visualise your portfolio"}
              fullWidth={true}
              {...register("walletAddress", { required: "This is required." })}
              error={Boolean(errors?.walletAddress)}
              helperText={errors?.walletAddress?.message}
              variant="outlined"
            />
          </>
        }
        options={{
          title: "Add Wallet",
          action: {
            title: "Add Wallet to Watchlist",
            loading: submitLoading,
            onSubmit: onSubmit,
          },
        }}
        setOpen={setCanvasModalIsOpen}
      />
      <CanvasModal
        open={Boolean(renameModal)}
        content={
          <>
            <Typography variant="h6" style={{ marginBottom: 13 }}>
              New wallet name
            </Typography>
            <TextField
              placeholder={"Enter your wallet name"}
              fullWidth={true}
              {...register("walletAddress", { required: "This is required." })}
              error={Boolean(errors?.walletAddress)}
              helperText={errors?.walletAddress?.message}
              variant="outlined"
            />
          </>
        }
        options={{
          title: "Rename Wallet",
          action: {
            title: "Change",
            loading: submitLoading,
            onSubmit: onRenameWallet,
          },
        }}
        setOpen={() => setRenameModal("")}
      />
      <CanvasConfirmationModal
        open={Boolean(confirmationModal)}
        content={
          <>
            <p>Are you sure you want to delete this wallet?</p>
            <h5>{wallets?.find((item: IUserWallet) => item.address === confirmationModal)?.title}</h5>
            <Typography variant="h6" style={{ marginBottom: 24 }}>
              {confirmationModal}
            </Typography>
          </>
        }
        options={{
          title: "Remove Wallet",
          action: {
            title: "Delete",
            loading: deleteLoading,
            onAccept: () => onDeleteWallet(confirmationModal),
          },
        }}
        setOpen={() => setConfirmationModal("")}
      />
      <Grid container className={classes.walletCreate} alignItems="center" justifyContent="space-between" spacing={3}>
        <Grid item><Typography variant="h2">Wallets</Typography></Grid>
        <Grid item>
          <span className={classes.label}>select: </span>
          <ButtonGroup color="primary" className={classes.buttonGroup}>
            <Button onClick={() => selectAllWallets()}>All</Button>
            <Button onClick={() => unSelectAllWallets()}>None</Button>
          </ButtonGroup>

          <CanvasButton onClick={() => setCanvasModalIsOpen(true)} className={classes.button}>
            Add Wallet to Watchlist
          </CanvasButton>
        </Grid>
      </Grid>
      <Grid container className={classes.walletList} spacing={3}>
        {loading &&
          Array.from(new Array(3)).map((item, index) => (
            <Grid item xs={12} lg={4} xl={3} key={index}>
              <CanvasSkeleton className={classes.skeleton} height={130} />
            </Grid>
          ))}
        {wallets?.map((item: IUserWallet, index) => (
          <Grid key={item.address + index} item xs={12} sm={12} md={6} lg={4} xl={3}>
            <WalletItem
              copied={copied}
              loading={walletsLoading}
              walletNumber={wallets.length}
              onCheckboxChange={(address) => {
                // if (index === 0) return;
                setSelectedWallets({ ...selectedWallets, [address]: !selectedWallets[address] });
              }}
              // checkboxDisabled={index === 0}
              setCopied={setCopied}
              name={item.type}
              title={item.title}
              checked={Boolean(selectedWallets[item.address])}
              onRename={(address) => setRenameModal(address)}
              onDelete={(address) => setConfirmationModal(address)}
              address={item.address}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
