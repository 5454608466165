import { useEffect, useState, useCallback } from 'react';
import api from '@services/api';
import { format } from 'date-fns';

const request = api('wallets');

const getData: any = async (dateFrom: string, isAuthorized: boolean, walletId: string, parsedSelectedWallets: string) => {
  if (walletId) {
    // public wallet address
    return await request(`${walletId}/balances?before=${dateFrom}`);
  } else if (isAuthorized) {
    // logged in user with selection of wallet addresses
    return await request(`balances?before=${dateFrom}${parsedSelectedWallets?.replace('?', '&')}`);
  }
};

export default function useGetBalanceSummary(dateFrom: string, isAuthorized: boolean, walletId: string | null, parsedSelectedWallets: string | null, timestamp: number) {
  // console.log('useGetBalanceSummary', dateFrom, isAuthorized, walletId, parsedSelectedWallets, timestamp);
  const [prevTimestamp, setPrevTimestamp] = useState(0);
  const [data, setData] = useState();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const doLoad = useCallback(() => {
    if (!isLoading) {
      setIsLoading(true);
      setMessage('Refreshing wallet details ....');
      setTimeout(() => { // UI responsiveness
        getData(dateFrom, isAuthorized, walletId, parsedSelectedWallets).then((newData) => {
          console.log('balance', newData);
          if (newData?.status === 'complete') {
            setMessage('Historic wallet details retrieved from: ' + format(new Date(newData.lastUpdate), `'&nbsp;<span>'dd-MMM-yyyy, H:mm a'</span>&nbsp;' z`));
          } else if (newData?.status === 'not-found') {
            if (walletId) {
              setMessage('No data found for this wallet.');
            }
            else {
              setMessage('No data found for these wallets.');
            }          
          }
          setData(newData);
        }).catch((error: any) => {
          console.log(error);
          if (error.response) {
            setMessage('Error: <span>' + error.response.data.message + '</span>. Please try again...');
          } else {
            setMessage('<span>' + error + '</span>');
          }
        }).finally(() => {
          setIsLoading(false);
        });
      }, 200);
    }
  }, [dateFrom, isAuthorized, walletId, parsedSelectedWallets, isLoading]);

  useEffect(() => {
    if (timestamp !== prevTimestamp) {
      doLoad();
    }
  }, [doLoad, timestamp, prevTimestamp]);

  useEffect(() => {
    setPrevTimestamp(timestamp);
  }, [timestamp]);
  
  return {
    userData: data as any,
    isLoading: isLoading,
    message: message,
  };
}
