import { useMemo, useState } from "react";
import clsx from "clsx";

import AllAssetsIcon from "@assets/images/all-assets-icon.svg";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { DashboardTable } from "@pages/Dashboard/components/Tables/DashboardTable";
// import { EmptyDashboard } from "@pages/Dashboard/EmptyDashboard";
import IconsCollectionBadge from "../../../../../shared/UI/IconsCollectionBadge";
import { getSortData } from "../../../../../utils/sortingData";
import { utilsService } from "@services/utils.service";

export const StakedTable = ({ sortTables, isPhone, ratioWidthTablet, renderHeaderTablet, staked, filters, calculateSum }) => {
  const [tableError, setTableError] = useState(false);
  const { formatNumberByCurrency } = useCurrencyStore();

  const total: number = useMemo(() => filters ? calculateSum(staked) : staked?.totalBalance?.toFixed(2), [filters, staked, calculateSum]);

  const stakedCategoryName = useMemo(() => {
    return staked?.networks.map((item: any) => utilsService.getNetworkName(item?.name));
  }, [staked?.networks]);

  const sortDataStakedArray = useMemo(() => {
    if (staked) {
      try {
        const dataStakedArray = staked?.networks.map((item: any) => {
          const dataStaked = item.data.sort((a, b) => b.balanceUSD - a.balanceUSD).reduce(
            (acc: any, value: any) => {
              return {
                dataArray: [
                  ...acc.dataArray,
                  {
                    label: value.label && value.label !== "label" ? value.label : (value.protocol ? value.protocol : ''),
                    mainIcon: value.imageUrl,
                    protocolIcon: value.protocolImageUrl,
                    price: value?.price ? (+value.price)?.toFixed(4) : 0,
                    balance: (value.balance
                      ? value.balance : (value?.tokens?.length ? value.tokens[0].balance : 0)).toFixed(4),
                    value: value.balanceUSD?.toFixed(2),
                    apy: value.apy,
                  },
                ],
                sum: acc.sum + value.balanceUSD,
              };
            },
            {
              dataArray: [],
              sum: 0,
            },
          );

          const sortDataStaked = getSortData(
            dataStaked?.dataArray,
            sortTables?.[`staked${item.name}`],
          );
          return [
            ...sortDataStaked,
            {
              label: `Total Assets: ${sortDataStaked.length}`,
              mainIcon: '',
              protocolIcon: '',
              balance: '',
              value: dataStaked.sum.toFixed(2),
              isEndRow: true,
            },
          ];
        });
        return dataStakedArray;
      } catch (e) {
        console.log('----stakedError');
        console.log(e);
        setTableError(true);
      }
    }
  }, [staked, sortTables]);
  const currentColumnsStaked = useMemo(() => {
    if (!isPhone) {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 388 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge-desktop')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} protocolIcon={value.protocolIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 250 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 250 * ratioWidthTablet,
          render: (value: any) => {
            return (<>{!value.isEndRow && (<span className='table-text'>{formatNumberByCurrency(value.price, { toFixed: 4 })}</span>)}</>);
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "APY",
          prop: "apy",
          width: 200 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <>
                {!value.isEndRow && <span className="table-text">{value.apy ? (value.apy * 100).toFixed(2) + "%" : "-"}</span>}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },      
        {
          label: 'Value',
          prop: 'value',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 400 * ratioWidthTablet,
          fixed: 'left',
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge')}>
                <IconsCollectionBadge
                  mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 350 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return (<>{!value.isEndRow && (<span className='table-text'>{formatNumberByCurrency(value.price, { toFixed: 4 })}</span>)}</>);
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'APY',
          prop: 'apy',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return (<>{!value.isEndRow && (<span className='table-text'>{(value.apy ? (value.apy*100).toFixed(2) + "%" : "-")}</span>)}</>);
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);


  if (tableError) return <></>;

  if (staked && Number(total) !== 0) {
    return <DashboardTable
      dataArray={sortDataStakedArray}
      columns={currentColumnsStaked}
      categoryNameArray={stakedCategoryName}
      title='Staked'
      className='dashboard-table'
      titleValue={total}
    />;
  } else {
    return <></>;
  }
};
