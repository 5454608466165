import CanvasButton from "@shared/UI/CanvasButton";

import { makeStyles, Theme, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import DownloadIcon from '@material-ui/icons/CloudDownload';

interface IRefreshButton {
  message: string;
  isRefreshing: boolean;
  onRefresh: Function;
  isDownloading?: boolean;
  onDownload?: Function;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(2),
  },
  message: {
    fontWeight: 400,
    "& span": {
      fontWeight: 700,
    },
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

export function RefreshButton(props: IRefreshButton) {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Grid container spacing={3} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography className={classes.message} dangerouslySetInnerHTML={{ __html: props.message }} />
        </Grid>
        <Grid item>
          {props.onRefresh && (
            <CanvasButton
              className={classes.button}
              loading={props.isRefreshing}
              disabled={props.isRefreshing || props.isDownloading}
              disableElevation
              startIcon={<RefreshIcon />}
              onClick={() => props.onRefresh()}
              style={{ minWidth: '105px' }}
            >
              Refresh
            </CanvasButton>
          )}
          {props.onDownload && (
            <CanvasButton
              className={classes.button}
              loading={props.isDownloading}
              disabled={props.isRefreshing || props.isDownloading}
              disableElevation
              startIcon={<DownloadIcon />}
              onClick={() => props.onDownload && props.onDownload()}
              style={{ minWidth: '123px' }}
            >
              Download
            </CanvasButton>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default RefreshButton;
