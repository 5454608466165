import api from "./api";

const request = api('trade');

export const requestQuote = async (data: any) => {
  console.log('requestQuote', data);
  const response = await request('quote', 'post', {}, data);
  console.log('requestQuote', response);
  return response;
};

export const approveTrade = async (quoteId: string) => {
  console.log('approveTrade', quoteId);
  const response = await request('approve', 'post', {}, { quoteId });
  console.log('approveTrade', response);
  return response;
};

export const executeTrade = async (quoteId: string) => {
  console.log('executeTrade', quoteId);
  const response = await request('execute', 'post', {}, { quoteId });
  console.log('executeTrade', response);
  return response;
};

export const getTradeAllowance = async (quoteId: string) => {
  console.log('getTradeAllowance', quoteId);
  const response = await request('allowance', 'post', {}, { quoteId });
  console.log('getTradeAllowance', response);
  return response;
};

export const getTradeStatus = async (id: string) => {
  console.log('getTradeStatus', id);
  const response = await request(`status/${id}`, 'get', {}, {});
  console.log('getTradeStatus', response);
  return response;
};

export const getTradeTransactions = async (clientId: string) => {
  console.log('getTradeTransactions', clientId);
  const response = await request(`transactions/${clientId}`, 'get', {}, {});
  console.log('getTradeTransactions', response);
  return response;
};

export const parseTradeProgress = (status: string) => {
  console.log('parseTradeProgress', status);
  switch (status) {
    case 'SUBMITTED': return 10;
    case 'QUEUED': return 30;
    case 'PENDING_SIGNATURE': return 50;
    case 'CONFIRMING': return 70;
    case 'COMPLETED': return 100;
    case 'CANCELLED': return 100;
    case 'FAILED': return 100;
  }
  return 0;
};
