
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, IconButton } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import NumberFormat from "react-number-format";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
    container: {

    },
    titles: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        marginBottom: 4,
    },
    inputWrapper: {
        height: '62px',
        width: '100%',
        background: '#F4F2F0',
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        padding: '0 21px',
        justifyContent: 'space-between',
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 8,
    },
    inputMax: {
        background: '#F37222',
        width: 55,
        height: 30,
        color: '#fff',
        fontSize: '1.125rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius:8,
    },
    textField: {
        width: '100%',

        '& > div::before': {
            display: 'none',
        },

        '& input': {
            '&::placeholder': {
                color: 'rgb(112, 119, 127)',
                fontSize: '1rem',
            },
            color: '#808191',
            fontSize: '1.5rem',
        },

        '& > div::after': {
            display: 'none',
        }
    },
    disabled: {
        background: 'rgba(0, 0, 0, 0.1)',
    },
}));

interface ITradeInput {
    label: string;
    value?: string;
    loading?: boolean;
    error?: boolean;
    onChange?: (e) => void;
    onRefresh?: () => void;
    isRefreshing?: boolean;
    disabled?: boolean;
    placeholder?: string;
    tokenData?;
    errorText?: string;
}

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat<any> | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            allowLeadingZeros={false}
            thousandSeparator={" "}
            isNumericString
        />
    );
}

export const TradeInput = (
    {
        label,
        value,
        loading,
        error,
        onChange,
        onRefresh,
        isRefreshing,
        disabled,
        placeholder,
        tokenData,
        errorText
    }: ITradeInput) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => setInputValue(value), [value])

    const renderInput = () => {
        if (loading) {
            return <Typography style={{ color: '#70777F' }}>Loading...</Typography>
        }

        if (!loading) {
            return (
                <>
                    <TextField className={clsx(classes.textField)}
                               InputProps={{
                                   inputComponent: NumberFormatCustom as any,
                               }}
                               placeholder={placeholder}
                               disabled={disabled}
                               onChange={(e) => {
                                   if (onChange) onChange(e);
                                   setInputValue(e.target.value);
                               }}
                               name="numberformat"
                               value={inputValue} />
                    {/*<div className={classes.inputMax}>*/}
                    {/*    MAX*/}
                    {/*</div>*/}
                </>
            );
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.titles}>
                <Typography style={{ fontSize: '0.8125rem' , fontWeight: 500}}>{label}</Typography>
                <Typography style={{ fontSize: '0.8125rem' , fontWeight: 400}}>
                  <IconButton color="primary" size="small" disabled={isRefreshing} onClick={() => onRefresh ? onRefresh() : undefined}><RefreshIcon /></IconButton>                  
                  <span style={{ color: 'rgb(128, 129, 145)'}}>Balance: </span>
                  <span style={{fontWeight: 500}}>{tokenData?.balance ? tokenData.balance.toFixed(4) : 0 } </span>
                  <span>{tokenData?.symbol ? tokenData.symbol : ""} </span>
                </Typography>
                
            </div>
            <div className={clsx(classes.inputWrapper, disabled && classes.disabled)}>
                {renderInput()}
            </div>
            <div className={classes.footer}>
                {
                    error && errorText ? <Typography style={{ color: '#DA1212', fontSize: '0.8125rem' }}>{errorText}</Typography> : <div></div>
                }

                <Typography style={{ color: '#808191', fontSize: '0.8125rem' }}>
                    {inputValue ? inputValue : 0 } {tokenData?.symbol ? tokenData.symbol : ""} / {inputValue && tokenData?.price ? ((+inputValue)*tokenData?.price).toFixed(2) : 0} USD
                </Typography>
            </div>
        </div>
    );
};
