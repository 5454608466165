import React from 'react';

import { AuthProvider } from "@contexts/AuthProvider";
import { WalletProvider } from "@contexts/WalletProvider";
import { CurrencyProvider } from "@contexts/CurrencyProvider";
import { HideZerosProvider } from "@contexts/HideZerosProvider";

const ContextProviders = ({ children }) => {
    return (
        <AuthProvider>
            <CurrencyProvider>
                <WalletProvider>
                    <HideZerosProvider>
                        {children}
                    </HideZerosProvider>
                </WalletProvider>
            </CurrencyProvider>
        </AuthProvider>
    );
};

export default ContextProviders;
