import { utilsService } from "@services/utils.service";

import IconsCollectionBadge from "@shared/UI/IconsCollectionBadge";
import CoinIcon from "@assets/images/coin-icon.png";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Chip, LinearProgress } from "@material-ui/core";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import LoopIcon from '@material-ui/icons/Loop';
import TickIcon from '@material-ui/icons/CheckCircleOutline';
import CrossIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  content: {
  },
  subtitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  symbol: {
    fontWeight: 500
  },
  address: {
    fontSize: '0.75rem',
    color: '#808191',
  },
  amount: {
    marginTop: theme.spacing(1),
    textTransform: 'uppercase',
    '& span': {
      fontWeight: 700
    }
  },
  grid: {
    marginBottom: theme.spacing(3)
  },
  gridProgress: {
    marginTop: theme.spacing(3)
  },  
  assetLHS: {
    textAlign: 'right',
  },
  assetMid: {
    textAlign: 'left',
  },
  assetRHS: {
    textAlign: 'left',
  },
  item: {
    textAlign: 'right',
    fontWeight: 300
  },
  arrow: {
    fontSize: '80px'
  },
  chip: {
    fontWeight: 500
  },
  footnote: {
    fontSize: '0.75rem',
    color: '#808191',
    textAlign: 'center'
  },
  status: {
    fontWeight: 500,
    whiteSpace: 'nowrap'
  }
}));

export const TradeInfo = ({ quote, allowanceLoading, allowanceRefresh, transactionProgress, transactionStatus }) => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Typography component='h3' className={classes.subtitle}>Wallet Address: {utilsService.shrinkWalletAddress(quote.request.walletAddress)}</Typography>
      <Grid container spacing={2} justifyContent='center' alignItems='center' className={classes.grid}>
        <Grid item xs={5} className={classes.assetLHS}>
          <Grid container spacing={0} alignItems='center' justifyContent="flex-end" style={{ width: '100%' }} >
            <Grid item style={{ textAlign: 'right' }}>
              <Typography component='h6' className={classes.symbol}>{quote.form.selectedTokenData.title}</Typography>
              <Typography className={classes.address}>{utilsService.shrinkWalletAddress(quote.response.sellTokenAddress)}</Typography>
            </Grid>
            <Grid item style={{ textAlign: 'left' }}><IconsCollectionBadge mainIcon={quote.form.selectedTokenData.mainIcon ? quote.form.selectedTokenData.mainIcon : CoinIcon} protocolIcon={quote.form.selectedTokenData.protocolIcon} align="right" /></Grid>
          </Grid>
          <Typography className={classes.amount}>sell: <span>{(quote.response.sellAmount / Math.pow(10, quote.form.selectedTokenData.decimals || 0)).toFixed(4)}</span> {quote.form.selectedTokenData.symbol}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.assetMid}>
          <ArrowRightAltIcon color='primary' className={classes.arrow} />
        </Grid>
        <Grid item xs={5} className={classes.assetRHS}>
          <Grid container spacing={0} alignItems='center' >
            <Grid item style={{ textAlign: 'right' }}><IconsCollectionBadge mainIcon={quote.form.destinationTargetData.mainIcon ? quote.form.destinationTargetData.mainIcon : CoinIcon} protocolIcon={quote.form.destinationTargetData.protocolIcon} /></Grid>
            <Grid item style={{ textAlign: 'left' }}>
              <Typography component='h6' className={classes.symbol}>{quote.form.destinationTargetData.title}</Typography>
              <Typography className={classes.address}>{utilsService.shrinkWalletAddress(quote.response.buyTokenAddress)}</Typography>
            </Grid>
          </Grid>
          <Typography className={classes.amount}>buy: <span>{(quote.response.buyAmount / Math.pow(10, quote.form.destinationTargetData.decimals || 0)).toFixed(4)}</span> {quote.form.destinationTargetData.symbol}</Typography>
        </Grid>
      </Grid>

      {(quote?.allowance || allowanceLoading) && (
        <Grid container spacing={1} justifyContent='center' alignItems='center' className={classes.grid}>
          <Grid item xs={4} style={{ textAlign: 'right' }}>Transaction Allowance: </Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}><Chip color="primary" disabled={allowanceLoading} icon={allowanceLoading ? <LoopIcon /> : quote?.allowance?.isApproved ? <TickIcon /> : <CrossIcon />} label={allowanceLoading ? 'loading ...' : quote?.allowance?.isApproved ? 'approved' : 'insufficient'} onClick={() => allowanceRefresh ? allowanceRefresh() : undefined} className={classes.chip} /></Grid>
          <Grid item xs={4} style={{ textAlign: 'left' }}>
            {quote?.allowance && (
              <>
                (amount: {(quote?.allowance?.amount / Math.pow(10, quote.form.selectedTokenData.decimals || 0)).toFixed(4)})
              </>
            )}
          </Grid>
        </Grid>
      )}

      <Typography component='h3' className={classes.subtitle}>Quote Response</Typography>
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={6} className={classes.item}>estimated gas:</Grid><Grid item xs={6}>{quote.response.estimatedGas}</Grid>
        <Grid item xs={6} className={classes.item}>estimated price:</Grid><Grid item xs={6}>{Number(quote.response.price).toFixed(6)}</Grid>
        <Grid item xs={6} className={classes.item}>guaranteed price:</Grid><Grid item xs={6}>{Number(quote.response.guaranteedPrice).toFixed(6)}</Grid>
        {quote.response.expectedSlippage > 0 && (
          <>
            <Grid item xs={6} className={classes.item}>expected slippage:</Grid><Grid item xs={6}>{quote.response.expectedSlippage}</Grid>
          </>
        )}
      </Grid>
      <div className={classes.footnote}>note: the transaction will only succeed if the 'guaranteed price' is achieved.</div>
      {transactionProgress > 0 && (
        <Grid container spacing={1} justifyContent='center' alignItems='center' className={classes.gridProgress}>
          <Grid item className={classes.item}>transaction:</Grid>
          <Grid item style={{width: '50%'}}><LinearProgress variant="determinate" value={transactionProgress} /></Grid>
          <Grid item className={classes.status}>{transactionStatus ? transactionStatus.replace('_', ' ') : ''}</Grid>          
        </Grid>
      )}
    </div>
  );
}

/*
{
    "chainId": 1,
    "price": "0.999",
    "guaranteedPrice": "0.989",
    "estimatedPriceImpact": "0.2173",
    "to": "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
    "data": "0xd9627aa4000000000000000000000000000000000000000000000000000000000000008000000000000000000000000000000000000000000000000000000000000003e800000000000000000000000000000000000000000000000000000000000003dd00000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000000002000000000000000000000000a0b86991c6218b36c1d19d4a2e9eb0ce3606eb48000000000000000000000000dac17f958d2ee523a2206206994597c13d831ec7869584cd00000000000000000000000010000000000000000000000000000000000000110000000000000000000000000000000000000000000000ea6b72c47b6318f199",
    "value": "0",
    "gas": "170794",
    "estimatedGas": "170794",
    "from": "0xfbabd7a2f65e7efd4e7e9fa4485acc3c9cac27ad",
    "gasPrice": "35556500000",
    "protocolFee": "0",
    "minimumProtocolFee": "0",
    "buyTokenAddress": "0xdac17f958d2ee523a2206206994597c13d831ec7",
    "sellTokenAddress": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    "buyAmount": "999",
    "sellAmount": "1000",
    "sources": [
        {
            "name": "0x",
            "proportion": "0"
        },
        {
            "name": "Uniswap",
            "proportion": "0"
        },
        {
            "name": "Uniswap_V2",
            "proportion": "0"
        },
        {
            "name": "Curve",
            "proportion": "0"
        },
        {
            "name": "Balancer",
            "proportion": "0"
        },
        {
            "name": "Balancer_V2",
            "proportion": "0"
        },
        {
            "name": "Bancor",
            "proportion": "0"
        },
        {
            "name": "BancorV3",
            "proportion": "0"
        },
        {
            "name": "mStable",
            "proportion": "0"
        },
        {
            "name": "SushiSwap",
            "proportion": "1"
        },
        {
            "name": "Shell",
            "proportion": "0"
        },
        {
            "name": "MultiHop",
            "proportion": "0"
        },
        {
            "name": "DODO",
            "proportion": "0"
        },
        {
            "name": "DODO_V2",
            "proportion": "0"
        },
        {
            "name": "LiquidityProvider",
            "proportion": "0"
        },
        {
            "name": "CryptoCom",
            "proportion": "0"
        },
        {
            "name": "Lido",
            "proportion": "0"
        },
        {
            "name": "MakerPsm",
            "proportion": "0"
        },
        {
            "name": "KyberDMM",
            "proportion": "0"
        },
        {
            "name": "Component",
            "proportion": "0"
        },
        {
            "name": "Saddle",
            "proportion": "0"
        },
        {
            "name": "Uniswap_V3",
            "proportion": "0"
        },
        {
            "name": "Curve_V2",
            "proportion": "0"
        },
        {
            "name": "ShibaSwap",
            "proportion": "0"
        },
        {
            "name": "Synapse",
            "proportion": "0"
        },
        {
            "name": "Synthetix",
            "proportion": "0"
        }
    ],
    "orders": [
        {
            "type": 0,
            "source": "SushiSwap",
            "makerToken": "0xdac17f958d2ee523a2206206994597c13d831ec7",
            "takerToken": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            "makerAmount": "999",
            "takerAmount": "1000",
            "fillData": {
                "tokenAddressPath": [
                    "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
                    "0xdac17f958d2ee523a2206206994597c13d831ec7"
                ],
                "router": "0xd9e1ce17f2641f24ae83637ab66a2cca9c378b9f"
            },
            "fill": {
                "input": "1000",
                "output": "999",
                "adjustedOutput": "1",
                "gas": 90000
            },
            "sourcePathId": "0xbe1462f682cdcc95235b84af292178a48c372e924dd7ff6a7004ed02611330f8"
        }
    ],
    "allowanceTarget": "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
    "decodedUniqueId": "ea6b72c47b-1662579097",
    "sellTokenToEthRate": "1572.20998",
    "buyTokenToEthRate": "1574.05757",
    "expectedSlippage": null
}
*/