import useSWR from 'swr';
import api from "@services/api";
import useAuthStore from "@hooks/globalStores/useAuthStore";

const options = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
};

const request = api("trade");
const getTradeTransactions = (_) => request(`/transactions`);

export default function useApiTradeTransactions() {
    const { isAuthorized } = useAuthStore();
    const { data, error, isValidating, mutate } = useSWR(isAuthorized ? 'getTradeTransactions': null, getTradeTransactions, options)
    return {
        data: data?.data || [],
        isLoading: isValidating,
        error: error,
        mutate: mutate
    }
}
