import React, { useState } from "react";

import { Divider, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";

import ArrowBackSquare from "@assets/images/arrow-back-square.svg";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    tabsWrapper: {
        flex: 0.5,
        marginRight: 30,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            flex: 1,
        },
    },
    detailsWrapper: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            overflowX: 'scroll',
        },
    },
    tabContent: {},
    tabItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15,
        padding: '7px 10px',
        justifyContent: 'space-between',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
    },
    tabItemActive: {
        background: 'rgba(243, 114, 34, 0.1)',
        padding: '7px 10px',
        borderRadius: 10,
        [theme.breakpoints.down('xs')]: {
            background: 'transparent',
            padding: 0,
        },
    },
    imgWrapper: {
        height: 40,
        width: 40,
        background: '#F37222',
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 12,
    },
    backWrapper: {
        display: 'flex',
        marginBottom: 20,
        '&:hover': {
            cursor: 'pointer',
        },
        '& img': {
            marginRight: 10,
        },
    },
    tabItemLeftInfoWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    tabItemCount: {
        height: 20,
        width: 20,
        background: '#FEF1E9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.6875rem',
        borderRadius: 4,
        color: '#333042',
    },
    tabItemCountActive: {
        background: '#F37222',
        color: '#fff',
    },
    titleDescription: {
        fontSize: '1.125rem',
        color: '#808191',
        fontWeight: 400,
    },
}));

const TabLayout = ({ data, initialSelectedTab, title, divider = true }) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const [showMobileView, setShowMobileView] = useState(false);

    const handleTabClick = (item) => {
        if (xs) setShowMobileView(true);
        setSelectedTab(item.value);
    };

    return (
        <>
            {!showMobileView && <div className={classes.tabsWrapper}>
            <Typography variant="h3" style={{ marginBottom: 32 }}>{title}</Typography>
            <div className={classes.tabContent}>
                {
                    data.map((item, index) => <div
                        onClick={() => handleTabClick(item)}
                        key={index}
                        className={clsx(classes.tabItem, selectedTab === item.value && classes.tabItemActive)}>
                        <div className={classes.tabItemLeftInfoWrapper}>
                            <div className={classes.imgWrapper}>
                                <img src={item.icon} alt=""/>
                            </div>
                            <Typography>{item.name}</Typography>
                        </div>

                        {typeof item?.count === 'number'
                            && <div className={clsx(classes.tabItemCount, selectedTab === item.value && classes.tabItemCountActive)}>
                            {item.count}
                        </div>}
                    </div>)
                }
            </div>
        </div>
            }
            {
                (!xs || showMobileView) && <div className={classes.detailsWrapper}>
                    {data.filter(item => item.value === selectedTab).map((item, index) => <div key={index}>
                        {showMobileView && <div onClick={() => setShowMobileView(false)} className={classes.backWrapper}>
                            <img src={ArrowBackSquare} alt=""/>
                            <Typography>Back</Typography>
                        </div>}
                        <div  style={{ marginBottom: item?.contentTitle ? 22 : 32 }}>
                            <Typography variant="h3">
                                {item?.contentTitle ? item.contentTitle : item.name}
                            </Typography>
                            {
                                item?.contentTitleDescription && <Typography className={classes.titleDescription}>
                                    {item?.contentTitleDescription}
                                </Typography>
                            }
                        </div>
                        {divider && <Divider style={{ background: '#000', marginBottom: 32 }}/>}
                        {item.content}
                    </div>)}
                </div>
            }
        </>
    );
};

export default TabLayout;
