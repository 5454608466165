import { useState } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";

import SummaryWalletItem from "@pages/Dashboard/components/DashboardSummaryTotal/SummaryModal/SummaryWalletItem/SummaryWalletItem";
import ChartBreakdown from "@pages/Dashboard/components/DashboardSummaryTotal/SummaryModal/СhartBreakdown/ChartBreakdown";
import SummaryModalSkeleton from "@pages/Dashboard/components/DashboardSummaryTotal/SummaryModal/SummaryModalSkeleton/SummaryModalSkeleton";
import SummaryModalTable from "@pages/Dashboard/components/DashboardSummaryTotal/SummaryModal/SummaryModalTable/SummaryModalTable";
import useWalletStore from "@hooks/globalStores/useWalletStore";
import { Typography } from "@material-ui/core";
import useGetBalancesByCategory from "@hooks/fetchers/Dashboard/useGetBalancesByCategory";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "90vh",
    width: "50vw",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  walletsWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(250px, 1fr))",
    marginBottom: 70,
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2, minmax(250px, 1fr))",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(1, minmax(250px, 1fr))",
    },
    gridGap: 20,
  },
  platformTitle: {
    marginBottom: 44,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 25,
    },
  },
  tablesWrapper: {
    paddingBottom: 20,
  },
  managedWallets: {},
  detailedWallets: {},
}));

export default function SummaryModal({ rawName, name, filters, value }) {
  const classes = useStyles();
  const [walletCopied, setWalletCopied] = useState("");

  const { wallets, selectedWallets } = useWalletStore();
  const { userData, isLoading } = useGetBalancesByCategory(rawName);
  const isManagedExisted = Boolean(userData?.managed?.platforms?.length && userData?.managed?.networks?.length);

  const detailedNetwork = () => {
    const networks = userData?.detailed?.networks;

    const data =
      filters && !filters.includes("allNetworks") && networks
        ? networks.filter(({ name }: any) => filters.includes(name))
        : networks;

    return data;
  };

  return (
    <div className={classes.root}>
      {isLoading && <SummaryModalSkeleton />}
      {!isLoading && !userData?.detailed && !isManagedExisted && <Typography>No existing data</Typography>}
      {isManagedExisted && (
        <div className={classes.managedWallets}>
          <SummaryWalletItem canvasBlockhead={true} />
          <ChartBreakdown filters={filters} title={"Asset Breakdown"} chartData={userData?.managed?.platforms} />
          <ChartBreakdown filters={filters} title={"Network Breakdown"} chartData={userData?.managed?.networks} />
        </div>
      )}
      {userData?.detailed && (
        <div className={classes.detailedWallets}>
          <div className={classes.walletsWrapper}>
            {wallets
              ?.filter((item) => item.type !== "canvas" && selectedWallets[item.address])
              .map((item, index) => (
                <SummaryWalletItem
                  name={item.type}
                  title={item.title}
                  key={index}
                  copied={walletCopied}
                  setCopied={setWalletCopied}
                  address={item.address}
                />
              ))}
          </div>
          <div className={classes.tablesWrapper}>
            <SummaryModalTable
              name={name}
              rawName={rawName}
              networks={detailedNetwork()}
              value={value}
              filters={filters}
              totalBalance={userData?.detailed?.totalBalanceUSD}
            />
          </div>
        </div>
      )}
    </div>
  );
}
