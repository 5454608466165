import React from 'react';
import clsx from "clsx";

import { makeStyles, Theme } from '@material-ui/core/styles';
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    progress: {
        width: 264,
        height: 9,
        marginBottom: 24,
        borderRadius: 10,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        '&:last-child': {
            marginBottom: 0,
        },
        '&>*': {
            borderRadius: 20,
            background: (props: { color: string }) => props.color,
        },
    },
}));


interface ISummaryChartProgress {
    color: string;
    value: number;
}

export default function ChartProgress( { color, value } : ISummaryChartProgress) {
    const classes = useStyles({ color });

    return (
        <LinearProgress className={clsx(classes.progress)}
                        style={{ background: '#EAE6E2' }}
                        variant="determinate"
                        value={value} />
    );
}
