import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import NotFound from "@assets/images/not-found-icon.png";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60vh',
        width: '100%',
        flexDirection: 'column',
        '& img': {
            marginBottom: 25,
        },
    },
}));

export default function CanvasNotFound({ title }) {
    const classes = useStyles();

    return <div className={classes.root}>
        <img src={NotFound} alt=""/>
        <Typography style={{ fontSize: '1.5rem' }}>{title}</Typography>
    </div>
}
