import React, { useState, useMemo, useEffect } from "react";
import { startOfMonth, addMonths, format } from 'date-fns';

import useAuthStore from "@hooks/globalStores/useAuthStore";
import useWalletStore from "@hooks/globalStores/useWalletStore";
import { useGetTransactions } from "@hooks/fetchers/Transactions/useGetTransactions";
import { xlsxTransactions2 } from '@services/excel';
import { utilsService } from "@services/utils.service";

import CanvasSkeleton from "@shared/UI/CanvasSkeleton";
import ModuleLayout from "@shared/Layouts/ModuleLayout/ModuleLayout";
import RefreshButton from "@shared/UI/RefreshButton";
import TransactionsTable from "./TransactionsTable";

import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, FormControl, InputLabel, Select, MenuItem, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  headerTitle: {
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 11,
    },
  },
  skeleton: {
    height: 250,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  formControl: {
    width: 150,
    marginLeft: theme.spacing(2),
  }
}));


const MonthSelect = ({ startDate, endDate, selectedMonth, setSelectedMonth }) => {
  const classes = useStyles();
  const months = useMemo(() => {
    const mths: string[] = [];
    const start = startOfMonth(new Date(startDate));
    const end = startOfMonth(new Date(endDate));
    for (let dt = start; dt >= end; dt = addMonths(dt, -1)) {
      mths.push(format(dt, 'MMM-yyyy'));
    }
    return mths;
  }, [startDate, endDate]);
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Month/Year</InputLabel>
      <Select
        id="month-select"
        labelId="month-select-label"
        value={selectedMonth}
        onChange={(e: any) => setSelectedMonth(e.target.value)}
      >
        <MenuItem value=''>All</MenuItem>
        <Divider />
        {months.map((month: string) => (
          <MenuItem value={month}>{month}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const NetworkSelect = ({ networks, selectedNetwork, setSelectedNetwork }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Networks</InputLabel>
      <Select
        id="network-select"
        labelId="network-select-label"
        value={selectedNetwork}
        onChange={(e: any) => setSelectedNetwork(e.target.value)}
      >
        <MenuItem value=''>All</MenuItem>
        <Divider />
        {networks.map((network: string) => (
          <MenuItem value={network}>{utilsService.getNetworkName(network)}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const ActionSelect = ({ actions, selectedAction, setSelectedAction }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Actions</InputLabel>
      <Select
        id="action-select"
        labelId="action-select-label"
        value={selectedAction}
        onChange={(e: any) => setSelectedAction(e.target.value)}
      >
        <MenuItem value=''>All</MenuItem>
        <Divider />
        {actions.map((action: string) => (
          <MenuItem value={action}>{action}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export const Transactions: React.FC = () => {
  const { parsedSelectedWallets } = useWalletStore();
  const { isAuthorized } = useAuthStore();
  const [msg, setMsg] = useState('');
  const [timestamp, setTimestamp] = useState(Date.now()); // update this to trigger a reload
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [selectedAction, setSelectedAction] = useState('');

  const query = useMemo(() => new URLSearchParams(window.location.search), []);
  const walletId = useMemo(() => query.get("walletId") || "", [query]);

  const { userData, message, isLoading } = useGetTransactions(
    isAuthorized,
    walletId,
    parsedSelectedWallets,
    timestamp
  );

  const [startDate, endDate] = useMemo(() => {
    if (userData?.transactions?.length > 0) {
      const startDate = userData.transactions[0].txnDate;
      const endDate = userData.transactions[userData.transactions.length - 1].txnDate;
      return [startDate, endDate];
    }
    return ['', ''];
  }, [userData]);

  const networks = useMemo(() => {
    if (userData?.transactions?.length > 0) {
      return userData.transactions.map((x: any) => x.network).filter((x: string, i: number, a: string) => a.indexOf(x) === i);
    }
    return [];
  }, [userData]);

  const actions = useMemo(() => {
    if (userData?.transactions?.length > 0) {
      return userData.transactions.map((x: any) => x.action).filter((x: string, i: number, a: string) => a.indexOf(x) === i);
    }
    return [];
  }, [userData]);

  const transactions = useMemo(() => {
    let txns = userData?.transactions || [];
    if (selectedMonth) {
      const dt = new Date(selectedMonth);
      const dtStart = dt.toISOString();
      const dtEnd = addMonths(dt, 1).toISOString();
      txns = txns.filter((x: any) => x.txnDate >= dtStart && x.txnDate < dtEnd);
    }
    if (selectedNetwork) {
      txns = txns.filter((x: any) => x.network === selectedNetwork);
    }
    if (selectedAction) {
      txns = txns.filter((x: any) => x.action === selectedAction);
    }
    return txns;
  }, [userData, selectedMonth, selectedNetwork, selectedAction]);

  useEffect(() => {
    setMsg(message);
  }, [message]);

  const doRefresh = () => {
    setTimestamp(new Date().getTime());
  };

  const doDownload = () => {
    setIsDownloading(true);
    setMsg(`Creating excel spreadsheet ...`);

    let prefix = '';
    if (selectedMonth) {
      const dt = new Date(selectedMonth);
      prefix += '.' + format(dt, 'MMM-yyyy');
    }
    if (selectedNetwork) {
      prefix += '.' + selectedNetwork;
    }
    if (selectedAction) {
      prefix += '.' + selectedAction;
    }
    setTimeout(() =>
      xlsxTransactions2(transactions, prefix).then((filename: string) => {
        setMsg(`Saving excel spreadsheet ...`);
        setIsDownloading(false);
        setMsg(`Download file created: <span>${filename}</span>`);
      }
    ), 500);
  };

  const classes = useStyles();
  return (
    <ModuleLayout options={{ wallets: true }}>
      <RefreshButton message={msg} isRefreshing={isLoading} onRefresh={doRefresh} isDownloading={isDownloading} onDownload={() => doDownload()} />
      {isLoading && <CanvasSkeleton className={classes.skeleton} />}
      {!isLoading && userData && userData.transactions && (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <div className={classes.header}>
              <Typography variant="h2" className={classes.headerTitle}>Transactions History</Typography>
              <div>
                <MonthSelect startDate={startDate} endDate={endDate} selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} />
                <NetworkSelect networks={networks} selectedNetwork={selectedNetwork} setSelectedNetwork={setSelectedNetwork} />
                <ActionSelect actions={actions} selectedAction={selectedAction} setSelectedAction={setSelectedAction} />
              </div>
            </div>
            <TransactionsTable data={transactions} />
          </Paper>
        </div>
      )}
    </ModuleLayout>
  );
};
