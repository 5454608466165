import React, { createContext, useEffect, useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";

import useGetCurrencyList from "@hooks/fetchers/useGetCurrencyList";

// FYI: https://idlechampions.fandom.com/wiki/Large_number_abbreviations
const abbreviateNumber = (value) => {
  let newValue = value;
  const suffixes = [
    "",
    "K",
    "M",
    "B",
    "t",
    "q",
    "Q",
    "s",
    "S",
    "o",
    "n",
    "d",
    "U",
    "D",
    "T",
    "Qt",
    "Qd",
    "Sd",
    "St",
    "O",
    "N",
    "v",
    "c",
  ];
  let suffixNum = 0;
  if (newValue >= 1000) {
    while (newValue >= 1000) {
      newValue /= 1000;
      suffixNum++;
      if (suffixNum >= suffixes.length) {
        break;
      }
    }
    return newValue.toPrecision(3) + suffixes[suffixNum];
  }
  return newValue.toFixed(2);
};

interface IFormatNumberOptions {
  toFixed?: number;
  isAbbreviateNumber?: number;
}

export const CurrencyContext = createContext<any>(null);

export const CurrencyProvider = ({ children }: { children: React.ReactElement }) => {
  const [selectedCurrency, _setSelectedCurrency] = useState(localStorage.getItem("currency") || '');
  const { rates, loading } = useGetCurrencyList();

  const setSelectedCurrency = (value: string) => {
    localStorage.setItem("currency", value);
    _setSelectedCurrency(value);
  };

  const formatNumberByCurrency = (val: number | string, options: IFormatNumberOptions) => {
    let rawValue = 0;
    try {
      rawValue = Number(val || 0);
    } catch {}

    if (selectedCurrency && rates) rawValue = rawValue * rates[selectedCurrency];

    const toFixed = !isNaN(Number(options?.toFixed))  ? Number(options.toFixed) : 2;

    if (typeof rawValue === "number" && rawValue !== 0) {
      if (rawValue > 1e9 || options?.isAbbreviateNumber) {
        return `${getSymbolFromCurrency(selectedCurrency as string)}${abbreviateNumber(rawValue)}`;
      } else if (toFixed > 2) {
        return rawValue.toFixed(toFixed);
      } else {
        const nf = Intl.NumberFormat("en", { style: "currency", currency: selectedCurrency, maximumFractionDigits: toFixed });
        return nf.format(rawValue);
      }
    }

    return rawValue.toString();
  };

  useEffect(() => {
    if (!selectedCurrency) setSelectedCurrency("USD");
  }, [selectedCurrency]);

  return (
    <CurrencyContext.Provider
      value={{
        selectedCurrency,
        setSelectedCurrency,
        rates,
        loading,
        formatNumberByCurrency,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
