import { useMemo, useState } from "react";
import clsx from "clsx";

import AllAssetsIcon from "@assets/images/all-assets-icon.svg";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { DashboardTable } from "@pages/Dashboard/components/Tables/DashboardTable";
// import { EmptyDashboard } from "@pages/Dashboard/EmptyDashboard";
import IconsCollectionBadge from "../../../../../shared/UI/IconsCollectionBadge";
import { getSortData } from "../../../../../utils/sortingData";
import { utilsService } from "@services/utils.service";

export const ClaimableTable = ({
  sortTables,
  isPhone,
  ratioWidthTablet,
  renderHeaderTablet,
  claimable,
  filters,
  calculateSum,
}) => {
  const [tableError, setTableError] = useState(false);
  const { formatNumberByCurrency } = useCurrencyStore();

  const total: number = useMemo(
    () => (filters ? calculateSum(claimable) : claimable?.totalBalance?.toFixed(2)),
    [filters, claimable, calculateSum]
  );

  const currentColumnsClaimable = useMemo(() => {
    if (isPhone) {
      return [
        {
          label: "Assets",
          prop: "assets",
          width: 400 * ratioWidthTablet,
          fixed: "left",
          render: (value: any) => {
            return (
              <div className={clsx("icons-badge", value.isEndRow && "end-row-icons-badge")}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Value",
          prop: "value",
          width: 380 * ratioWidthTablet,
          render: (value: any) => {
            return <div className={clsx({ resultSum: value.isEndRow })}>{formatNumberByCurrency(value.value)}</div>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Balance",
          prop: "balance",
          width: 380 * ratioWidthTablet,
          render: (value: any) => {
            return <span className="table-text">{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: "Assets",
          prop: "assets",
          width: 388 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx("icons-badge", value.isEndRow && "end-row-icons-badge-desktop")}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Balance",
          prop: "balance",
          width: 500 * ratioWidthTablet,
          render: (value: any) => {
            return <span className="table-text">{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Value",
          prop: "value",
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <div className={clsx({ resultSum: value.isEndRow })}>{formatNumberByCurrency(value.value)}</div>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);

  const claimableCategoryName = useMemo(() => {
    return claimable?.networks.map((item: any) => utilsService.getNetworkName(item?.name));
  }, [claimable?.networks]);

  const sortDataClaimableArray = useMemo(() => {
    try {
      if (claimable) {
        const dataClaimableArray = claimable?.networks.map((item: any) => {
          const dataClaimable = item.data.reduce(
            (acc: any, value: any) => {
              return {
                dataArray: [
                  ...acc.dataArray,
                  {
                    label: value.label ? value.label : value?.tokens?.length ? value.tokens[0].symbol : "No name",
                    mainIcon: value.imageUrl,
                    balance: (value.balance
                      ? value.balance
                      : value?.tokens?.length
                      ? value.tokens[0].balance
                      : 0
                    ).toFixed(4),
                    value: value.balanceUSD.toFixed(2),
                  },
                ],
                sum: acc.sum + value.balanceUSD,
              };
            },
            {
              dataArray: [],
              sum: 0,
            }
          );

          const sortDataClaimable = getSortData(dataClaimable?.dataArray, sortTables?.[`yield farming${item.name}`]);

          return [
            ...sortDataClaimable,
            {
              label: `Total Assets: ${sortDataClaimable.length}`,
              mainIcon: "",
              balance: "",
              apy: "",
              value: dataClaimable.sum.toFixed(2),
              isEndRow: true,
            },
          ];
        });

        return dataClaimableArray;
      }
    } catch (e) {
      setTableError(true);
      console.log("----claimableError");
      console.log(e);
    }
  }, [claimable, sortTables]);

  if (tableError) return <></>;

  if (claimable && Number(total) !== 0) {
    return (
      <DashboardTable
        dataArray={sortDataClaimableArray}
        columns={currentColumnsClaimable}
        categoryNameArray={claimableCategoryName}
        title="Yield Farming"
        className="dashboard-table--yield"
        titleValue={total}
      />
    );
  } else {
    return <></>;
  }
};
