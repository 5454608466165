import { useMemo, useState } from "react";
import clsx from "clsx";

import AllAssetsIcon from "@assets/images/all-assets-icon.svg";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { DashboardTable } from "@pages/Dashboard/components/Tables/DashboardTable";
import IconsCollectionBadge from "@shared/UI/IconsCollectionBadge";
import { getSortData } from "@utils/sortingData";
import { utilsService } from "@services/utils.service";

export const OtherTable = ({
  sortTables,
  isPhone,
  ratioWidthTablet,
  renderHeaderTablet,
  other,
  filters,
  calculateSum,
}) => {
  const [tableError, setTableError] = useState(false);
  const { formatNumberByCurrency } = useCurrencyStore();

  const total: number = useMemo(
    () => (filters ? calculateSum(other) : other?.totalBalance?.toFixed(2)),
    [filters, other, calculateSum]
  );

  const currentColumnsOther = useMemo(() => {
    if (isPhone) {
      return [
        {
          label: "Assets",
          prop: "assets",
          width: 400 * ratioWidthTablet,
          fixed: "left",
          render: (value: any) => {
            return (
              <div className={clsx("icons-badge", value.isEndRow && "end-row-icons-badge")}>
                <IconsCollectionBadge
                  mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                  protocolIcon={value.protocolIcon}
                />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Balance",
          prop: "balance",
          width: 380 * ratioWidthTablet,
          render: (value: any) => {
            return <span className="table-text">{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Value",
          prop: "value",
          width: 380 * ratioWidthTablet,
          render: (value: any) => {
            return <div className={clsx({ resultSum: value.isEndRow })}>{formatNumberByCurrency(value.value)}</div>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: "Assets",
          prop: "assets",
          width: 400 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx("icons-badge", value.isEndRow && "end-row-icons-badge-desktop")}>
                <IconsCollectionBadge
                  mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                  protocolIcon={value.protocolIcon}
                />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Balance",
          prop: "balance",
          width: 350 * ratioWidthTablet,
          render: (value: any) => {
            return <span className="table-text">{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Price",
          prop: "price",
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return (<>{!value.isEndRow && (<span className='table-text'>{formatNumberByCurrency(value.price, { toFixed: 4 })}</span>)}</>);
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: "Value",
          prop: "value",
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <div className={clsx({ resultSum: value.isEndRow })}>{formatNumberByCurrency(value.value)}</div>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);
  const sortDataOtherArray = useMemo(() => {
    try {
      if (other) {
        const dataOtherArray = other?.networks.map((item: any) => {
          const dataOther = item.data
            .filter((x) => (x.balance ? x.balance : 0) >= 0.00005)
            .sort((a, b) => b.balanceUSD - a.balanceUSD)
            .reduce(
              (acc: any, value: any) => {
                return {
                  dataArray: [
                    ...acc.dataArray,
                    {
                      label:
                        value.label && value.label !== "label"
                          ? value.label
                          : value?.tokens?.length
                          ? value.tokens[0].symbol
                          : value.protocol && value.protocol !== "other"
                          ? value.protocol
                          : value.symbol
                          ? value.symbol
                          : "",
                      mainIcon: value.imageUrl,
                      balance: (value.balance
                        ? value.balance
                        : value?.tokens?.length
                        ? value.tokens[0].balance
                        : 0
                      ).toFixed(4),
                      price: value.price,
                      value: value.balanceUSD.toFixed(2),
                    },
                  ],
                  sum: acc.sum + value.balanceUSD,
                };
              },
              {
                dataArray: [],
                sum: 0,
              }
            );

          const sortDataOther = getSortData(dataOther?.dataArray, sortTables?.[`other${item.name}`]);

          return [
            ...sortDataOther,
            {
              label: `Total Assets: ${sortDataOther.length}`,
              mainIcon: "",
              balance: "",
              apy: "",
              value: dataOther.sum.toFixed(2),
              isEndRow: true,
            },
          ];
        });
        return dataOtherArray;
      }
    } catch (e) {
      setTableError(true);
      console.log("----otherError");
      console.log(e);
    }
  }, [other, sortTables]);
  const otherCategoryName = useMemo(() => {
    return other?.networks.map((item: any) => utilsService.getNetworkName(item?.name));
  }, [other?.networks]);

  if (tableError) return <></>;

  if (other && Number(total) !== 0) {
    return (
      <DashboardTable
        dataArray={sortDataOtherArray}
        columns={currentColumnsOther}
        categoryNameArray={otherCategoryName}
        title="Other"
        className="dashboard-table--other"
        titleValue={total}
      />
    );
  } else {
    return <></>;
  }
};
