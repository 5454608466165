import React from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    preloader: {
        display: "flex",
        width: '51%',
        position: 'absolute',
        height: 5,
        bottom: 0,
        left: 0,
        borderRadius: 20,
        background: 'linear-gradient(#F37222 0 0) right/0% 100% no-repeat #F4F2F0',
        animation: '$w1 3s infinite linear',
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    preloader1: {
        display: "flex",
        width: '50%',
        position: 'absolute',
        height: 5,
        bottom: 0,
        right: 0,
        borderRadius: 20,
        background: 'linear-gradient(#F37222 0 0) left/0% 100% no-repeat #F4F2F0',
        animation: '$w1 3s infinite linear',
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
    },
    '@keyframes w1': {
        "100%": {
            backgroundSize: '110% 100%',
        },
    },
    })
);

export default function WalletPreloader() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.preloader}/>
            <div className={classes.preloader1}/>
        </>
    );
}
