export const getSortData = (data: any, sort: any) => {
  if (!sort?.type) {
    return data;
  }

  return data.sort((a: any, b: any) => {
    const currentType = sort.type === "assets" ? "label" : sort.type;

    let ba = 0;
    let ab = 0;
    const typeOfColumn = ["balance", "balanceFirst", "price", "value", "apy"];

    if (
      typeOfColumn.includes(currentType) ||
      typeof a?.[currentType] === "number"
    ) {
      ab = +a?.[currentType];
      ba = +b?.[currentType];

      if (sort.sort === "asc") {
        return ba - ab;
      }
      return ab - ba;
    }

    if (typeof a?.[currentType] === "string") {
      const strA = a?.[currentType]?.toLowerCase();
      const strB = b?.[currentType]?.toLowerCase();

      const curStrA = sort.sort === "asc" ? strA : strB;
      const curStrB = sort.sort === "asc" ? strB : strA;

      if (curStrA > curStrB) {
        return 1;
      }
      if (curStrA === curStrB) {
        return 0;
      }
      return -1;
    }

    if (sort.sort === "asc") {
      return b?.[currentType] - a?.[currentType];
    }
    return a?.[currentType] - b?.[currentType];
  });
};
