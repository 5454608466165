import React, { useState, useMemo, useEffect } from "react";

import { getDates } from "@services/history";
import { getBalance } from "@services/balance.service";
import { getTransactions } from "@services/transaction.service";
import { xlsxBalance, xlsxTransactions2 } from "@services/excel";

import useAuthStore from "@hooks/globalStores/useAuthStore";
import useWalletStore from "@hooks/globalStores/useWalletStore";
import useGetBalancePeriod from "@hooks/fetchers/Dashboard/useGetBalancePeriod";

import CanvasSkeleton from "@shared/UI/CanvasSkeleton";
import ModuleLayout from "@shared/Layouts/ModuleLayout/ModuleLayout";
import PaperLayout from "@shared/Layouts/PaperLayout/PaperLayout";
import RefreshButton from "@shared/UI/RefreshButton";

import { makeStyles } from "@material-ui/core/styles";
import { ReportsTable } from "./ReportsTable";

const parseLiquidWallets = (wallets: any) => {
  const w = wallets.filter((x: any) => x.address.indexOf('offchain:') === -1);
  console.log('parseLiquidWallets', w);
  const addresses = w.map((x: any) => `addresses=${x.address}`);
  return "&" + addresses.join("&");
}

const parseIlliquidWallets = (wallets: any) => {
  const w = wallets.filter((x: any) => x.address.indexOf('offchain:') === 0);
  console.log('parseIlliquidWallets', w);
  const addresses = w.map((x: any) => `addresses=${x.address}`);
  return "&" + addresses.join("&");
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  titleWrapper: {
    display: "flex",
    marginBottom: 37,
  },
  paper: {
    width: 381,
    height: 381,
  },
  skeleton: {
    height: 300,
  },
  formPaper: {
    padding: "0 22px",
    background: '#fff',
    height: '90vh',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
  },
})
);

export const Reports = React.memo(() => {
  const { isAuthorized } = useAuthStore();
  const { wallets, parsedSelectedWallets } = useWalletStore();
  const [msg, setMsg] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now()); // update this to trigger a reload

  const query = useMemo(() => new URLSearchParams(window.location.search), []);
  const walletId = useMemo(() => query.get("walletId") || "", [query]);

  const { data, isLoading, message } = useGetBalancePeriod(
    isAuthorized,
    walletId,
    parsedSelectedWallets,
    timestamp
  );
  const months = useMemo(() => data ? getDates(data.dateFrom, data.dateTo) : [], [data]);

  useEffect(() => {
    setMsg(message);
  }, [message]);

  const doRefresh = () => {
    setTimestamp(Date.now()); // update timestamp when refresh button is pressed
  };

  const doReport = (type: string, label: string, dateFrom: string) => {
    switch (type) {
      case 'liquid': doLiquidAssetsReport(type, label, dateFrom); break;
      case 'illiquid': doIlliquidAssetsReport(type, label, dateFrom); break;
      case 'transactions': doTransactionsReport(type, label, dateFrom); break;
      default:
        setMsg(`Unknown Report <span>${type}</span>.`);
        break;
    }
  }

  const doLiquidAssetsReport = (type: string, label: string, dateFrom: string) => {
    setIsDownloading(true);
    setMsg(`Downloading <span>${type}</span> report data for <span>${label}</span> ...`);
    setTimeout(() => { // UI responsiveness
      const parsedLiquidWallets = parseLiquidWallets(wallets);
      getBalance(dateFrom, isAuthorized, walletId, parsedLiquidWallets).then((newData: any) => {
        console.log('balance', newData);
        if (newData?.status === 'complete') {
          setMsg(`Creating the <span>${type}</span> report for <span>${label}</span> ...`);
          setTimeout(() => {
            xlsxBalance(newData).then((filename: string) => {
              setMsg(`Report created successfully. Check your downloads folder for the file: <span>${filename}</span>.`);
            }).catch((error: any) => {
              console.log(error);
            });
          }, 200);
        } else if (newData?.status === 'not-found') {
          setMsg(`${type} Report <span>failed</span>. ${label} data not found.`);
        }
      }).catch((error: any) => {
        console.log(error);
      }).finally(() => {
        setIsDownloading(false);
      });
    }, 200);
  }

  const doIlliquidAssetsReport = (type: string, label: string, dateFrom: string) => {
    setIsDownloading(true);
    setMsg(`Downloading <span>${type}</span> report data for <span>${label}</span> ...`);
    setTimeout(() => { // UI responsiveness
      const parsedIlliquidWallets = parseIlliquidWallets(wallets);
      getBalance(dateFrom, isAuthorized, walletId, parsedIlliquidWallets).then((newData) => {
        console.log('balance', newData);
        if (newData?.status === 'complete') {
          setMsg(`Creating the <span>${type}</span> report for <span>${label}</span> ...`);
          setTimeout(() => {
            xlsxBalance(newData).then((filename: string) => {
              setMsg(`Report created successfully. Check your downloads folder for the file: <span>${filename}</span>.`);
            }).catch((error: any) => {
              console.log(error);
            });
          }, 200);
        } else if (newData?.status === 'not-found') {
          setMsg(`${type} Report <span>failed</span>. ${label} data not found.`);
        }
      }).catch((error: any) => {
        console.log(error);
      }).finally(() => {
        setIsDownloading(false);
      });
    }, 200);
  }

  const doTransactionsReport = (type: string, label: string, dateFrom: string) => {
    setIsDownloading(true);
    setMsg(`Downloading <span>${type}</span> report data for <span>${label}</span> ...`);
    setTimeout(() => { // UI responsiveness
      getTransactions(dateFrom, isAuthorized, walletId, parsedSelectedWallets).then((newData) => {
        console.log('transactions', newData?.items?.length);
        if (newData?.items?.length > 0) {
          setMsg(`Creating the <span>${type}</span> report for <span>${label}</span> ...`);
          setTimeout(() => {
            xlsxTransactions2(newData.items, '').then((filename: string) => {
              setMsg(`Report created successfully. Check your downloads folder for the file: <span>${filename}</span>.`);
            }).catch((error: any) => {
              console.log(error);
            });
          }, 200);
        } else if (newData?.status === 'not-found') {
          setMsg(`${type} Report <span>failed</span>. ${label} data not found.`);
        }
      }).catch((error: any) => {
        console.log(error);
      }).finally(() => {
        setIsDownloading(false);
      });
    }, 200);
  }

  const classes = useStyles();
  return (
    <ModuleLayout options={{ wallets: true }}>
      <RefreshButton message={msg} isRefreshing={isLoading || isDownloading} onRefresh={doRefresh} />
      {(isLoading || !months) && (
        <CanvasSkeleton className={classes.skeleton} />
      )}
      {!isLoading && months && (
        <PaperLayout title='Custom Reports'>
          <ReportsTable data={months} doReport={doReport} />
        </PaperLayout>
      )}
    </ModuleLayout>
  );
});

