import { makeStyles } from "@material-ui/core";

interface WelcomeMessageProps {
  username: string;
}

const useStyles = makeStyles((theme) => ({
  message: {
    fontSize: "1.5rem",
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10px',
      fontSize: '1rem'
    },
    paddingTop: '3px',
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    fontWeight: 300,
  },
  name: {
    fontWeight: 500,
  }
}));

export function WelcomeMessage(props: WelcomeMessageProps) {
  const classes = useStyles();

  return (
    <>
      {props.username && (
        <div className={classes.message}>Hi <span className={classes.name}>{props.username}</span>, welcome back!</div>
      )}
    </>
  );
}
