import { useMemo, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { DashboardPieChart } from "@pages/Dashboard/components/DashboardDiagram/DashboardPieChart/DashboardPieChart";
import { utilsService } from "@services/utils.service";

const assetAllocationChartColors = [
  "#012a4aff",
  "#013a63ff",
  "#01497cff",
  "#014f86ff",
  "#2a6f97ff",
  "#2c7da0ff",
  "#468fafff",
  "#61a5c2ff",
  "#89c2d9ff",
  "#a9d6e5ff",
];
const networkAllocationChartColors = [
  "#590d22ff",
  "#800f2fff",
  "#a4133cff",
  "#c9184aff",
  "#ff4d6dff",
  "#ff758fff",
  "#ff8fa3ff",
  "#ffb3c1ff",
  "#ffccd5ff",
  "#fff0f3ff",
];
const platformAllocationChartColors = [
  "#590d22ff",
  "#800f2fff",
  "#a4133cff",
  "#c9184aff",
  "#ff4d6dff",
  "#ff758fff",
  "#ff8fa3ff",
  "#ffb3c1ff",
  "#ffccd5ff",
  "#fff0f3ff",
];

const calculateTotalSum = (data: any) => {
  return (
    data?.reduce((sum: number, item: any) => {
      sum += Math.abs(item.value);
      return sum;
    }, 0) || 0
  );
};

const calculatePercentData = (data: any) => {
  const totalSum = calculateTotalSum(data);

  return data.map((item: any) => {
    return {
      percentage: Math.abs((item.value / totalSum) * 100),
      name: item.name,
      value: item.value,
    };
  });
};

const useStyles = makeStyles((theme) => ({
  root: {},
  chartItem: {
    background: "#fff",
    height: "100%",
    padding: "25px 22px 25px",
    borderRadius: 20,
    width: "100%",
    boxShadow: "0 4px 2px -3px #b3b1ad;",
  },
  chartHeader: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #EAE6E2",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
    paddingBottom: 12,
  },
  chartContent: {},
  chartTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.1rem",
    },
  },
}));

const DashboardDiagrams = (props) => {
  const [percentMode, setPercentMode] = useState({
    platform: true,
    assets: true,
    networks: true,
  });
  const classes = useStyles();

  const { formatNumberByCurrency } = useCurrencyStore();

  const chartData = useMemo(() => {
    const isSummary = props.isSummary;
    const platformAllocationData = props.platformAllocationData
      ?.filter((item) => item.name)
      .map(({ name, balanceUSD }) => ({
        name,
        value: balanceUSD,
      }));

    const networkAllocationData = props.networkAllocationData?.filter((item) => item.name).map(
      ({ name, balance: value }: { name: string; balance: number }) => ({ name: utilsService.getNetworkName(name), value })
    );

    const assetAllocationData = [
      {
        name: "Deposits",
        value: +props.assetAllocationData["depositTotal"],
      },
      {
        name: "Yield Farming",
        value: +props.assetAllocationData["yieldFarmingTotal"],
      },
      {
        name: "Liquidity Pools",
        value: +props.assetAllocationData["poolsTotal"],
      },
      {
        name: "Fiat",
        value: +props.assetAllocationData["fiatTotal"],
      },
      {
        name: "Offchain",
        value: +props.assetAllocationData["offchainTotal"],
      },      
      {
        name: "Exchange",
        value: +props.assetAllocationData["exchangeTotal"],
      },
      {
        name: "Wallet",
        value: +props.assetAllocationData["walletTotal"],
      },
      {
        name: "Staked",
        value: +props.assetAllocationData["stakedTotal"],
      },
      {
        name: "Debt",
        value: +props.assetAllocationData["debtTotal"],
      },
      {
        name: "Other",
        value: +props.assetAllocationData["otherTotal"],
      },
    ];

    const platform = percentMode.platform ? calculatePercentData(platformAllocationData) : platformAllocationData;
    const assets = percentMode.assets ? calculatePercentData(assetAllocationData) : assetAllocationData;
    const networks = percentMode.networks ? calculatePercentData(networkAllocationData) : networkAllocationData;

    const charts = [
      {
        title: "Asset Allocation",
        name: "assets",
        data: assets?.map((item, index) => ({
          ...item,
          color: assetAllocationChartColors[index % assetAllocationChartColors.length],
        })),
      },
      {
        title: "Network Allocation",
        name: "networks",
        data: networks?.map((item, index) => ({
          ...item,
          color: networkAllocationChartColors[index % networkAllocationChartColors.length],
        })),
      },
      {
        title: "Platform Allocation",
        name: "platform",
        data: platform?.map((item, index) => ({
          ...item,
          color: platformAllocationChartColors[index % platformAllocationChartColors.length],
        })),
      },
    ];
    if (isSummary) return [charts[0], charts[1]];
    return [charts[0], charts[2]];
  }, [props, percentMode]);

  const handleChangeViewMode = (event: any, namePie: string) => {
    const value = event.target.checked;
    setPercentMode((prev) => ({ ...prev, [namePie]: value }));
  };

  // select items with percentage greater than capValue(%)
  const capValue = 5;
  const sortData = (data) => {
    const items: any = { results: [{ name: "other", value: 0, percentage: 0, list: [{}], color: "black" }] };

    for (let item of data) {
      const i = Object.assign({}, item, { value: Math.abs(item.value)});
      if (i.name !== "other" && i.percentage > capValue) {
        items.results.push(i);
      } else {
        items.results[0].list.push(i);
        items.results[0].value += i.value;
        items.results[0].percentage += i.percentage;
      }
    }
    if (items.results[0].percentage < capValue) {
      // delete others if total balance still less than capValue
      delete items.results[0];
    }
    return items;
  };

  return (
    <Grid container className={classes.root} spacing={3}>
      {chartData.map((item, index) => (
        <Grid item sm={12} md={6} key={index} xs={12}>
          <div className={classes.chartItem}>
            <div className={classes.chartHeader}>
              <Typography className={classes.chartTitle} variant='h3'>
                {item.title}
              </Typography>
              <Typography className={classes.chartTitle} variant='h5'>
                Total: {formatNumberByCurrency(props.totalBalance, { isAbbreviateNumber: true })}
              </Typography>
              {/* {mobileResolution && <ChartSwitchWithLabel onChange={(e: any) => handleChangeViewMode(e, item.name)} />} */}
            </div>

            <div className={classes.chartContent}>
              <DashboardPieChart
                name={item.name}
                percentMode={percentMode[item.name]}
                data={sortData(item.data)}
                changeVievMode={handleChangeViewMode}
                totalAssetBalance={props.totalBalance}
              />
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default DashboardDiagrams;
