import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from "recharts";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Paper, Box, Typography } from "@material-ui/core";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { utilsService } from "@services/utils.service";
import { chartColors } from "@utils/colors";

const useStyles = makeStyles((theme: Theme) => ({
  chartContainer: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
  },
  chartTitle: {
    marginBottom: theme.spacing(5),
  },
  title: {
    fontFamily: 'Cera Pro'
  },
  toolTipValues: {
    fontWeight: 'bold',
  },

  toolTipContainer: {
    padding: theme.spacing(1),
    opacity: 0.9,
  },
  dateContainer: {
    paddingBottom: theme.spacing(0),
  },

  toolTipBadge: {
    color: "#000",
  },
}));

export default function NetworkBarChart({ networks }) {
  const { formatNumberByCurrency } = useCurrencyStore();
  const classes = useStyles();

  const chartData: any[] = [];

  if (networks) {
    networks.forEach((network) => {
      if (network.balanceUSD !== 0) {
        let data: any = {};
        data["network"] = utilsService.getNetworkName(network.name);
        data["total"] = network.balanceUSD;
        chartData.push(data);
      }
    });
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Paper className={classes.toolTipContainer}>
          <span className={classes.toolTipBadge} style={{ color: payload[0].fill }}>{`${label} : ${formatNumberByCurrency(payload[0].value)}`}</span>
        </Paper>
      );
    }
    return <></>;
  };

  return (
    <Paper className={classes.chartContainer} style={{ borderRadius: 5 }}>
      <Box className={classes.chartTitle}>
        <Typography variant={"h3"}>Networks</Typography>
      </Box>
      <ResponsiveContainer width={"100%"} height={400}>
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 10,
            bottom: 5,
          }}
          barSize={50}
          stackOffset='sign'
        >
          <XAxis dataKey='network' scale='point' padding={{ left: 30, right: 10 }} dy={5} />
          <YAxis
            tickFormatter={(balance) => {
              if (balance >= 0) {
                return formatNumberByCurrency(Math.abs(balance), {
                  isAbbreviateNumber: true,
                });
              } else {
                let formattedBalance = formatNumberByCurrency(Math.abs(balance), {
                  isAbbreviateNumber: true,
                });
                return `-${formattedBalance}`;
              }
            }}
            dx={-15}
          />
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid stroke='#f5f5f5' />
          <Bar dataKey='total' fill='#781D42' background={{ fill: "#fcfcfa" }}>
            {chartData.map((chart, index) => (
              <Cell key={index} fill={chartColors[index % chartColors.length]}>{chart}</Cell>
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
}
