import React from "react";



import ModuleLayout from "../../shared/Layouts/ModuleLayout/ModuleLayout";
import { makeStyles } from "@material-ui/core";
import PaperLayout from "../../shared/Layouts/PaperLayout/PaperLayout";
import ProfileIcon from '@assets/images/profile-icon.svg';
import { AccountSettings } from "@pages/Settings/AccountSettings/AccountSettings";
import TabLayout from "../../shared/Layouts/TabLayout/TabLayout";
import { ComingSoonPage } from "../../shared/ComingSoonPage/ComingSoonPage";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
}));

const SETTINGS = [
    {
        name: 'Account',
        icon: ProfileIcon,
        value: 'account',
        content: <AccountSettings />,
    },
    // {
    //     name: 'Security',
    //     icon: ProfileIcon,
    //     value: 'security',
    //     content: <div>security</div>,
    // }
];

export const Settings = () => {
    const classes = useStyles();

    return (
        <ModuleLayout>
            <PaperLayout className={classes.root}>
                <ComingSoonPage isBlur={true}/>
                <TabLayout title={"Settings"} data={SETTINGS} initialSelectedTab={"account"} />
            </PaperLayout>
        </ModuleLayout>
    );
};
