
import { useMemo, useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import IconsCollectionBadge from "@shared/UI/IconsCollectionBadge";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
  container: {
  },
  option: {},
  textFieldWrapper: {
    marginTop: 3,
    height: 62,
    background: '#F4F2F0',
    borderRadius: 8,
    position: 'relative',
  },
  titles: {
    marginRight: 8,
  },
  textField: {
    '& input': {
      marginTop: 3,
      fontSize: '1.125rem',
      '&::placeholder': {
        color: 'rgb(112, 119, 127)',
        opacity: 1,
        fontSize: '1rem',
      },
    },
  },
  extraTitle: {
    fontSize: '0.8125rem',
    color: '#808191',
  },
  textFieldExtraValue: {
    left: 20,
    top: 40,
    color: '#808191',
    position: 'absolute',
    fontSize: '0.8125rem'
  },
  disabled: {
  },
}));


interface ITradeAutoComplete {
  title: string;
  value: string;
  placeholder?: string;
  onChange: (e: any) => void;
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  data: {
    title: string;
    symbol?: string;
    extraTitle?: string;
    value: string;
    mainIcon?: string;
    protocolIcon?: string;
  }[]
}

export const TradeAutoComplete = ({ title, data, value, placeholder, onChange, disabled, loading, error }: ITradeAutoComplete) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);
  const selectedValue = useMemo(() => data.find(item => item.value === value) || { extraTitle: '', title: '', value: '' }, [data, value]);
  return (
    <div className={clsx(classes.container)}>
      <Typography style={{ fontSize: '0.8125rem', marginBottom: 4, fontWeight: 500 }}>{title}</Typography>
      <Autocomplete
        options={data}
        classes={{
          option: classes.option,
        }}
        disabled={disabled || loading}
        onChange={(e, newValue: any) => {
          if (newValue) onChange(newValue);
        }}
        value={selectedValue}
        onInputChange={(e, newInputValue) => {
          setInputValue(newInputValue)
        }}
        inputValue={inputValue}
        autoHighlight
        freeSolo
        popupIcon={""}
        getOptionLabel={(option) => option.title}
        renderOption={(option) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {option?.mainIcon && (
              <IconsCollectionBadge mainIcon={option.mainIcon} />
            )}
            <div className={classes.titles} style={{ textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: "nowrap" }}>
              {option?.symbol && (
                <Typography component="span" style={{ fontSize: '1.125rem', marginRight: '8px' }}>[{option.symbol}]</Typography>
              )}
              <Typography component="span" style={{ fontSize: '1.125rem', }}>{option?.title}</Typography>
              {option?.extraTitle && (
                <Typography className={classes.extraTitle}>{option?.extraTitle}</Typography>
              )}
            </div>
          </div>
        )}
        renderInput={(params) => (
          <div className={clsx(classes.textFieldWrapper, disabled && classes.disabled)}>
            <TextField
              {...params}
              placeholder={loading ? 'Loading...' : placeholder}
              variant="outlined"
              className={clsx(classes.textField, disabled && classes.disabled)}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
            <Typography className={classes.textFieldExtraValue}>{selectedValue?.['extraTitle']}</Typography>
          </div>
        )}
      />
    </div>
  );
};
