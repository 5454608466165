import React, { ReactElement, useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Network from "./components/NetworkInfo/Network";
import { INetwork } from "./Dashboard";

import CanvasFilterButtons from "../../shared/UI/CanvasFilterButtons";

interface Props {
  title: string;
  data: INetwork[];
  setFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: "40px 0px",
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontFamily: "Cera Pro",
    fontSize: "28px",
    fontStyle: "medium",
    marginRight: 22,
  },
  categoriesBox: {
    display: "flex",
    flexWrap: "wrap",
    width: "calc(100% + 21.43px)",
    margin: "0 0 -30px -21.43px",
    "@media (max-width: 350px)": {
      flexWrap: "nowrap",
      overflow: "auto",
    },
  },
  filterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "8px",
    position: "relative",
    zIndex: 10,
  },
  filterButton: {
    background: "#FFF",
    borderRadius: "8px",
    fontFamily: "Cera Pro",
    fontStyle: "medium",
    fontSize: "18px",
    border: "none",
    padding: "7px 16px",
    textAlign: "center",
    cursor: "pointer",
  },
  filterButtonActive: {
    background: "#F37222",
    color: "#FFF",
  },
  listContainer: {
    height: "auto",
    borderRadius: "20px",
    background: "#FFF",
    position: "absolute",
    maxWidth: "180px",
    width: "180px",
    top: "50px",
    right: 0,
    zIndex: 10,
  },
  listButton: {
    borderRadius: "20px",
    border: "none",
    background: "#FFF",
    width: "100%",
    textAlign: "left",
    padding: "20px 0 20px 21px",
    fontSize: "16px",
  },
  activeListButton: {
    background: "#F37222",
    color: "#FFF",
  },
  emptyOtherContainer: {
    cursor: "not-allowed !important",
  },
  btns: {
    background: "#fff",
    color: "#333042",
    padding: "0 16px",
    height: 35,
    marginRight: 11,
    marginBottom: 11,
    "&:last-item": {
      marginRight: 0,
    },
  },
}));

export default function DashboardCategories({ title, data, setFilters }: Props): ReactElement {
  const classes = useStyles();
  const [btnData, setBtnData] = useState([{ name: "All" }, ...data]);
  const [selectedData, setSelectedData] = useState(
    localStorage.getItem("networksFilter") ? JSON.parse(localStorage.getItem("networksFilter") as string) : ["All"]
  );

  useEffect(() => {
    let finalValue = ["allNetworks"];
    if (localStorage.getItem("networksFilter")) {
      const data = JSON.parse(localStorage.getItem("networksFilter") as string);
      if (!data.includes("All")) {
        finalValue = data;
      }
    }

    setFilters(finalValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newData: any[] = [{ name: "All" }, ...data];
    setBtnData(newData);
  }, [data]);

  return (
    <div className={classes.container}>
      <div className={classes.head}>
        <span className={classes.title}>{title}</span>
        {title === "Networks" && (
          <div className={classes.filterContainer}>
            <CanvasFilterButtons
              onSelect={(value: any) => {
                setSelectedData(() => [...value]);
                localStorage.setItem("networksFilter", JSON.stringify(value));
                const allIndex = value.indexOf("All");
                value[allIndex] = "allNetworks";
                setFilters(value);
              }}
              classNames={{
                buttons: classes.btns,
              }}
              selected={selectedData}
              data={btnData?.map((item) => ({ name: item.name }))}
            />
          </div>
        )}
      </div>
      <div className={classes.categoriesBox}>
        {selectedData.length === 1 && selectedData[0] === "All" ? (
          data?.map((item: INetwork) => {
            return <Network network={item} key={item.name} />;
          })
        ) : (
          <React.Fragment>
            {data?.map((item: INetwork) => {
              return (
                <React.Fragment key={item.name}>
                  {selectedData.includes(item.name) && <Network network={item} />}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
