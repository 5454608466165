import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Menu, MenuItem, Select, useMediaQuery } from "@material-ui/core";

import ArrowDown from "@assets/images/arrowDown.svg";
import CanvasButton from "./CanvasButton";
import { utilsService } from "@services/utils.service";

const useStyles = makeStyles((theme) => ({
  root: {},
  btnItem: {
    marginRight: 6,
    height: 30,
    padding: "0 13px",
    background: "#fff",
    color: "#333042",
    minWidth: 43,
    "&:hover": {
      color: "#fff",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  active: {
    background: "#F37222 !important",
    color: "#fff !important",
  },
}));

interface ICanvasFilterBtns {
  data: { name: string }[];
  selected: string | string[];
  onSelect: (value: { name: string; from?: string; to?: string }) => void;
  disabled?: boolean;
  classNames?: {
    wrapper?: string;
    buttons?: string;
  };
}

export default function CanvasFilterButtons({ data, selected, onSelect, disabled, classNames }: ICanvasFilterBtns) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileResolution = useMediaQuery(theme.breakpoints.down("xs"));
  const isMDResolution = useMediaQuery(theme.breakpoints.down("md"));
  const [btns, setBtns] = useState(data);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMultiple = Array.isArray(selected);
  const isSmMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setBtns(data);
  }, [data]);

  useEffect(() => {
    if (!btns.some((item) => item.name === "other")) setBtns((prev) => [...prev, { name: "other" }]);
  }, [btns]);

  const filterInMobileCase = (item, index) => {
    if (isMobileResolution) {
      if (isMultiple) {
        return selected.includes(item.name) && item.name !== "other";
      } else {
        return item.name === selected || item.name === "other";
      }
    }

    if (isMDResolution) {
      if (isMultiple) {
        return selected.includes(item.name) || item.name === "other";
      } else {
        return item.name === selected || item.name === "other" || [0, 1, 2].includes(index);
      }
    }

    return item.name !== "other";
  };

  const onHandleChange = (item, isMenu?: boolean, event?) => {
    if (isMenu) {
      setAnchorEl(null);
    } else {
      if (item.name === "other") {
        setAnchorEl(event.currentTarget);
        return;
      }
    }

    if (isMultiple) {
      let selectedArray: any = selected;

      if (item.name === "All") {
        selectedArray = ["All"];
      } else {
        if (selectedArray.includes("All")) {
          const allIndex = selectedArray.indexOf("All");
          selectedArray.splice(allIndex, 1);
        }

        if (!selectedArray.includes(item.name)) {
          selectedArray.push(item.name);
        } else {
          const index = selectedArray.indexOf(item.name);
          selectedArray.splice(index, 1);
        }
      }

      if (!selectedArray.length) {
        selectedArray = ["All"];
      }

      onSelect(selectedArray);
    } else {
      onSelect(item);
    }
  };

  if (isSmMobile) {
    return (
      <div>
        <CanvasButton style={{ margin: 0 }} className={classNames?.buttons} onClick={() => setOpen(true)}>
          Filters <img style={{ marginLeft: 5 }} src={ArrowDown} alt="arrow" />
        </CanvasButton>
        <Select
          open={open}
          onClose={() => setOpen(false)}
          style={{ opacity: "0", position: "absolute" }}
          multiple
          value={selected}
          onChange={(event: any) => {
            let value: any = event.target.value;
            const itemName = event.nativeEvent.target.dataset.value;
            if (itemName === "All") {
              value = ["All"];
            } else {
              if (value.includes("All")) {
                const allIndex = value.indexOf("All");
                value.splice(allIndex, 1);
              }
            }

            if (!value.length) {
              value = ["All"];
            }

            onSelect(value);
          }}
        >
          {btns
            .filter((item) => item.name !== "other")
            .map((item, index) => (
              <MenuItem key={index} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </div>
    );
  }

  return (
    <div className={classNames?.wrapper}>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {data &&
          btns
            .filter((item) => item.name !== "other" && !selected.includes(item.name))
            .map((item, index) => (
              <MenuItem
                key={item.name + index}
                onClick={() => onHandleChange(item, true)}
              >
                {utilsService.getNetworkName(item.name)}
              </MenuItem>
            ))}
      </Menu>
      {data &&
        btns.filter(filterInMobileCase).map((item, index) => (
          <CanvasButton
            disabled={disabled}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => onHandleChange(item, false, event)}
            className={clsx(classes.btnItem, selected.includes(item.name) && classes.active, classNames?.buttons)}
            key={index + item.name}
          >
            <span>{utilsService.getNetworkName(item.name)}</span>
            {item.name === "other" && <img style={{ marginLeft: 5 }} src={ArrowDown} alt="arrow" />}
          </CanvasButton>
        ))}
    </div>
  );
}
