
import { useState } from "react";

import { TradeQuote } from "./TradeQuote";
import { TradeApprove } from "./TradeApprove";
import { TradeExecute } from "./TradeExecute";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 525,
      width: 525,
    },
    width: '100%',
  },
}));

export const TradeModal = ({ closeModal }) => {
  const classes = useStyles();
  const [mode, setMode] = useState('quote');
  const [quote, setQuote] = useState();

  return (
    <div className={classes.container}>
      {(mode === 'quote') && (
        <TradeQuote setMode={setMode} closeModal={closeModal} quote={quote} setQuote={setQuote} />
      )}
      {(mode === 'approve') && (
        <TradeApprove setMode={setMode} quote={quote} setQuote={setQuote} />
      )}
      {(mode === 'execute') && (
        <TradeExecute setMode={setMode} closeModal={closeModal} quote={quote}  setQuote={setQuote} />
      )}
    </div>
  );
};
