import ModuleLayout from "@shared/Layouts/ModuleLayout/ModuleLayout";
import ResearchImage from './images/research.3.jpg';
import InsightsImage from './images/overview.jpg';
import DaiDepegImage from './images/dai-depeg.png';
import CrossChainImage from './images/cross-chain-bridges.png';
import AaveImage from './images/aave.png';
import stETHImage from './images/lido.png';

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';



const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: theme.spacing(3),
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  subtitle: {
    marginTop: theme.spacing(2),
    fontWeight: 700
  },
  source: {
    marginTop: theme.spacing(2),
    textTransform: 'uppercase',
    fontSize: '0.9em',
    fontWeight: 500
  },
  date: {
    fontSize: '0.9em',
    fontWeight: 400,
    color: 'rgba(128, 129, 145, 1)',
    marginBottom: theme.spacing(2),
  },
  image: {
    width: '100%',
    height: 'auto'
  },
  banner: {
    width: '100%',
    paddingBottom: '50%',
    backgroundColor: '#f8f9fa',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    border: 'solid 1px #666',
  },
  btn: {
    color: '#fff',
  },
  button: {
    '& :hover': {
      color: '#fff'
    }
  }

}));
export function Research() {
  const classes = useStyles();
  return (
    <ModuleLayout>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid xs={12} sm={12} item md={4}>
            <div className={classes.banner} style={{ backgroundImage: `url(${ResearchImage})` }} />
          </Grid>
          <Grid item xs={12} sm={12} md={8} style={{ maxWidth: '600px' }}>
            <Typography variant="h2" className={classes.title}>Market Research</Typography>
            <p>Decentralized finance (DeFi) is an emerging financial technology based on secure distributed ledgers similar to those used by cryptocurrencies. The system removes the control banks and institutions have on money, financial products, and financial services.</p>
            <p>DeFi is a new, fast-growing market. While it still show the classic symptoms of an emerging market, with high volatility and irregular trade volume, success can be found for any investor willing to keep up to date with the latest market trends and movements.</p>
            <p>Download the latest market research below, from our team of expert advisors.</p>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={4}>
          <Paper className={classes.paper}>
            <div className={classes.banner} style={{ backgroundImage: `url(${stETHImage})` }} />
            <h4 className={classes.subtitle}>stETH: The Road to Redemption</h4>
            <h6 className={classes.source}>Nethermind Crypto Market Research Insights</h6>
            <h6 className={classes.date}>October 2022</h6>
            <p>Recently, Lido’s stETH has been an extremely contentious token and has gained popularity on the back of the Merge. This research paper explains what stETH is as well as the concept of liquid staking, and why it has been considered risky.</p>
            <div className={classes.btn}><Button variant='contained' color='primary' disableElevation startIcon={<CloudDownloadIcon />} href='https://assets.prod.cloud.canvas.co/research/nethermind/nethermind_crypto_market_research_steth_oct2022.pdf' target='_blank' download='nethermind_crypto_market_research_steth_oct2022.pdf' className={classes.button}>Download</Button></div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Paper className={classes.paper}>
            <div className={classes.banner} style={{ backgroundImage: `url(${AaveImage})` }} />
            <h4 className={classes.subtitle}>Aave's GHO: A deep take on a potential new Stablecoin King</h4>
            <h6 className={classes.source}>Nethermind Crypto Market Research Insights</h6>
            <h6 className={classes.date}>July 2022</h6>
            <p>Our DeFi team has been closely following the development of the stablecoin industry and is eager to share our in-depth analysis of one of the most recent entrants, GHO. Overall, Aave's initiative is an innovative and well-thought-out concept; however, it raises many interesting questions regarding how to set interest rates, incentivize AAVE stakers, and integrate external protocols.</p>
            <div className={classes.btn}><Button variant='contained' color='primary' disableElevation startIcon={<CloudDownloadIcon />} href='https://assets.prod.cloud.canvas.co/research/nethermind/nethermind_crypto_market_research_crosschainbridge_Jul2022.pdf' target='_blank' download='nethermind_crypto_market_research_dai_depeg_jul2022.pdf' className={classes.button}>Download</Button></div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Paper className={classes.paper}>
            <div className={classes.banner} style={{ backgroundImage: `url(${CrossChainImage})` }} />
            <h4 className={classes.subtitle}>Cross Chain Bridges – Security &amp; Comparison</h4>
            <h6 className={classes.source}>Nethermind Crypto Market Research Insights</h6>
            <h6 className={classes.date}>July 2022</h6>
            <p>Currently, more than $22B is locked in bridges. This market is rapidly growing and is also frequently being hacked. In 2021, there were over $1B lost in bridge hacks, with this trend likely to continue. Understanding the security mechanisms at play is an important step to securing user capital, and managing risk exposure in the cross-chain ecosystem.</p>
            <div className={classes.btn}><Button variant='contained' color='primary' disableElevation startIcon={<CloudDownloadIcon />} href='https://assets.prod.cloud.canvas.co/research/nethermind/nethermind_crypto_market_research_crosschainbridge_Jul2022.pdf' target='_blank' download='nethermind_crypto_market_research_dai_depeg_jul2022.pdf' className={classes.button}>Download</Button></div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Paper className={classes.paper}>
            <div className={classes.banner} style={{ backgroundImage: `url(${DaiDepegImage})` }} />
            <h4 className={classes.subtitle}>Can DAI Depeg?</h4>
            <h6 className={classes.source}>Nethermind Crypto Market Research Insights</h6>
            <h6 className={classes.date}>July 2022</h6>
            <p>The recent events surrounding the implosion of Terra and its stablecoin UST, which at its peak had a market capitalization of $18B, have reignited the discussion about stablecoins and their essential property - their ability to maintain their peg against a fiat currency.</p>
            <div className={classes.btn}><Button variant='contained' color='primary' disableElevation startIcon={<CloudDownloadIcon />} href='https://assets.prod.cloud.canvas.co/research/nethermind/nethermind_crypto_market_research_dai_depeg_jul2022.pdf' target='_blank' download='nethermind_crypto_market_research_dai_depeg_jul2022.pdf' className={classes.button}>Download</Button></div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Paper className={classes.paper}>
            <div className={classes.banner} style={{ backgroundImage: `url(${InsightsImage})` }} />
            <h4 className={classes.subtitle}>Market Overview</h4>
            <h6 className={classes.source}>Nethermind Crypto Market Research Insights</h6>
            <h6 className={classes.date}>July 2022</h6>
            <p>In general, July has seen the crypto market stabilize compared to June. Ether has been a favorable asset gaining ca. 20% in value last four weeks following Ethereum’s Sepolia Testnet Merge success.</p><p>The trading volumes have been going up in July. However, uncertainty and circumspection are still vastly observed.</p>
            <div className={classes.btn}><Button variant='contained' color='primary' disableElevation startIcon={<CloudDownloadIcon />} href='https://assets.prod.cloud.canvas.co/research/nethermind/nethermind_crypto_market_research_insight_jul2022.pdf' target='_blank' download='nethermind_crypto_market_research_insight_jul2022.pdf' className={classes.button}>Download</Button></div>
          </Paper>
        </Grid>
      </Grid>
    </ModuleLayout>
  );
}
