import CanvasModal from "@shared/UI/CanvasModal";
import BalanceSummary from "./BalanceSummary";

import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  dialogContainer: {
    justifyContent: "flex-end",
  },
  dialogPaper: {
    height: "100%",
    maxHeight: "100%",
    backgroundColor: '#F4F2F0',
    margin: 0,
  },
}));

const BalanceModal = (props: any) => {
  const classes = useStyles();
  return (
    <CanvasModal
      open={props.open}
      content={<BalanceSummary dateFrom={props.dateFrom} />}
      options={{
        title: 'Balance Summary',
        maxWidth: "lg",
        classes: {
          container: classes.dialogContainer,
          paper: classes.dialogPaper,
        },
      }}
      setOpen={props.setOpen}
    />
  )
};
export default BalanceModal;