import { useEffect, useState, useCallback, useMemo } from "react";
import DashboardDiagrams from "@pages/Dashboard/components/DashboardDiagram/DashboardDiagrams";

import "@pages/Dashboard/Dashboard.scss";
import "@pages/Dashboard/components/Tables/CategoryTable.scss";
import { DashboardWithTables } from "@pages/Dashboard/DashboardWithTables";
import { GrandTotalBalance } from "@pages/Dashboard/components/GrandTotalBalance/GrandTotalBalance";
import DashboardCategories from "@pages/Dashboard/DashboardCategories";
import { TotalBalance } from "@shared/Layouts/ModuleLayout/TotalBalance/TotalBalance";

import RefreshButton from "@shared/UI/RefreshButton";
import useAuthStore from "@hooks/globalStores/useAuthStore";
import useWalletStore from "@hooks/globalStores/useWalletStore";
import DashboardSkeleton from "@pages/Dashboard/components/DashboardSkeleton/DashboardSkeleton";
import useGetBalanceSummary from "@hooks/fetchers/Dashboard/useGetBalanceSummary";
import { utilsService } from "@services/utils.service";
import { xlsxBalance } from "@services/excel";

export const findCategoryByName = (data: any, criteria: string, filters?: string[] | undefined) => {
  const network = data && data.categories && data.categories.find(({ name }: { name: string }) => name === criteria);
  return filters && !filters.includes("allNetworks") && network
    ? {
      ...network,
      networks: network.networks.filter(({ name }: any) => filters.includes(name)),
    }
    : network;
};

export const calculateBalances = (data: any) => {
  const fiat = findCategoryByName(data, "fiat");
  let fiatTotal = +(fiat ? fiat.totalBalance.toFixed(2) : 0);

  const offchain = findCategoryByName(data, "offchain");
  let offchainTotal = +(offchain ? offchain.totalBalance.toFixed(2) : 0);

  const exchange = findCategoryByName(data, "exchange");
  let exchangeTotal = +(exchange ? exchange.totalBalance.toFixed(2) : 0);

  const wallet = findCategoryByName(data, "wallet");
  let walletTotal = +(wallet ? wallet.totalBalance.toFixed(2) : 0);

  const deposit = findCategoryByName(data, "deposit");
  let depositTotal = +(deposit ? deposit.totalBalance.toFixed(2) : 0);

  const pool = findCategoryByName(data, "pool");
  let poolsTotal = +(pool ? pool.totalBalance.toFixed(2) : 0);

  const claimable = findCategoryByName(data, "claimable");
  let yieldFarmingTotal = +(claimable ? claimable.totalBalance.toFixed(2) : 0);

  const staked = findCategoryByName(data, "staked");
  let stakedTotal = +(staked ? staked.totalBalance.toFixed(2) : 0);

  const debt = findCategoryByName(data, "debt");
  let debtTotal = -(debt ? debt.totalBalance.toFixed(2) : 0);

  const other = findCategoryByName(data, "other");
  let otherTotal = +(other ? other.totalBalance.toFixed(2) : 0);

  return {
    fiatTotal,
    offchainTotal,
    exchangeTotal,
    walletTotal,
    depositTotal,
    poolsTotal,
    yieldFarmingTotal,
    debtTotal,
    stakedTotal,
    otherTotal,
  };
};

interface category {
  name: string;
  networks: INetwork[];
}

export interface INetwork {
  name: string;
  value: number;
  image?: string;
}

const BalanceSummary = (props: any) => {
  const { parsedSelectedWallets } = useWalletStore();
  const { currentUser, isAuthorized } = useAuthStore();
  const [totalBalances, setTotalBalances]: any = useState({});
  const [networks, setNetworks] = useState<INetwork[]>([]);
  const [filters, setFilters] = useState<string[]>([]); // state for filter tables in categories
  const [timestamp, setTimestamp] = useState(Date.now()); // update this to trigger a reload
  const [isDownloading, setIsDownloading] = useState(false);

  const query = useMemo(() => new URLSearchParams(window.location.search), []);
  const walletId = useMemo(() => query.get("walletId") || "", [query]);

  const { userData, message, isLoading } = useGetBalanceSummary(
    props.dateFrom,
    isAuthorized,
    walletId,
    parsedSelectedWallets,
    timestamp
  );

  const doRefresh = () => {
    setTimestamp(Date.now()); // update timestamp when refresh button is pressed
  };

  const doDownload = () => {
    setIsDownloading(true);
    setTimeout(() => {
      xlsxBalance(userData).finally(() => setIsDownloading(false));
    }, 500); // UI responsiveness
  }

  const calculateSum = useCallback(
    (data: any) => {
      if (filters[0] === "allNetworks") {
        return data?.totalBalance?.toFixed(2);
      } else {
        const sum = filters.reduce((prevItem: number, item: any) => {
          if (data?.networks.find((nw: { name: string }) => nw.name === item)) {
            const wallets = data?.networks.find((network: { name: string }) => network.name === item);
            const totalSum = wallets?.data.reduce((prevItem: number, item: { balanceUSD: number }) => {
              prevItem += item.balanceUSD;
              return prevItem;
            }, 0);
            prevItem += totalSum;
          }

          return prevItem;
        }, 0);
        return sum.toFixed(2);
      }
    },
    [filters]
  );

  const getSumBalanceUSD = (data: category[]) => {
    const sum = utilsService.getNetworkTotals();
    data?.forEach((category: any) => {
      const { networks } = category;
      networks.forEach((network: any) => {
        const { name, data } = network;
        data.forEach((token: any) => {
          //   @ts-ignore
          sum[name] += token.balanceUSD;
        });
      });
    });
    const networks: INetwork[] = Object.entries(sum)
      .map(([name, value]) => ({
        name,
        value,
        image: utilsService.getWalletImage(name),
      }))
      .filter((item) => !!item.value);
    setNetworks(networks);
  };

  useEffect(() => {
    if (userData && !isLoading) {
      setTotalBalances(calculateBalances(userData));
      getSumBalanceUSD(userData?.categories);
    }
  }, [userData, isLoading]);

  const fiat = useMemo(() => {
    return findCategoryByName(userData, "fiat", filters);
  }, [userData, filters]);
  const offchain = useMemo(() => {
    return findCategoryByName(userData, "offchain", filters);
  }, [userData, filters]);
  const exchange = useMemo(() => {
    return findCategoryByName(userData, "exchange", filters);
  }, [userData, filters]);
  const wallet = useMemo(() => {
    return findCategoryByName(userData, "wallet", filters);
  }, [userData, filters]);
  const deposit = useMemo(() => {
    return findCategoryByName(userData, "deposit", filters);
  }, [userData, filters]);
  const pool = useMemo(() => {
    return findCategoryByName(userData, "pool", filters);
  }, [userData, filters]);
  const claimable = useMemo(() => {
    return findCategoryByName(userData, "claimable", filters);
  }, [userData, filters]);
  const staked = useMemo(() => {
    return findCategoryByName(userData, "staked", filters);
  }, [userData, filters]);
  const debt = useMemo(() => {
    return findCategoryByName(userData, "debt", filters);
  }, [userData, filters]);
  const other = useMemo(() => {
    return findCategoryByName(userData, "other", filters);
  }, [userData, filters]);

  return (
    <div style={{ paddingBottom: '1px'}}>
      {!isLoading && userData && userData.status === "complete" && (
        <TotalBalance totalBalanceUSD={userData.totalBalanceUSD} updatedAt={userData.updatedAt} isLoading={isLoading} />
      )}
      <RefreshButton message={message} isRefreshing={isLoading} onRefresh={doRefresh} isDownloading={isDownloading} onDownload={userData && userData.status === "complete" ? doDownload : undefined} />
      {(isLoading || !userData) && <DashboardSkeleton />}
      {!isLoading && userData && userData.status === "complete" && (
        <>
          <DashboardDiagrams
            assetAllocationData={totalBalances}
            platformAllocationData={userData.protocolsTotal}
            networkAllocationData={userData.networksTotal}
            totalBalance={userData.totalBalanceUSD}
            isSummary={currentUser.isSummary}
          />
          {networks && <DashboardCategories title="Networks" data={networks} setFilters={setFilters} />}
          <DashboardWithTables
            calculateSum={calculateSum}
            filters={filters}
            fiat={fiat}
            offchain={offchain}
            exchange={exchange}
            wallet={wallet}
            deposit={deposit}
            pool={pool}
            claimable={claimable}
            debt={debt}
            staked={staked}
            other={other}
          />
          <GrandTotalBalance userData={userData} />
        </>
      )}
    </div>
  );
}

export default BalanceSummary;
