import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ulid } from "ulid";

import useQuery from "@hooks/useQuery";
import useAuthStore from "@hooks/globalStores/useAuthStore";
import { exchangeCode, getLoginLink } from "@services/user.service";
import { utilsService } from "@services/utils.service";

import { createStyles, makeStyles, Typography, CircularProgress, Button } from "@material-ui/core";

import CanvasButton from "@shared/UI/CanvasButton";
import canvasLogo from "@assets/images/canvas-logo.svg";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#333042',
      zIndex: 2000,
      position: 'relative',
    },
    logoTitle: {
      color: '#fff',
      textTransform: 'uppercase',
      fontWeight: 500,
      '& span': {
        color: 'rgba(255, 255, 255, 0.7)',
        fontWeight: 300
      }
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 30,
      '& img': {
        height: 50,
        width: 70,
        marginRight: 13,
      },
    },
    progress: {
      paddingTop: '2px'
    },
    buttons: {
      display: 'block'
    },
    btnLogin: {
      width: 120,
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
    },
    btnSignUp: {
      width: 100,
      color: 'rgba(255, 255, 255, 0.7)',
      fontWeight: 300,
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
    },
  }),
);

export const SignIn = () => {
  const { isAuthorized, setIdToken, setAccessToken, setRefreshToken, logout } = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const query = useQuery();

  const newUniqueId = () => {
    const uid = ulid();
    localStorage.setItem("uniqueId", uid);
    return uid;
  };

  const handleLogin = () => {
    // window.location.href = '/signin?error=503';
    // return;

    setIsLoading(true);
    const uid = newUniqueId();
    getLoginLink(uid).then(link => {
      // setIsLoading(false);
      // console.log(link);
      window.location.href = link;
    }).catch((e: any) => {
      console.log(e);
      utilsService.notify({ message: 'Unable to Login' });
    }).finally(() => setIsLoading(false));
  };

  const handleSignUp = () => {
    // window.location.href = '/signin?error=503';
    // return;

    setIsLoading(true);
    const uid = newUniqueId();
    getLoginLink(uid).then(link => {
      // setIsLoading(false);
      window.location.href = link + '&screen_hint=signup';
    }).catch((e: any) => {
      console.log(e);
      utilsService.notify({ message: 'Unable to Sign-up' });
    }).finally(() => setIsLoading(false));
  };

  // useEffect(() => {
  //   const error = query.get('error');
  //   if (error !== "503") {
  //     window.location.href = '/signin?error=503';
  //   }
  // }, [query]);

  useEffect(() => {
    if (isAuthorized) {
      setIsLoading(false);
      history.push("/");
    } else {
      const error = query.get('error');
      const code = query.get('code');
      const state = query.get('state');
      const url = query.get('callback_url') || '/';
      const uniqueId = localStorage.getItem('uniqueId');
      if (error === "401") {
        utilsService.notify({ message: 'Session timeout. Please Login again.' });
      }
      if (error === "403") {
        utilsService.notify({ message: 'You do not have sufficient permissions' });
      }
      if (error === "503") {
        utilsService.notify({ message: 'Closed for maintenance. Check back in 2 hrs...' });
      }
      if (code && (state === uniqueId)) {
        setIsLoading(true);
        const finalCode = Array.isArray(code) ? code[0] : code;
        exchangeCode(finalCode).then((data) => {
          if (data) {
            setIdToken(data.id_token);
            setAccessToken(data.access_token);
            setRefreshToken(data.refresh_token);
            setIsLoading(false);
            history.push(url);
          }
        }).catch((e: any) => {
          setIsLoading(false);
          console.log('exchangeCode', e);
          utilsService.notify({ message: 'Unable to Sign-in' });
          logout();
        }).finally(() => setIsLoading(false));
      } else {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.logoWrapper}>
        <div><img src={canvasLogo} alt="" /></div>
        <Typography variant="h1" className={classes.logoTitle}>CANVAS <span>Prime</span></Typography>
      </div>
      {isLoading && (
        <div className={classes.progress}>
          <CircularProgress color="primary" size={35} />
        </div>
      )}
      {!isLoading && (
        <div className={classes.buttons}>
          <CanvasButton onClick={handleLogin} loading={isLoading} size="medium" className={classes.btnLogin}>Login</CanvasButton>
          <Button onClick={handleSignUp} disabled={isLoading} size="medium" className={classes.btnSignUp}>Sign Up</Button>
        </div>
      )}
    </div>
  );
}