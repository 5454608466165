import React, { useCallback, useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';


import { useMediaQuery } from "@hooks/useMediaQuery";
import {
  isDesktopBreakPoint,
  isLaptopBreakPoint, isPhoneBreakPoint,
  isSmallDesktopBreakPoint,
  isSmallLaptopBreakPoint, isSmallPhoneBreakPoint
} from "../../../../../../utils/functions";
import { getSortData } from "../../../../../../utils/sortingData";
import { DashboardTable } from "@pages/Dashboard/components/Tables/DashboardTable";
import clsx from "clsx";
import IconsCollectionBadge from "../../../../../../shared/UI/IconsCollectionBadge";
import AllAssetsIcon from "@assets/images/all-assets-icon.svg";
import { TableSortLabel } from "@material-ui/core";
import SortIcon from "../../../../../../shared/UI/SortIcon/SortIcon";
import QuestionIcon from "@assets/images/question.svg";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";

interface SortTables {
  sort: 'asc' | 'desc';
  type: string;
}

interface SortAllTables {
  [key: string]: SortTables;
}

const useStyles = makeStyles(() => ({
  modalTable: {
    padding: 0,
  },
}));

const mainImages = {
  protoUnicorn: 'https://storage.googleapis.com/zapper-fi-assets/apps/uniswap-v2.png',
  balancerPyrovid: 'https://storage.googleapis.com/zapper-fi-assets/apps/balancer-v2.png',
};

export default function SummaryModalTable({ networks, totalBalance, name, rawName, value, filters }) {
  const classes = useStyles();
  const isDesktop = useMediaQuery(isDesktopBreakPoint);
  const isSmallDesktop = useMediaQuery(isSmallDesktopBreakPoint);
  const isLaptop = useMediaQuery(isLaptopBreakPoint);
  const isSmallLaptop = useMediaQuery(isSmallLaptopBreakPoint);
  const isPhone = useMediaQuery(isPhoneBreakPoint);
  const isSmallPhone = useMediaQuery(isSmallPhoneBreakPoint);

  const [sortTables, setSortTables] = useState<SortAllTables>({});
  const [balanceErrors, setBalanceErrors] = useState(false);
  const { formatNumberByCurrency } = useCurrencyStore();

  const ratioWidthTablet = useMemo(() => {
    if (isSmallPhone) {
      return 0.2;
    }
    if (isPhone) {
      return 0.5;
    }
    if (isSmallLaptop) {
      return 0.65;
    }
    if (isLaptop) {
      return 0.65;
    }
    if (isSmallDesktop) {
      return 0.65;
    }
    if (isDesktop) {
      return 0.7;
    }
    return 1;
  }, [isPhone, isDesktop, isLaptop, isSmallDesktop, isSmallLaptop, isSmallPhone]);


  const handleSort = useCallback((table: string, type: string) => {
    setSortTables((prev: any) => {
      const prevType = prev[table]?.type;
      const prevSort = prev[table]?.sort;

      if (!prev?.[table] || (prevType && prevType !== type)) {
        return {
          ...prev,
          [table]: { type: type, sort: 'asc' },
        };
      }

      if (prevType === type) {
        if (prevSort === 'asc') {
          return {
            ...prev,
            [table]: {
              type: prevType,
              sort: 'desc',
            },
          };
        } else {
          const currentPrev = { ...prev };
          delete currentPrev[table];
          return currentPrev;
        }
      }
    });
  }, []);

  const renderHeaderTablet = useCallback(
    (column: any) => {
      const tableId = column.columnKey;
      const sortTable = sortTables?.[tableId];
      const columnName = column.prop;

      return (
        <TableSortLabel
          direction={sortTable?.sort}
          IconComponent={() => (
            <SortIcon active={sortTable?.type === columnName} direction={sortTable?.sort} />
          )}
          onClick={() => handleSort(tableId, columnName)}>
          {column.label}
        </TableSortLabel>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortTables],
  );

  const sortDataArray = useMemo(() => {
    if (networks?.length) {
      try {
        const dataArray = networks.map((network: any) => {
          const networkData = network.data.reduce(
            (acc: any, value: any) => {
              switch (rawName) {
                case 'fiat':
                  return {
                    dataArray: [
                      ...acc.dataArray,
                      {
                        label: value.label ? value.label : (value?.tokens?.length ? value.tokens[0].symbol : 'No name'),
                        mainIcon: value.imageUrl,
                        balance: (value.balance
                          ? value.balance : (value?.tokens?.length ? value.tokens[0].balance : 0)).toFixed(4),
                        price: value.price,
                        value: value.balanceUSD.toFixed(2),
                      },
                    ],
                    sum: acc.sum + value.balanceUSD,
                  };
                case 'exchange':
                  return {
                    dataArray: [
                      ...acc.dataArray,
                      {
                        label: value.label ? value.label : (value?.tokens?.length ? value.tokens[0].symbol : 'No name'),
                        mainIcon: value.imageUrl,
                        balance: (value.balance
                          ? value.balance : (value?.tokens?.length ? value.tokens[0].balance : 0)).toFixed(4),
                        price: value.price,
                        value: value.balanceUSD.toFixed(2),
                      },
                    ],
                    sum: acc.sum + value.balanceUSD,
                  };
                case 'wallet':
                  return {
                    dataArray: [
                      ...acc.dataArray,
                      {
                        label: value.label ? value.label : (value?.tokens?.length ? value.tokens[0].symbol : 'No name'),
                        mainIcon: value.imageUrl,
                        balance: (value.balance
                          ? value.balance : (value?.tokens?.length ? value.tokens[0].balance : 0)).toFixed(4),
                        price: value.price,
                        value: value.balanceUSD.toFixed(2),
                      },
                    ],
                    sum: acc.sum + value.balanceUSD,
                  };
                case 'deposit':
                  return {
                    dataArray: [
                      ...acc.dataArray,
                      {
                        label: value.label ? value.label : (value?.tokens?.length ? value.tokens[0].symbol : 'No name'),
                        mainIcon: value.imageUrl,
                        balance: (value.balance
                          ? value.balance : (value?.tokens?.length ? value.tokens[0].balance : 0)).toFixed(4),
                        apy: value.apy,
                        value: value.balanceUSD.toFixed(2),
                      },
                    ],
                    sum: acc.sum + value.balanceUSD,
                  };
                case 'pool':
                  return {
                    dataArray: [
                      ...acc.dataArray,
                      {
                        label: value.label ? value.label : (value?.tokens?.length ? value.tokens[0].symbol : 'No name'),
                        mainIcon: value.tokens[0]?.tokenAddress,
                        protocolIcon: value.imageUrl
                          ? value.imageUrl : (mainImages.protoUnicorn ? mainImages.protoUnicorn : QuestionIcon),
                        secondIcon: value.tokens[1]?.tokenAddress,
                        balanceFirst: value.tokens[0]?.balance.toFixed(4),
                        symbolFirst: value.tokens[0]?.symbol,
                        balanceSecond: value.tokens[1]?.balance
                          ? value.tokens[1]?.balance.toFixed(4)
                          : '',
                        symbolSecond: value.tokens[1]?.symbol ? value.tokens[1]?.symbol : '',
                        apy: value.apy,
                        value: value.balanceUSD.toFixed(2),
                      },
                    ],
                    sum: acc.sum + value.balanceUSD,
                  };
                case 'claimable':
                  return {
                    dataArray: [
                      ...acc.dataArray,
                      {
                        label: value.label ? value.label : (value?.tokens?.length ? value.tokens[0].symbol : 'No name'),
                        mainIcon: value.tokens[0].tokenAddress,
                        balance: (value.balance
                          ? value.balance : (value?.tokens?.length ? value.tokens[0].balance : 0)).toFixed(4),
                        value: value.tokens[0].balanceUSD.toFixed(2),
                      },
                    ],
                    sum: acc.sum + value.tokens[0].balanceUSD,
                  };
                case 'debt':
                  return {
                    dataArray: [
                      ...acc.dataArray,
                      {
                        label: value.label ? value.label : (value?.tokens?.length ? value.tokens[0].symbol : 'No name'),
                        mainIcon: value.imageUrl,
                        protocolIcon: mainImages?.protoUnicorn ? mainImages.protoUnicorn : QuestionIcon,
                        balance: (value.balance
                          ? value.balance : (value?.tokens?.length ? value.tokens[0].balance : 0)).toFixed(4),
                        value: value.balanceUSD.toFixed(2),
                      },
                    ],
                    sum: acc.sum + value.balanceUSD,
                  };
                case 'other':
                  return {
                    dataArray: [
                      ...acc.dataArray,
                      {
                        label: value.label ? value.label : (value?.tokens?.length ? value.tokens[0].symbol : 'No name'),
                        mainIcon: value.tokenAddress,
                        balance: (value.balance
                          ? value.balance : (value?.tokens?.length ? value.tokens[0].balance : 0)).toFixed(4),
                        value: value.balanceUSD.toFixed(2),
                      },
                    ],
                    sum: acc.sum + value.balanceUSD,
                  };
                case 'staked':
                  return {
                    dataArray: [
                      ...acc.dataArray,
                      {
                        label: value.label ? value.label : (value?.tokens?.length ? value.tokens[0].symbol : 'No name'),
                        mainIcon: value.imageUrl,
                        price: value.price ? (+value.price)?.toFixed(4) : (+value.tokens[0].price).toFixed(4),
                        balance: (value.balance
                          ? value.balance : (value?.tokens?.length ? value.tokens[0].balance : 0)).toFixed(4),
                        value: value.balanceUSD?.toFixed(2),
                      },
                    ],
                    sum: acc.sum + value.balanceUSD,
                  };
              }
              return null;
            },
            {
              dataArray: [],
              sum: 0,
            },
          );

          const sortDataInfo = getSortData(
            networkData?.dataArray,
            sortTables?.[`${rawName}${network.name}`],
          );

          switch (rawName) {
            case 'fiat':
              return [
                ...sortDataInfo,
                {
                  label: `Total Assets: ${sortDataInfo.length}`,
                  mainIcon: '',
                  protocolIcon: '',
                  balance: '',
                  value: networkData.sum.toFixed(2),
                  isEndRow: true,
                },
              ];
            case 'exchange':
              return [
                ...sortDataInfo,
                {
                  label: `Total Assets: ${sortDataInfo.length}`,
                  mainIcon: '',
                  protocolIcon: '',
                  balance: '',
                  value: networkData.sum.toFixed(2),
                  isEndRow: true,
                },
              ];
            case 'wallet':
              return [
                ...sortDataInfo,
                {
                  label: `Total Assets: ${sortDataInfo.length}`,
                  mainIcon: '',
                  protocolIcon: '',
                  balance: '',
                  value: networkData.sum.toFixed(2),
                  isEndRow: true,
                },
              ];
            case 'deposit':
              return [
                ...sortDataInfo,
                {
                  label: `Total Assets: ${sortDataInfo.length}`,
                  mainIcon: '',
                  balance: '',
                  apy: '',
                  value: networkData.sum.toFixed(2),
                  isEndRow: true,
                },
              ];
            case 'pool':
              return [
                ...sortDataInfo,
                {
                  label: `Total Assets: ${sortDataInfo.length}`,
                  mainIcon: '',
                  protocolIcon: '',
                  secondIcon: '',
                  balance: '',
                  apy: '',
                  value: networkData.sum.toFixed(2),
                  isEndRow: true,
                },
              ];
            case 'claimable':
              return [
                ...sortDataInfo,
                {
                  label: `Total Assets: ${sortDataInfo.length}`,
                  mainIcon: '',
                  balance: '',
                  apy: '',
                  value: networkData.sum.toFixed(2),
                  isEndRow: true,
                },
              ];
            case 'debt':
              return [...sortDataInfo];
            case 'other':
              return [
                ...sortDataInfo,
                {
                  label: `Total Assets: ${sortDataInfo.length}`,
                  mainIcon: '',
                  balance: '',
                  apy: '',
                  value: networkData.sum.toFixed(2),
                  isEndRow: true,
                },
              ];
            case 'staked':
              return [
                ...sortDataInfo,
                {
                  label: `Total Assets: ${sortDataInfo.length}`,
                  mainIcon: '',
                  protocolIcon: '',
                  balance: '',
                  value: networkData.sum.toFixed(2),
                  isEndRow: true,
                },
              ];
          }

          return null;
        });
        return dataArray;
      } catch (e) {
        console.log('----tableError');
        console.log(e);
        setBalanceErrors(true);
      }
    }
  }, [networks, sortTables, rawName]);

  const categoryName = useMemo(() => {
    return networks.map((item: any) => item?.name || '');
  }, [networks]);

  const currentColumnsFiat = useMemo(() => {
    if (!isPhone) {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 350 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge-desktop')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 190 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 190 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <>
                {!value.isEndRow && (
                  <span className='table-text'>
                    {formatNumberByCurrency(value.price, { toFixed: 4 })}
                  </span>
                )}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 190 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          fixed: 'left',
          width: 400 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 350 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <>{!value.isEndRow && <span className='table-text'>$ {value.price}</span>}</>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);

  const currentColumnsExchange = useMemo(() => {
    if (!isPhone) {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 350 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge-desktop')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 190 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 190 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <>
                {!value.isEndRow && (
                  <span className='table-text'>
                    {formatNumberByCurrency(value.price, { toFixed: 4 })}
                  </span>
                )}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 190 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          fixed: 'left',
          width: 400 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 350 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <>{!value.isEndRow && <span className='table-text'>$ {value.price}</span>}</>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);

  const currentColumnsWallet = useMemo(() => {
    if (!isPhone) {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 350 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge-desktop')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 190 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 190 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <>
                {!value.isEndRow && (
                  <span className='table-text'>
                    {formatNumberByCurrency(value.price, { toFixed: 4 })}
                  </span>
                )}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 190 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          fixed: 'left',
          width: 400 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 350 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <>{!value.isEndRow && <span className='table-text'>$ {value.price}</span>}</>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);

  const currentColumnsDeposit = useMemo(() => {
    if (isPhone) {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 400 * ratioWidthTablet,
          fixed: 'left',
          render: (value: any) => {
            return (
              <div className='icons-badge'>
                <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge')}>
                  <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                  <span>{value.label}</span>
                </div>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 350 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'APY',
          prop: 'apy',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <span className='table-text'>{value.apy ? value.apy.toFixed(2) + '%' : '-'}</span>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 388 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge-desktop')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 250 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'APY',
          prop: 'apy',
          width: 250 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <>
                {!value.isEndRow && (
                  <span className='table-text'>{value.apy ? value.apy.toFixed(2) + '%' : '-'}</span>
                )}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);

  const currentColumnsPool = useMemo(() => {
    if (isPhone) {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 400 * ratioWidthTablet,
          fixed: 'left',
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge')}>
                <IconsCollectionBadge
                  mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                  protocolIcon={value.protocolIcon}
                  secondIcon={value.secondIcon}
                />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 320 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 550 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <>
                {!value.isEndRow && (
                  <span className='table-text'>{`${value.balanceFirst} ${value.symbolFirst} / ${value.balanceSecond} ${value.symbolSecond}`}</span>
                )}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 388 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge-desktop')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                  protocolIcon={value.protocolIcon}
                  secondIcon={value.secondIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 500 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <>
                {!value.isEndRow && (
                  <span className='table-text'>{`${value.balanceFirst} ${value.symbolFirst} / ${value.balanceSecond} ${value.symbolSecond}`}</span>
                )}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);

  const currentColumnsClaimable = useMemo(() => {
    if (isPhone) {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 400 * ratioWidthTablet,
          fixed: 'left',
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge')}>
                <IconsCollectionBadge
                  mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 380 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 380 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 388 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge-desktop')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 500 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);

  const currentColumnsDebt = useMemo(() => {
    if (isPhone) {
      return [
        {
          label: 'Liability',
          prop: 'liability',
          width: 400 * ratioWidthTablet,
          fixed: 'left',
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge')}>
                <IconsCollectionBadge
                  mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                  protocolIcon={value.protocolIcon}

                />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 380 * ratioWidthTablet,
          render: (value: any) => {
            const isNegative = Math.sign(value.value) === -1;
            return (
              <>
                {isNegative && '('}
                {formatNumberByCurrency(Math.abs(value.value))}
                {isNegative && ')'}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 380 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: 'Liability',
          prop: 'liability',
          width: 388 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge-desktop')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                  protocolIcon={value.protocolIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 500 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            const isNegative = Math.sign(value.value) === -1;
            return (
              <>
                {isNegative && '('}
                {formatNumberByCurrency(Math.abs(value.value))}
                {isNegative && ')'}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);

  const currentColumnsStaked = useMemo(() => {
    if (!isPhone) {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 388 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge-desktop')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 250 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 250 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <>
                {!value.isEndRow && (
                  <span className='table-text'>
                    {formatNumberByCurrency(value.price, { toFixed: 4 })}
                  </span>
                )}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 400 * ratioWidthTablet,
          fixed: 'left',
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge')}>
                <IconsCollectionBadge
                  mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 350 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <>{!value.isEndRow && <span className='table-text'>$ {value.price}</span>}</>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);

  const currentColumnsOther = useMemo(() => {
    if (isPhone) {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 400 * ratioWidthTablet,
          fixed: 'left',
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge')}>
                <IconsCollectionBadge
                  mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                  protocolIcon={value.protocolIcon}
                />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 380 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 380 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 388 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge-desktop')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon}
                  protocolIcon={value.protocolIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 500 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);

  const columns = {
    fiat: currentColumnsFiat,
    exchange: currentColumnsExchange,
    wallet: currentColumnsWallet,
    deposit: currentColumnsDeposit,
    pool: currentColumnsPool,
    claimable: currentColumnsClaimable,
    debt: currentColumnsDebt,
    staked: currentColumnsStaked,
    other: currentColumnsOther,
  };

  return (
    <>
      {!balanceErrors && <DashboardTable
        dataArray={sortDataArray}
        columns={columns[rawName]}
        categoryNameArray={categoryName}
        style={{ padding: 0 }}
        title={name}
        className={classes.modalTable}
        titleValue={totalBalance?.toFixed(2)}
      />
      }
    </>

  );
}
