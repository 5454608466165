import { makeStyles, Theme } from "@material-ui/core/styles";
import Bowser from "bowser";

import logoComingSoon from "@assets/images/coming-soon.svg";

export const ComingSoonPage = ({ isBlur, display = true }: { isBlur?: boolean; display?: boolean }) => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isSafari = browser.getBrowser().name === "Safari";

  const useStyles = makeStyles((theme: Theme) => ({
      comingSoonContent: {
        position: "relative",
        height: "600px",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        borderRadius: 20,
      },
      contentOnChange: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      },
      imagePage: {
        height: "calc(100% - 100px)",
        width: "calc(100% - 100px)",
        filter: "blur(3px)",
        opacity: "0.5",
      },
      comingSoonLogo: {
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      comingSoonImage: {
        width: 70,
        height: 70,
        zIndex: 10,
      },
      comingSoonText: {
        height: "10%",
        fontFamily: "Cera Pro",
        fontSize: 30,
        zIndex: 20,
        width: "100%",
        color: "#333042",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: isSafari ? 20 : 0,
      },
      blur: {
          display: 'flex',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: '#fff',
          opacity: '0.9',
          zIndex: 800,
          width: '100%',
          borderRadius: 8,
      },
    })
  );

  const classes = useStyles();

  if (!display) {
      return null;
  }

  return (
    <div className={isBlur ? classes.blur :  classes.comingSoonContent}>
      <div className={classes.comingSoonLogo}>
        <img
          src={logoComingSoon}
          alt={"logo-canvas"}
          className={classes.comingSoonImage}
        />
        <span className={classes.comingSoonText}>Coming Soon</span>
      </div>
    </div>
  );
};
