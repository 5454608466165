import { useState } from "react";
import { format, subDays, subWeeks, subMonths, subYears } from "date-fns";

import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";

import DashboardHistogramButtons from "@pages/Dashboard/components/DashboardHistogram/DashboardHistogramButtons";
import CanvasSkeleton from "@shared/UI/CanvasSkeleton";

import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import useGetBalanceHistory from "@hooks/fetchers/Dashboard/useGetBalanceHistory";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
  grid: {
    marginBottom: theme.spacing(2),
  },
  chartTitle: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontFamily: 'Cera Pro'
  },
  skeleton: {
    height: '250px',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },  
  badge: {
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(0.5, 1),
  },
  buttons: {
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toolTip: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
}));

const currentDate = new Date();
const periodButtons = [
  {
    name: '1 D',
    ticks: 'hour',
    tickFormat: 'haaa',
    toolTipFormat: 'dd-MMM-yyyy haaa',
    from: subDays(currentDate, 1).toISOString(),
    to: currentDate.toISOString(),
  },
  {
    name: '1 W',
    ticks: 'hour',
    tickFormat: 'd-MMM haaa',
    toolTipFormat: 'dd-MMM-yyyy haaa',
    from: subWeeks(currentDate, 1).toISOString(),
    to: currentDate.toISOString(),
  },
  {
    name: '1 M',
    ticks: 'day',
    tickFormat: 'd-MMM',
    toolTipFormat: 'dd-MMM-yyyy',
    from: subMonths(currentDate, 1).toISOString(),
    to: currentDate.toISOString(),
  },
  {
    name: '3 M',
    ticks: 'day',
    tickFormat: 'd-MMM',
    toolTipFormat: 'dd-MMM-yyyy',
    from: subMonths(currentDate, 3).toISOString(),
    to: currentDate.toISOString(),
  },
  {
    name: '1 Y',
    ticks: 'day',
    tickFormat: 'MMM yy',
    toolTipFormat: 'dd-MMM-yyyy',
    from: subYears(currentDate, 1).toISOString(),
    to: currentDate.toISOString(),
  },
];

export default function PortfolioPerformanceChart() {
  const [selectedPeriod, setSelectedPeriod] = useState<any>(periodButtons.find((x: any) => x.name === '1 M'));
  const { formatNumberByCurrency } = useCurrencyStore();
  const { data, isLoading } = useGetBalanceHistory('categories', selectedPeriod?.from, selectedPeriod?.to, selectedPeriod?.ticks);
  const classes = useStyles();

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Paper className={classes.toolTip}>
          <div>{format(new Date(label), selectedPeriod.toolTipFormat)}</div>
          {payload.map((item: any) => item.value !== 0 && (
            <div className={classes.badge} style={{ backgroundColor: item.fill }}>{item.name}: {formatNumberByCurrency(item.value, { toFixed: 0 })}</div>
          ))}
        </Paper>
      );
    }
    return <></>;
  };

  return (
    <>
      {!data && (
        <CanvasSkeleton className={classes.skeleton} />
      )}
      {data && (
        <Paper className={classes.paper}>
          <Grid container justifyContent="space-between" alignItems="center" className={classes.grid}>
            <Grid item>
              <Typography variant={'h3'} className={classes.title}>Category Performance</Typography>
            </Grid>
            <Grid item className={classes.buttons}>
              <DashboardHistogramButtons
                onSelect={setSelectedPeriod}
                disabled={isLoading}
                selected={selectedPeriod.name}
                data={periodButtons}
              />
            </Grid>
          </Grid>
          <ResponsiveContainer width='100%' aspect={1.0 / 0.25}>
            <AreaChart data={data.chart} margin={{ top: 10, right: 40, left: 0, bottom: 0 }}>
              <Area stackId={1} dataKey={'fiat'} type="monotone" stroke="#fff" fillOpacity={1} fill="#012a4aff" />
              <Area stackId={1} dataKey={'offchain'} type="monotone" stroke="#fff" fillOpacity={1} fill="#013a63ff" />
              <Area stackId={1} dataKey={'exchange'} type="monotone" stroke="#fff" fillOpacity={1} fill="#01497cff" />
              <Area stackId={1} dataKey={'wallet'} type="monotone" stroke="#fff" fillOpacity={1} fill="#014f86ff" />
              <Area stackId={1} dataKey={'staked'} type="monotone" stroke="#fff" fillOpacity={1} fill="#2a6f97ff" />
              <Area stackId={1} dataKey={'deposit'} type="monotone" stroke="#fff" fillOpacity={1} fill="#2c7da0ff" />
              <Area stackId={1} dataKey={'pool'} type="monotone" stroke="#fff" fillOpacity={1} fill="#468fafff" />
              <Area stackId={1} dataKey={'claimable'} type="monotone" stroke="#fff" fillOpacity={1} fill="#61a5c2ff" />
              <Area stackId={1} dataKey={'debt'} type="monotone" stroke="#fff" fillOpacity={1} fill="#89c2d9ff" />
              <Area stackId={1} dataKey={'other'} type="monotone" stroke="#fff" fillOpacity={1} fill="#a9d6e5ff" />
              <XAxis tickFormatter={(value) => format(new Date(value), selectedPeriod.tickFormat)} dataKey='date' />
              <YAxis tickFormatter={(value) => formatNumberByCurrency(value, { isAbbreviateNumber: true })} />
              <Tooltip content={<CustomTooltip />} />
            </AreaChart>
          </ResponsiveContainer>
        </Paper>
      )}
    </>
  );
}