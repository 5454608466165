import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from "recharts";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Paper, Box, Typography } from "@material-ui/core";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { utilsService } from "@services/utils.service";
import { chartColors } from "@utils/colors";

const useStyles = makeStyles((theme: Theme) => ({
  chartContainer: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
  },
  chartTitle: {
    marginBottom: theme.spacing(5),
  },
  title: {
    fontFamily: 'Cera Pro'
  },
  dateContainer: {
    paddingBottom: theme.spacing(0),
  },
  badge: {
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(0.5, 1),
  },
  toolTip: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
}));

export default function CategoryBarChart({ categories }) {
  const { formatNumberByCurrency } = useCurrencyStore();
  const classes = useStyles();

  const chartData: any[] = [];

  if (categories) {
    categories.forEach((category) => {
      if (category.balanceUSD !== 0) {
        let data: any = {};
        data["category"] = utilsService.capitalizeLetter(category.name);
        data["total"] = category.balanceUSD;
        chartData.push(data);
      }
    });
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      // console.log(label, active, payload);
      return (
        <Paper className={classes.toolTip}>
          <h6>{label}</h6>
          {payload.map((item: any, index: number) => item.value !== 0 && (
            <div key={index} className={classes.badge} style={{ backgroundColor: item.fill }}>{formatNumberByCurrency(item.value, { toFixed: 0 })}</div>
          ))}
        </Paper>
      );
    }
    return <></>;
  };

  return (
    <Paper className={classes.chartContainer} style={{ borderRadius: 5 }}>
      <Box className={classes.chartTitle}>
        <Typography variant={"h3"}>Categories</Typography>
      </Box>
      <ResponsiveContainer width={"100%"} height={400} className={"pie-chart-wrapper"}>
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 10,
            bottom: 5,
          }}
          barSize={50}
          stackOffset='sign'
        >
          <XAxis dataKey='category' scale='point' padding={{ left: 30, right: 10 }} dy={5} />
          <YAxis
            tickFormatter={(balance) => {
              if (balance >= 0) {
                return formatNumberByCurrency(Math.abs(balance), {
                  isAbbreviateNumber: true,
                });
              } else {
                let formattedBalance = formatNumberByCurrency(Math.abs(balance), {
                  isAbbreviateNumber: true,
                });
                return `-${formattedBalance}`;
              }
            }}
            dx={-15}
          />
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid stroke='#f5f5f5' />
          <Bar dataKey='total' background={{ fill: "#fcfcfa" }}>
            {chartData.map((chart, index) => (
              <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]}>{chart}</Cell>
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
}
