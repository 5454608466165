import React, { createContext, useState } from "react";


export const HideZerosContext = createContext<any>(null);

export const HideZerosProvider = ({ children } : { children : React.ReactElement }) => {
    const [isHideZero, setIsHideZero] = useState(false);

    return (
        <HideZerosContext.Provider
            value={{
                isHideZero,
                setIsHideZero,
            }}
        >
            {children}
        </HideZerosContext.Provider>
    );
};
