import { useState, useEffect, useCallback } from 'react';
import api from '@services/api';
import { format } from 'date-fns';

const request = api('wallets');

const getData: any = async (isAuthorized: boolean, walletId: string, parsedSelectedWallets: string) => {
  if (walletId) {
    // public wallet address
    return await request(`${walletId}/transactions`);
  } else if (isAuthorized) {
    // logged in user with selection of wallet addresses
    return await request(`transactions${parsedSelectedWallets}`);
  }
};

const postRefresh: any = async (isAuthorized: boolean, walletId: string, parsedSelectedWallets: string, refreshType: string) => {
  if (walletId) {
    // public wallet address
    return await request(`${walletId}/transactions/refresh/${refreshType}`, 'post');
  } else if (isAuthorized) {
    // logged in user with selection of wallet addresses
    return await request(`transactions/refresh/${refreshType}${parsedSelectedWallets}`, 'post');
  }
};

const getRefresh: any = async (refreshId: string) => {
  return await request(`transactions/refresh/${refreshId}`);
};

export const useGetTransactions = (isAuthorized: boolean, walletId: string | null, parsedSelectedWallets: string | null, timestamp: number) => {
  const [prevRefreshTimestamp, setPrevRefreshTimestamp] = useState(timestamp);
  const [refreshTimestamp, setRefreshTimestamp] = useState(prevRefreshTimestamp);
  const [prevQueryTimestamp, setPrevQueryTimestamp] = useState(new Date().getTime());
  const [queryTimestamp, setQueryTimestamp] = useState(prevQueryTimestamp);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [message, setMessage] = useState('');

  useEffect(() => {
    setIsFirstLoad(true);
    setQueryTimestamp(new Date().getTime());
  }, [isAuthorized, walletId, parsedSelectedWallets]);

  const queryData = useCallback(() => {
    getData(isAuthorized, walletId, parsedSelectedWallets).then((newData: any) => {
      setIsLoading(false);
      if (newData.status) {
        console.log(newData);
        setUserData(newData);
        setMessage('Transactions successfully updated at: ' + format(new Date(), `'&nbsp;<span>'dd-MMM-yyyy, H:mm a'</span>&nbsp;' z`));
        setIsFirstLoad(false);
      } else {
        setMessage(`Error downloading transactions: ${newData.message}`);
        if (isFirstLoad) {
          // if not found - on first try - do a refresh
          setRefreshTimestamp(new Date().getTime());
          setIsFirstLoad(false);
        }        
      }
    }).catch((error: any) => {
      setIsLoading(false);
      console.log('getData', error);
      if (error.response) {
        setMessage('Error: <span>' + error.response.data.message + '</span>. Please try again...');
      } else {
        setMessage('<span>' + error + '</span>');
      }
    });
  }, [isAuthorized, walletId, parsedSelectedWallets, isFirstLoad]);

  const queryRefresh = useCallback(() => {
    postRefresh(isAuthorized, walletId, parsedSelectedWallets, 'all').then((refresh) => {
      // console.log('postRefresh', walletId, parsedSelectedWallets, refresh);
      if (refresh?.ulid) {
        let pollStart = new Date().getTime();
        const intervalId = setInterval(() => {
          const pollNow = new Date().getTime();
          const pollSecs = Math.floor((pollNow - pollStart) / 1000);
          setMessage(`Waiting for transactions ... ${pollSecs} secs`);
          if (pollSecs > 180) {
            setMessage(`Giving up waiting for transactions. Please try again ...`);
            clearInterval(intervalId);
            setIsRefreshing(false);
          } else if (pollSecs % 3 === 0) {
            getRefresh(refresh.ulid).then(poll => {
              console.log('getRefresh', refresh.ulid, poll);
              if (poll.every(x => x.state === 'COMPLETED' || x.state === 'FAILED')) {
                console.log('Refresh All:', pollSecs, 'secs');
                setQueryTimestamp(new Date().getTime());
                clearInterval(intervalId);
                setIsRefreshing(false);
              }
            }).catch((error: any) => {
              clearInterval(intervalId);
              setIsRefreshing(false);
              if (error.response) {
                setMessage('Error: <span>' + error.response.data.message + '</span>. Please try again...');
              } else {
                setMessage('<span>' + error + '</span>');
              }
            });
          }
        }, 1000);
      } else {
        setIsRefreshing(false);
        setMessage('Error: <span>No Refresh Id</span>. Please try again...');
      }
    }).catch((error: any) => {
      setIsRefreshing(false);
      console.log('postRefresh', error);
      if (error.response) {
        setMessage('Error: <span>' + error.response.data.message + '</span>. Please try again...');
      } else {
        setMessage('<span>' + error + '</span>');
      }
    });
  }, [isAuthorized, walletId, parsedSelectedWallets]);

  useEffect(() => {
    setRefreshTimestamp(timestamp);
  }, [timestamp]);

  useEffect(() => {
    if (prevRefreshTimestamp !== refreshTimestamp) {
      setPrevRefreshTimestamp(refreshTimestamp);
      setIsRefreshing(true);
      setMessage('Refreshing transactions ....');
      setTimeout(() => { // UI responsiveness
        queryRefresh();
      }, 200);
    }
  }, [prevRefreshTimestamp, refreshTimestamp, queryRefresh]);

  useEffect(() => {
    if (prevQueryTimestamp !== queryTimestamp) {
      setPrevQueryTimestamp(queryTimestamp);
      setIsLoading(true);
      setMessage('Downloading transactions ....');
      setTimeout(() => { // UI responsiveness
        queryData();
      }, 200);
    }
  }, [prevQueryTimestamp, queryTimestamp, queryData]);

  return {
    userData: userData,
    isLoading: isLoading || isRefreshing,
    message: message,
  }
}
