import { useState, useMemo } from "react";
import { isValid, format, subDays, subWeeks, subMonths, subYears } from "date-fns";

import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";

import DashboardHistogramButtons from "@pages/Dashboard/components/DashboardHistogram/DashboardHistogramButtons";
import CanvasSkeleton from "@shared/UI/CanvasSkeleton";

import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import useGetPortfolioHistory from "@hooks/fetchers/Dashboard/useGetPortfolioHistory";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
  grid: {
    marginBottom: theme.spacing(2),
  },
  chartTitle: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontFamily: 'Cera Pro'
  },
  skeleton: {
    height: '250px',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  badge: {
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(0.5, 1),
  },
  buttons: {
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toolTip: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
}));

const currentDate = new Date();
const periodButtons = [
  {
    name: '1 D',
    ticks: 'hour',
    tickFormat: 'haaa',
    toolTipFormat: 'dd-MMM-yyyy haaa',
    from: subDays(currentDate, 1).toISOString(),
    to: currentDate.toISOString(),
  },
  {
    name: '1 W',
    ticks: 'hour',
    tickFormat: 'd-MMM haaa',
    toolTipFormat: 'dd-MMM-yyyy haaa',
    from: subWeeks(currentDate, 1).toISOString(),
    to: currentDate.toISOString(),
  },
  {
    name: '1 M',
    ticks: 'day',
    tickFormat: 'd-MMM',
    toolTipFormat: 'dd-MMM-yyyy',
    from: subMonths(currentDate, 1).toISOString(),
    to: currentDate.toISOString(),
  },
  {
    name: '3 M',
    ticks: 'day',
    tickFormat: 'd-MMM',
    toolTipFormat: 'dd-MMM-yyyy',
    from: subMonths(currentDate, 3).toISOString(),
    to: currentDate.toISOString(),
  },
  {
    name: '1 Y',
    ticks: 'day',
    tickFormat: 'MMM yy',
    toolTipFormat: 'dd-MMM-yyyy',
    from: subYears(currentDate, 1).toISOString(),
    to: currentDate.toISOString(),
  },
];

export default function PortfolioPerformanceChart({ parentIsLoading }) {
  const [selectedPeriod, setSelectedPeriod] = useState<any>(periodButtons.find((x: any) => x.name === '1 M'));
  const { formatNumberByCurrency } = useCurrencyStore();
  const { data, isLoading } = useGetPortfolioHistory(selectedPeriod?.from, selectedPeriod?.to, selectedPeriod?.ticks);
  const chart = useMemo(() => data?.chart?.filter((x: any) => x.total !== 0), [data]);

  const classes = useStyles();

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Paper className={classes.toolTip}>
          <div>{format(new Date(label), selectedPeriod.toolTipFormat)}</div>
          <div className={classes.badge}>{formatNumberByCurrency(payload[0].value, { toFixed: 0 })}</div>
        </Paper>
      );
    }
    return <></>;
  };

  const formatDate = (value: string) => {
    if (isValid(value)) {
      return format(new Date(value), selectedPeriod.tickFormat)
    }
    return '';
  };

  return (
    <>
      {(parentIsLoading || !chart) && (
        <CanvasSkeleton className={classes.skeleton} />
      )}
      {!parentIsLoading && chart && chart.length > 0 && (
        <Paper className={classes.paper}>
          <Grid container justifyContent="space-between" alignItems="center" className={classes.grid}>
            <Grid item>
              <Typography variant={'h3'} className={classes.title}>Portfolio Performance</Typography>
            </Grid>
            <Grid item className={classes.buttons}>
              <DashboardHistogramButtons
                onSelect={setSelectedPeriod}
                disabled={isLoading}
                isLoading={isLoading}
                selected={selectedPeriod.name}
                data={periodButtons}
              />
            </Grid>
          </Grid>
          <ResponsiveContainer width='100%' aspect={1.0 / 0.35}>
            <AreaChart data={chart} margin={{ top: 10, right: 40, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#6699CC" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#6699CC" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area type="monotone" dataKey="total" stroke="#6699CC" fillOpacity={1} fill="url(#colorTotal)" />
              <XAxis tickFormatter={(value) => formatDate(value)} dataKey='date' angle={-35} textAnchor="end" interval="preserveStartEnd" tick={{ fontSize: 11 }} tickCount={15} height={50} />
              <YAxis tickFormatter={(value) => formatNumberByCurrency(value, { isAbbreviateNumber: true })} />
              <Tooltip content={<CustomTooltip />} />
            </AreaChart>
          </ResponsiveContainer>
        </Paper>
      )}
    </>
  );
}
