import CanvasSkeleton from "@shared/UI/CanvasSkeleton";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  skeleton: {
    height: 250,
    marginBottom: theme.spacing(4)
  },
}));

function DashboardSkeleton() {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <CanvasSkeleton className={classes.skeleton} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <CanvasSkeleton className={classes.skeleton} />
        </Grid>
      </Grid>
      <CanvasSkeleton className={classes.skeleton} />
      <CanvasSkeleton className={classes.skeleton} />
      <CanvasSkeleton className={classes.skeleton} />
    </>
  );
}

export default DashboardSkeleton;
