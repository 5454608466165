import React, { useState } from "react";
import { Grid, IconButton, makeStyles, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import ArrowDown from '@assets/images/arrowDown.svg';
import CopyIcon from '@assets/images/new-copy-icon.svg';
import EnvelopAirplane from '@assets/images/envelope-airplane.svg';
import Instagram from '@assets/socialNetworkIcons/instagram.svg';
import Discord from '@assets/socialNetworkIcons/discord.svg';
import Facebook from '@assets/socialNetworkIcons/facebook.svg';
import LinkedIn from '@assets/socialNetworkIcons/linkedIn.svg';
import Telegram from '@assets/socialNetworkIcons/telegram.svg';
import Twitter from '@assets/socialNetworkIcons/twitter.svg';
import WhatsApp from '@assets/socialNetworkIcons/WhatsApp.svg';
import { useTheme } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    container: {},
    header: {
        marginBottom: 16,
    },
    headerTitle: {
        display: 'flex',
        marginBottom: 14,
    },
    headerDescription: {
        color: 'rgba(51, 48, 66, 0.5)',
    },
    content: {},
    contentWrapper: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30,
        },
    },
    contentItem: {
        display: 'flex',
        border: '1px solid #EAE6E2',
        borderRadius: 20,
        flexDirection: 'column',
        marginBottom: 32,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
        },
    },
    contentItemHeader: {
        display: 'flex',
        padding: '20px 24px',
        borderBottom: '1px solid #EAE6E2',
    },
    contentItemContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 108,
    },
    divider: {
        background: '#EAE6E2',
        height: 1,
        width: '100%',
        marginBottom: 32,
    },
    socialSection: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    referalWrapper: {
        height: 76,
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            marginBottom: 32,
            marginRight: 0,
            padding: '16px 15px',
            '& img': {
                width: 15,
                height: 15,
            },
        },
        background: '#F37222',
        padding: '16px 32px',
        color: '#fff',
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: 21,
        flex: 0.5,
    },
    socialNetworks: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #EAE6E2',
        borderRadius: 20,
        padding: '0 32px',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            padding: '20px 32px',
        },
    },
    socialItemsWrapper: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 2,
        },
    },
}));

const overviewInfo = [
    {
        name: 'Your Rewards',
        color: '#F37222',
        extraContentColor: '#808191',
        data: [
            {
                title: 'Total Rewards Earned',
                content: '1,279',
            },
            {
                title: 'Current Status Tier',
                content: 'Gold',
                extraContent: '(25% Bonus Tokens)',
            },
            {
                title: 'Token to Reach Next Tier',
                content: '5,000',
                extraContent: '(25% Bonus Tokens)',
            },
        ],
    },
    {
        name: 'Canvas Rewards',
        color: '#42BDB5',
        data: [
            {
                title: 'Time Left in Current Reward Period',
                content: '2D 1H 3M 55S',
            },
            {
                title: 'Current Reward',
                content: '10,000',
            },
            {
                title: 'Reward Total Value',
                content: '$10,000',
            },
        ],
    },
    {
        name: 'Canvas Rewards',
        color: '#7B77E0',
        extraContentColor: '#7B77E0',
        data: [
            {
                title: 'Token Price',
                content: '$10.00',
                extraContent: '+2.57%',
            },
            {
                title: 'No. of Token in Circulation',
                content: '10,000,000',
            },
            {
                title: 'Total Value',
                content: '$100,000,000',
            },
        ],
    },
];

const socialNetworks = [
    {
        name: 'Facebook',
        icon: Facebook,
    },
    {
        name: 'Instagram',
        icon: Instagram,
    },
    {
        name: 'Discord',
        icon: Discord,
    },
    {
        name: 'WhatsApp',
        icon: WhatsApp,
    },
    {
        name: 'Twitter',
        icon: Twitter,
    },
    {
        name: 'LinkedIn',
        icon: LinkedIn,
    },
    {
        name: 'Telegram',
        icon: Telegram,
    },
];


export const OverviewContent = () => {
    const classes = useStyles();
    const [copied, setCopied] = useState(false);
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.headerTitle}>
                    <Typography variant="h3" style={{ marginRight: 17 }}>Wallet - $27,751.00</Typography>
                    <img src={ArrowDown} alt=""/>
                </div>
            </div>
            <div className={classes.content}>
                {
                    overviewInfo.map(item => <div className={classes.contentWrapper}>
                        <Typography variant={"subtitle2"} style={{ marginBottom: 17 }}>{item.name}</Typography>
                        <Grid container spacing={3}>
                            { item.data.map( content => <Grid item sm={6} xs={12} md={4}>
                                <div className={classes.contentItem}>
                                    <div className={classes.contentItemHeader}>
                                        <Typography style={{ color: item.color }}>{content.title}</Typography>
                                    </div>
                                    <div className={classes.contentItemContent}>
                                        <Typography style={{ fontSize: sm ? '1.8rem' : '2.25rem'}}>{content.content}</Typography>
                                        {content.extraContent && <Typography variant="h6"
                                                                             style={{ color: item.extraContentColor }}>
                                            {content.extraContent}
                                        </Typography>}
                                    </div>
                                </div>
                            </Grid>)}
                        </Grid>
                    </div>)
                }
            </div>
            <div className={classes.divider} />
            <div className={classes.socialSection}>
                <div className={classes.referalWrapper}>
                    <div>
                        <Typography style={{ fontSize: '0.875rem' }}>Your Referal Code:</Typography>
                        <Typography style={{ fontSize: sm ? '1rem' : '1.25rem' }}>XYZ-127-ABC-99999</Typography>
                    </div>
                    <div style={{ background: '#EAE6E2', width: 1 }} />
                    <div style={{ display: 'flex' }}>
                        <Tooltip title={copied ? 'Copied' : 'Copy'} placement="top">
                            <IconButton onClick={() => {
                                setCopied(true);
                                navigator.clipboard.writeText("someref");
                            }}>
                                <img src={CopyIcon} alt=""/>
                            </IconButton>
                        </Tooltip>
                        <IconButton>
                            <img style={{ width: 24, height: 24 }} src={EnvelopAirplane} alt=""/>
                        </IconButton>
                    </div>
                </div>
                <div className={classes.socialNetworks}>
                    <Grid container spacing={3}>
                        {socialNetworks.map((item, index) => (
                            <Grid key={index} md={3} xs={12} sm={6}>
                                <div className={classes.socialItemsWrapper}>
                                    <img src={item.icon} style={{ marginRight: 12, width: 20, height: 18, }} alt=""/>
                                    <Typography>{item.name}</Typography>
                                </div>
                        </Grid>))}
                    </Grid>

                </div>
            </div>
        </div>
    );
};
