import { BrowserRouter, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import ContextProviders from "@contexts/ContextProvider";
import { AppLayout } from "./shared/Layouts/AppLayout/AppLayout";

import Home from "@pages/Home/Home";
import { SignIn } from "@pages/SignIn/SignIn";

// for tables
import "element-theme-default";
import { ToastContainer } from "react-toastify";

import { datadogRum } from '@datadog/browser-rum';
import { IntercomProvider } from 'react-use-intercom';
import { Analytics } from '@vercel/analytics/react';

import { version } from './version';
const APP_VERSION = version;

const CANVAS_ENV = process.env.REACT_APP_CANVAS_ENV || '';
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || '';

datadogRum.init({
  applicationId: 'b4bdedf8-3b58-4dd8-8f43-39341e3f7cca',
  clientToken: 'pubf2bbbcbc1589e7824b78982eb2094e9b',
  site: 'datadoghq.eu',
  service: 'prime-frontend',
  version: APP_VERSION,
  env: CANVAS_ENV,
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
});
datadogRum.startSessionReplayRecording();

function App() {
  return (
    <ContextProviders>
      <ToastContainer />
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <Analytics />
        <BrowserRouter>
          <Route path={"/signin"} component={SignIn} />
          <Route path={"/d/*"} component={AppLayout} />
          <Route path={"/"} exact component={Home} />
        </BrowserRouter>
      </IntercomProvider>
    </ContextProviders>
  );
}
export default App;
