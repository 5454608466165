import useSWR from 'swr';
import api from "@services/api";

const request = api("wallets");
const apiWalletBalance = (_, walletId: string) => request(`${walletId}/balances`);

let retryTime = 5000;

export default function useApiTradeBalance(walletId: string) {
  const { data, error, isValidating, mutate } = useSWR(walletId ? ['apiWalletBalance', walletId] : null, apiWalletBalance, {
    refreshInterval: 0,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
      console.log('apiWalletBalance', error);
      if (retryCount >= 5) return;
      setTimeout(() => {
        revalidate({ retryCount });
      }, retryTime);
    },
  });
  return {
    data: data,
    error: error,
    loading: isValidating,
    mutate: mutate    
  }
}
