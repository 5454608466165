import { useEffect, useState, useCallback } from 'react';
import api from '@services/api';

const request = api('wallets');

const getData: any = async (isAuthorized: boolean, walletId: string, parsedSelectedWallets: string) => {
  if (walletId) {
    // public wallet address
    return await request(`${walletId}/balances/period`);
  } else if (isAuthorized) {
    // logged in user with selection of wallet addresses
    return await request(`balances/period${parsedSelectedWallets}`);
  }
};

export default function useGetBalancePeriod(isAuthorized: boolean, walletId: string | null, parsedSelectedWallets: string | null, timestamp: number) {
  // console.log('useGetBalancePeriod', isAuthorized, walletId, parsedSelectedWallets, timestamp);
  const [prevTimestamp, setPrevTimestamp] = useState(0);
  const [data, setData] = useState();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const doLoad = useCallback(() => {
    if (!isLoading) {
      setIsLoading(true);
      setMessage('Downloading Range ...');
      getData(isAuthorized, walletId, parsedSelectedWallets).then((newData: any) => {
        setMessage('Report Range downloaded successfully.');
        // console.log('period', newData);
        setData(newData);
      }).catch((e: any) => {
        console.log(e);
        setMessage(e.message());
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [isAuthorized, walletId, parsedSelectedWallets, isLoading]);

  useEffect(() => {
    if (timestamp !== prevTimestamp) {
      doLoad();
    }
  }, [doLoad, timestamp, prevTimestamp]);

  useEffect(() => {
    setPrevTimestamp(timestamp);
  }, [timestamp]);

  return {
    data: data as any,
    isLoading: isLoading,
    message: message,
  };
}
