import React, { useState } from "react";
import PaperLayout from "../../../shared/Layouts/PaperLayout/PaperLayout";
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { ComingSoonPage } from "../../../shared/ComingSoonPage/ComingSoonPage";


const useStyles = makeStyles(() => ({
    tabsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginBottom: 32,

        '&:after': {
            content: '""',
            background: '#F4F2F0',
            height: 1,
            bottom: 0.5,
            width: '100%',
            position: 'absolute',
            left: 0,
        },
    },
    tabs: {
        width: '100%',
    },
    tabItem: {
        fontSize: '1.125rem',
        fontWeight: 500,
        zIndex: 10,
        color: 'rgba(128, 129, 145, 0.8)',
        textTransform: 'capitalize',
    },
    selectedTab: {
        color: 'rgba(51, 48, 66, 1)',
    },
    flexContainer: {
        justifyContent: 'space-between',
    },
}));


export const RewardsTabInfo = ({ data }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <PaperLayout>
            <ComingSoonPage isBlur={true} />
            <div className={classes.tabsWrapper}>
                <Tabs value={value}
                      variant="scrollable"
                      scrollButtons="on"
                      classes={{ flexContainer: classes.flexContainer}}
                      className={classes.tabs}
                      TabIndicatorProps={{ style: { background:'#F37222', zIndex: 11 }}}
                      onChange={handleChange}>
                    {data.map(item => <Tab classes={{ selected: classes.selectedTab }} className={classes.tabItem} label={item.name} />)}
                </Tabs>
            </div>
            <div>{data[value].content}</div>
        </PaperLayout>
    );
};
