import { useMemo, useState } from "react";
import clsx from "clsx";

import AllAssetsIcon from "@assets/images/all-assets-icon.svg";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { DashboardTable } from "@pages/Dashboard/components/Tables/DashboardTable";
// import { EmptyDashboard } from "@pages/Dashboard/EmptyDashboard";
import IconsCollectionBadge from "../../../../../shared/UI/IconsCollectionBadge";
import { getSortData } from "../../../../../utils/sortingData";
import { utilsService } from "@services/utils.service";

export const FiatTable = ({ sortTables, isPhone, ratioWidthTablet, renderHeaderTablet, fiat, filters, calculateSum }) => {
  const [tableError, setTableError] = useState(false);
  const { formatNumberByCurrency } = useCurrencyStore();

  const total: number = useMemo(() => filters ? calculateSum(fiat) : fiat?.totalBalance?.toFixed(2), [filters, fiat, calculateSum]);

  const currentColumnsFiat = useMemo(() => {
    if (!isPhone) {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          width: 388 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge-desktop')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 250 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 250 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <>
                {!value.isEndRow && (
                  <span className='table-text'>
                    {formatNumberByCurrency(value.price, { toFixed: 4 })}
                  </span>
                )}
              </>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    } else {
      return [
        {
          label: 'Assets',
          prop: 'assets',
          fixed: 'left',
          width: 400 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx('icons-badge', value.isEndRow && 'end-row-icons-badge')}>
                <IconsCollectionBadge mainIcon={value.isEndRow ? AllAssetsIcon : value.mainIcon} />
                <span>{value.label}</span>
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Value',
          prop: 'value',
          width: 350 * ratioWidthTablet,
          render: (value: any) => {
            return (
              <div className={clsx({ resultSum: value.isEndRow })}>
                {formatNumberByCurrency(value.value)}
              </div>
            );
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Balance',
          prop: 'balance',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <span className='table-text'>{value.balance}</span>;
          },
          renderHeader: renderHeaderTablet,
        },
        {
          label: 'Price',
          prop: 'price',
          width: 300 * ratioWidthTablet,
          render: (value: any) => {
            return <>{!value.isEndRow && <span className='table-text'>{formatNumberByCurrency(value.price, { toFixed: 4 })}</span>}</>;
          },
          renderHeader: renderHeaderTablet,
        },
      ];
    }
  }, [isPhone, ratioWidthTablet, renderHeaderTablet, formatNumberByCurrency]);


  const sortDataFiatArray = useMemo(() => {
    try {
      if (fiat) {
        const dataFiatArray = fiat?.networks.map((item: any) => {
          const dataFiat = item.data.sort((a, b) => b.balanceUSD - a.balanceUSD).reduce(
            (acc: any, value: any) => {
              return {
                dataArray: [
                  ...acc.dataArray,
                  {
                    label: value.label,
                    mainIcon: value.imageUrl,
                    balance: (value.balance ? value.balance : 0).toFixed(2),
                    price: value.price.toFixed(4),
                    value: value.balanceUSD.toFixed(2),
                  },
                ],
                sum: acc.sum + value.balanceUSD,
              };
            },
            {
              dataArray: [],
              sum: 0,
            },
          );

          const sortDataFiat = getSortData(
            dataFiat?.dataArray,
            sortTables?.[`fiat${item.name}`],
          );

          return [
            ...sortDataFiat,
            {
              label: `Total Assets: ${sortDataFiat.length}`,
              mainIcon: '',
              protocolIcon: '',
              balance: '',
              value: dataFiat.sum.toFixed(2),
              isEndRow: true,
            },
          ];
        });
        return dataFiatArray;
      }
    } catch (e) {
      console.log('----fiatError');
      console.log(e);
      setTableError(true);
    }
  }, [fiat, sortTables]);

  const fiatCategoryName = useMemo(() => {
    return fiat?.networks.map((item: any) => utilsService.getNetworkName(item?.name));
  }, [fiat?.networks]);


  if (tableError) return <></>;

  if (fiat && Number(total) !== 0) {
    return <DashboardTable
      dataArray={sortDataFiatArray}
      columns={currentColumnsFiat}
      categoryNameArray={fiatCategoryName}
      title='Fiat'
      className='dashboard-table--fiat'
      titleValue={total}
    />;
  } else {
    return <></>
  }
};
