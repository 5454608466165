
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import debounce from 'lodash.debounce';

import { utilsService } from "@services/utils.service";
import { requestQuote } from "@services/trade.service";
import useAuthStore from "@hooks/globalStores/useAuthStore";
import useApiTradeWallets from "@hooks/fetchers/Trade/useApiTradeWallets";
import useApiTradeBalance from "@hooks/fetchers/Trade/useApiTradeBalance";
import useApiTradeDestinations from "@hooks/fetchers/Trade/useApiTradeDestinations";

import { TradeSelect } from "./TradeSelect";
import { TradeInput } from "./TradeInput";
import { TradeAutoComplete } from "./TradeAutoComplete";

import CanvasButton from "@shared/UI/CanvasButton";
import CanvasLogo from "@assets/images/canvas-logo.svg";
import CanvasLogoNavy from "@assets/images/canvas-logo-navy.png";
import Ethereum from '@assets/networks/ethereum.png';
import MaticToken from '@assets/networks/matic.png';
import CoinIcon from "@assets/images/coin-icon.png";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Typography, FormHelperText } from "@material-ui/core";

const ALWAYS_APPROVED = [
  { network: 'ethereum', address: '0x0000000000000000000000000000000000000000' },
  { network: 'polygon', address: '0x0000000000000000000000000000000000001010' },
];

const TRANSLATE_ADDRESS = {
  'ethereum': {
    '0x0000000000000000000000000000000000000000': '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  },
  'polygon': {
    '0x0000000000000000000000000000000000001010': '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  }
};

const translateAddress = (network: string, address: string) => {
  const addr = TRANSLATE_ADDRESS[network][address];
  if (addr) {
    return addr;
  }
  return address;
}

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 525,
      width: 525,
    },
    width: '100%',
  },
  bottomDescription: {
    height: 71,
    border: '1px solid #E4E4E4',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    borderRadius: 8,
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 20,
  },
  bottomDescriptionPercentage: {
    display: 'flex',
    background: '#F4F2F0',
    width: '54px',
    height: 45,
    padding: '0 8px',
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  slippageTextField: {
    width: '100%',

    '& > div::before': {
      display: 'none',
    },

    '& input[type=number]': {
      display: 'textfield',
    },

    '& input': {
      '&::-webkit-outer-spin-button': {
        display: 'none',
        margin: 0,
      },

      '&::-webkit-inner-spin-button': {
        display: 'none',
        margin: 0,
      },

      '&::placeholder': {
        color: 'rgb(112, 119, 127)',
        fontSize: '0.875rem',
      },
      color: '#808191',
      fontSize: '0.875rem',
    },

    '& > div::after': {
      display: 'none',
    }
  },
}));

interface IFormValues {
  walletAddress: string;
  selectedNetwork: string;
  selectedToken: string;
  selectedTokenData;
  enteredAmount: number;
  destinationType: string;
  destinationTarget: string;
  destinationTargetData: any;
  slippage: number;
}

export const findTokenDataByNetwork = (data: any, networkName: string) => {
  const network =
    data &&
    data.categories &&
    data.categories.find(({ name }: { name: string }) => name === 'wallet');
  let tokenData = [];

  if (network?.networks.length) {
    let found = network.networks.find((item) => item.name === networkName);
    if (found?.data?.length) {
      tokenData = found.data;
    }
  }
  return tokenData;
};

export const TradeQuote = ({ closeModal, setMode, quote, setQuote }) => {
  const { currentUser, isAuthorized } = useAuthStore();

  const { setValue, watch, getValues } = useForm<IFormValues>({
    defaultValues: {
      walletAddress: quote?.form?.walletAddress || '',
      selectedNetwork: quote?.form?.selectedNetwork || '',
      selectedToken: quote?.form?.selectedToken || '',
      selectedTokenData: quote?.form?.selectedTokenData,
      enteredAmount: quote?.form?.enteredAmount || 0,
      destinationType: quote?.form?.destinationType || '',
      destinationTarget: quote?.form?.destinationTarget || '',
      destinationTargetData: quote?.form?.destinationTargetData,
      slippage: quote?.form?.slippage || 3,
    }
  });
  const formData = watch();
  const walletList = useApiTradeWallets();
  const walletBalance = useApiTradeBalance(formData.walletAddress);
  const tokenList = useMemo(() => (formData.selectedNetwork ? findTokenDataByNetwork(walletBalance.data, formData.selectedNetwork) : []), [formData, walletBalance]);
  const inSufficientAmount = useMemo(() => (formData.selectedTokenData && formData.enteredAmount > 0 && formData.enteredAmount > formData.selectedTokenData?.balance), [formData]);
  const isAlwaysApprove = useMemo(() => ALWAYS_APPROVED.find(x => (x.network === formData?.selectedTokenData?.network) && (x.address === formData?.selectedTokenData?.address)), [formData]);
  const [emptyBalance, setEmptyBalance] = useState(false);
  const [quoteLoading, setQuoteLoading] = useState(false);

  const destinationDetails = useApiTradeDestinations();
  const destinationList = useMemo(() => destinationDetails?.data ? Object.keys(destinationDetails.data) : [], [destinationDetails]);
  const destinationTarget = useMemo(() =>
  ((formData.destinationType && formData.selectedNetwork && destinationDetails?.data && destinationDetails.data[formData.destinationType] && destinationDetails.data[formData.destinationType][formData.selectedNetwork])
    ? destinationDetails.data[formData.destinationType][formData.selectedNetwork]
    : [{
      label: 'No destination found',
      name: "",
      address: "Please select another type or network",
      noSlice: true,
    }])
    , [destinationDetails, formData]);

  const debouncedAmount = useMemo(() => {
    return debounce((e) => setValue("enteredAmount", +e.target.value), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => debouncedAmount.cancel();
  });

  const onSubmit = () => {
    const allFormDataValues = getValues();
    if (allFormDataValues.enteredAmount <= 0) {
      setEmptyBalance(true);
      return;
    } else {
      setEmptyBalance(false);
    }

    const quoteData = {
      walletAddress: allFormDataValues.walletAddress,
      network: allFormDataValues.selectedTokenData.network,
      fromAddress: translateAddress(allFormDataValues.selectedTokenData.network, allFormDataValues.selectedTokenData.address),
      fromLabel: allFormDataValues.selectedTokenData.title,
      fromSymbol: allFormDataValues.selectedTokenData.symbol,
      fromIcon: allFormDataValues.selectedTokenData.mainIcon,
      fromAmount: (allFormDataValues.enteredAmount * Math.pow(10, allFormDataValues.selectedTokenData.decimals || 0)).toString(),
      fromDecimals: (allFormDataValues.selectedTokenData.decimals || 0),
      toAddress: translateAddress(allFormDataValues.selectedTokenData.network, allFormDataValues.destinationTargetData.address),
      toLabel: allFormDataValues.destinationTargetData.title,
      toSymbol: allFormDataValues.destinationTargetData.symbol,
      toIcon: allFormDataValues.destinationTargetData.mainIcon,
      toDecimals: (allFormDataValues.destinationTargetData.decimals || 0),
      slippage: allFormDataValues.slippage / 100
    };
    console.log('quoteData', quoteData);
    setQuoteLoading(true);
    requestQuote(quoteData).then(res => {
      setQuoteLoading(false);
      if (res.status) {
        utilsService.notify({ message: 'Trade Quote received', status: 'success' });
        setQuote({ quoteId: res.data.quoteId, form: formData, request: quoteData, response: res.data.response });
        if (isAlwaysApprove) {
          setMode("execute");
        } else {
          setMode("approve");
        }
      } else {
        utilsService.notify({ message: res.message, status: 'error' });
      }
    }).catch((e) => {
      console.log(e);
      setQuoteLoading(false);
      utilsService.notify({ message: e.message });
    });
  };

  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TradeSelect value={formData.walletAddress}
          title={"Wallet"}
          disabled={!currentUser || !isAuthorized}
          loading={currentUser && walletList.isLoading}
          error={!!walletList.error}
          onChange={(e) => {
            setValue("walletAddress", e.value);
            // reset dependent values
            setValue("selectedNetwork", "");
            setValue("selectedToken", "");
            setValue("selectedTokenData", null);
            setValue("enteredAmount", 0);
            setValue("destinationType", "");
            setValue("destinationTarget", "");
          }}
          data={[
            { title: walletList?.data?.length ? 'Select wallet' : 'No wallets found', value: "", placeholder: true },
            ...walletList?.data?.map((item: any) =>
            ({
              title: item.title ? item.title : 'Canvas',
              value: item.address,
              mainIcon: CanvasLogo,
              extraTitle: utilsService.shrinkWalletAddress(item.address),
            }))
          ]}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <TradeSelect value={formData.selectedNetwork}
          title={"Network"}
          disabled={!formData.walletAddress}
          loading={walletBalance.loading && !!formData.walletAddress}
          error={!!walletBalance.error}
          onChange={(e) => {
            setValue("selectedNetwork", e.value);
            // reset dependent values
            setValue("selectedToken", "");
            setValue("selectedTokenData", null);
            setValue("enteredAmount", 0);
            setValue("destinationType", "");
            setValue("destinationTarget", "");
          }}
          data={[
            { title: 'Select network', value: "", placeholder: true },
            { title: 'CANVAS Connect (coming soon)', value: "", mainIcon: CanvasLogoNavy, placeholder: true },
            { title: 'Ethereum', value: "ethereum", mainIcon: Ethereum },
            { title: 'Polygon', value: "polygon", mainIcon: MaticToken },
          ]}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <TradeSelect value={formData.selectedToken}
          disabled={!formData.selectedNetwork || !formData.walletAddress}
          loading={walletBalance.loading && !!formData.walletAddress}
          error={!!walletBalance.error}
          onChange={(e) => {
            setValue("selectedToken", e.value);
            setValue("selectedTokenData", e);
            // reset dependent values
            setValue("enteredAmount", 0);
            setValue("destinationType", "");
            setValue("destinationTarget", "");
          }}
          title={"Token"}
          data={[
            { title: tokenList.length ? 'Select token' : 'No tokens found', value: "", placeholder: true },
            ...tokenList.map((item: any) =>
            ({
              ...item,
              title: item.label,
              value: item.label.toLowerCase(),
              symbol: item.symbol,
              address: item.address,
              mainIcon: item.imageUrl ? item.imageUrl : CoinIcon,
            }))
          ]} />
      </Grid>
      <Grid item lg={12} sm={12} xs={12}>
        <TradeInput value={formData.enteredAmount.toString()}
          placeholder={"Enter the amount"}
          onChange={debouncedAmount}
          onRefresh={() => walletBalance.mutate()}
          isRefreshing={walletBalance.loading}          
          label={"Amount"}
          error={inSufficientAmount || emptyBalance}
          errorText={emptyBalance ? "Please enter correct balance" : "Insufficient amount"}
          tokenData={formData.selectedTokenData}
          disabled={!formData.selectedTokenData} />
      </Grid>
      <Grid item lg={12} sm={12} xs={12}>
        <TradeSelect value={formData.destinationType}
          title={"Type"}
          disabled={!formData.selectedTokenData || !formData.selectedNetwork || !formData.walletAddress}
          loading={destinationDetails.isLoading}
          error={!!destinationDetails.error}
          onChange={(e) => {
            setValue("destinationType", e.value);
            // reset dependent fields
            setValue("destinationTarget", "");

          }}
          data={[
            { title: 'Select type', value: "", placeholder: true },
            ...destinationList.map((item: string) =>
            ({
              title: item,
              value: item.toLowerCase(),
            }))
          ]}
        />
      </Grid>
      <Grid item lg={12} sm={12} xs={12}>
        <TradeAutoComplete placeholder={'Select Destination'}
          disabled={!formData.selectedTokenData || !formData.selectedNetwork || !formData.walletAddress || !formData.destinationType}
          value={formData.destinationTarget}
          title={"To"}
          onChange={(e) => {
            setValue("destinationTarget", e.value);
            setValue("destinationTargetData", e);
          }}
          data={[
            ...destinationTarget?.map(item => ({
              title: item.name,
              value: item.address,
              symbol: item.label,
              address: item.address,
              decimals: item.decimals,
              extraTitle: item.noSlice ? item.address : utilsService.shrinkWalletAddress(item.address),
              mainIcon: item.assetImage,
              protocolIcon: item.protocolImage,
            }))
          ]}
        />
      </Grid>
      <Grid item lg={12} sm={12} xs={12}>
        <div className={classes.bottomDescription}>
          <div style={{ display: 'flex' }}>
            <Typography style={{ fontSize: '0.8125rem', fontWeight: 500, marginRight: 6 }}>Slippage Tolerance</Typography>
          </div>
          <div className={classes.bottomDescriptionPercentage}>
            <TextField className={classes.slippageTextField}
              type={"number"}
              InputProps={{ inputProps: { min: 0, max: 100 } }}
              defaultValue={formData.slippage}
              onChange={e => setValue("slippage", +e.target.value)} />
            %
          </div>
        </div>
        <FormHelperText>Slippage refers to the discrepancy between the expected price of a trade and the price at which the trade is executed. <i>If the slippage exceeds this amount</i>, the trade will fail.</FormHelperText>
      </Grid>
      <Grid item xs={6}>
        <CanvasButton variant={"outlined"} size={"large"} fullWidth={true} onClick={() => closeModal()}>Cancel</CanvasButton>
      </Grid>
      <Grid item xs={6}>
        <CanvasButton
          size={"large"}
          fullWidth={true}
          disableElevation
          loading={quoteLoading}
          disabled={!formData.selectedTokenData
            || !formData.selectedNetwork
            || !formData.walletAddress
            || !formData.destinationType
            || !formData.destinationTarget
            || inSufficientAmount}
          onClick={() => onSubmit()}
        >Get Quote</CanvasButton>
      </Grid>
    </Grid>
  );
};
