import { useState, useEffect, useCallback } from 'react';
import api from '@services/api';
import { format } from 'date-fns';

const request = api('wallets');

const getData: any = async (isAuthorized: boolean, walletId: string, parsedSelectedWallets: string) => {
  if (walletId) {
    // public wallet address
    return await request(`${walletId}/balances`);
  } else if (isAuthorized) {
    // logged in user with selection of wallet addresses
    return await request(`balances${parsedSelectedWallets}`);
  }
};

const postRefresh: any = async (isAuthorized: boolean, walletId: string, parsedSelectedWallets: string, refreshType: string) => {
  if (walletId) {
    // public wallet address
    return await request(`${walletId}/balances/refresh/${refreshType}`, 'post');
  } else if (isAuthorized) {
    // logged in user with selection of wallet addresses
    return await request(`balances/refresh/${refreshType}${parsedSelectedWallets}`, 'post');
  }
};

const getRefresh: any = async (refreshId: string) => {
  return await request(`balances/refresh/${refreshId}`);
};

export default function useGetDashboardDetails(isAuthorized: boolean, walletId: string | null, parsedSelectedWallets: string | null, timestamp: number) {
  const [prevRefreshTimestamp, setPrevRefreshTimestamp] = useState(timestamp);
  const [refreshTimestamp, setRefreshTimestamp] = useState(prevRefreshTimestamp);
  const [prevQueryTimestamp, setPrevQueryTimestamp] = useState(new Date().getTime());
  const [queryTimestamp, setQueryTimestamp] = useState(prevQueryTimestamp);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [message, setMessage] = useState('');
  const [partialTotal, setPartialTotal] = useState(0);

  useEffect(() => {
    setIsFirstLoad(true);
    setQueryTimestamp(new Date().getTime());
  }, [isAuthorized, walletId, parsedSelectedWallets]);

  const queryData = useCallback(() => {
    getData(isAuthorized, walletId, parsedSelectedWallets).then(newData => {
      setIsLoading(false);
      if (newData) {
        console.log(newData);
        setUserData(newData);
        setPartialTotal(newData.totalBalanceUSD);
        if (newData?.status === 'complete') {
          setMessage('Wallet details successfully updated at: ' + format(new Date(newData.lastUpdate), `'&nbsp;<span>'dd-MMM-yyyy, H:mm a'</span>&nbsp;' z`));
          setIsFirstLoad(false);
        } else if (newData?.status === 'not-found') {
          if (walletId) {
            setMessage('No data found for this wallet. Please try again...');
          }
          else {
            setMessage('No data found for these wallets. Please try again...');
          }
          if (isFirstLoad) {
            // if not found - on first try - do a refresh
            setRefreshTimestamp(new Date().getTime());
            setIsFirstLoad(false);
          }
        } else {
          setMessage('Error downloading wallets: No data.');
        }
      } else {
        setMessage('Error downloading wallets: No data.');
      }
    }).catch((error: any) => {
      setIsLoading(false);
      console.log('getData', error);
      if (error.response) {
        setMessage('Error: <span>' + error.response.data.message + '</span>. Please try again...');
      } else {
        setMessage('<span>' + error + '</span>');
      }
    });
  }, [isAuthorized, walletId, parsedSelectedWallets, isFirstLoad]);

  const queryRefresh = useCallback(() => {
    postRefresh(isAuthorized, walletId, parsedSelectedWallets, 'all').then((refresh) => {
      // console.log('postRefresh', walletId, parsedSelectedWallets, refresh);
      if (refresh?.ulid) {
        let pollStart = new Date().getTime();
        const intervalId = setInterval(() => {
          const pollNow = new Date().getTime();
          const pollSecs = Math.floor((pollNow - pollStart) / 1000);
          setMessage(`Waiting for wallet details ... ${pollSecs} secs`);
          if (pollSecs > 180) {
            setMessage(`Giving up waiting for wallet details. Please try again ...`);
            clearInterval(intervalId);
            setIsRefreshing(false);
          } else if (pollSecs % 3 === 0) {
            getRefresh(refresh.ulid).then(poll => {
              console.log('getRefresh', refresh.ulid, poll);
              setPartialTotal(poll.reduce((acc, item) => acc += item.total, 0));
              if (poll.every(x => x.state === 'COMPLETED' || x.state === 'FAILED')) {
                console.log('Refresh All:', pollSecs, 'secs');
                clearInterval(intervalId);
                setIsRefreshing(false);
                setQueryTimestamp(new Date().getTime());
              }
            }).catch((error: any) => {
              clearInterval(intervalId);
              setIsRefreshing(false);
              if (error.response) {
                setMessage('Error: <span>' + error.response.data.message + '</span>. Please try again...');
              } else {
                setMessage('<span>' + error + '</span>');
              }
            });
          }
        }, 1000);
      } else {
        setIsRefreshing(false);
        setMessage('Error: <span>No Refresh Id</span>. Please try again...');
      }
    }).catch((error: any) => {
      setIsRefreshing(false);
      console.log('postRefresh', error);
      if (error.response) {
        setMessage('Error: <span>' + error.response.data.message + '</span>. Please try again...');
      } else {
        setMessage('<span>' + error + '</span>');
      }
    });
  }, [isAuthorized, walletId, parsedSelectedWallets]);

  useEffect(() => {
    setRefreshTimestamp(timestamp);
  }, [timestamp]);

  useEffect(() => {
    if (prevRefreshTimestamp !== refreshTimestamp) {
      setPrevRefreshTimestamp(refreshTimestamp);
      setIsRefreshing(true);
      setPartialTotal(0);
      setMessage('Refreshing wallet details ....');
      setTimeout(() => { // UI responsiveness
        queryRefresh();
      }, 200);
    }
  }, [prevRefreshTimestamp, refreshTimestamp, queryRefresh]);

  useEffect(() => {
    if (prevQueryTimestamp !== queryTimestamp) {
      setPrevQueryTimestamp(queryTimestamp);
      setIsLoading(true);
      setMessage('Downloading wallet details ....');
      setTimeout(() => { // UI responsiveness
        queryData();
      }, 200);
    }
  }, [prevQueryTimestamp, queryTimestamp, queryData]);

  return {
    userData: userData,
    totalBalanceUSD: partialTotal,
    isLoading: isLoading || isRefreshing,
    message: message,
  }
}
