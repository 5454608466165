import { useEffect, useState } from "react";
import {
  Drawer,
  Hidden, IconButton,
  makeStyles, useMediaQuery
} from "@material-ui/core";
import { Theme, useTheme } from "@material-ui/core/styles";
import { Menu } from "./Menu/Menu";

import NavBarToggleIcon from "@assets/images/navbar-toggle-icon.svg";
import clsx from "clsx";

interface IDrawerLayout {
  drawerWidth: number;
}

const useStyles = makeStyles((theme: Theme) =>
({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    position: 'relative',
    overflow: 'visible',
    width: ({ drawerWidth }: IDrawerLayout) => drawerWidth,
  },
  toggleBtn: {
    position: 'absolute',
    top: 75,
    right: -28,
    [theme.breakpoints.down('xs')]: {
      top: 65,
    },
  },
  drawerOpen: {
    position: 'relative',
    overflow: 'visible',
    height: '100%',
    width: ({ drawerWidth }: IDrawerLayout) => drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
  },
  drawerClose: {
    height: '100%',
    position: 'relative',
    overflow: 'visible',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
    borderRight: 'none',
    maxWidth: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
      maxWidth: theme.spacing(9) + 1,
    },
  },
  rotate: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
})
);

interface Props extends IDrawerLayout {
  window?: () => Window;
  setMobileOpen: (value: boolean) => void;
  mobileOpen: boolean;

}

export function DrawerLayout({ window, drawerWidth, setMobileOpen, mobileOpen }: Props) {
  const classes = useStyles({ drawerWidth });
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const container = window !== undefined ? () => window().document.body : undefined;
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));


  useEffect(() => {
    if (isTablet) setOpen(true);
    if (!isTablet && isDesktop) setOpen(true);
  }, [isTablet, isDesktop])

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="js">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={() => setMobileOpen(!mobileOpen)}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <IconButton className={clsx(classes.toggleBtn, classes.rotate)}
            style={{ transform: mobileOpen ? 'rotate(0)' : 'rotate(180deg)' }}
            onClick={() => setMobileOpen(!mobileOpen)}>
            <img src={NavBarToggleIcon} alt="toggle" />
          </IconButton>
          <Menu isMobile={true} onClose={() => setMobileOpen(false)} isOpen={mobileOpen} />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="js">
        <Drawer
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          variant="permanent"
        >
          <IconButton className={clsx(classes.toggleBtn, classes.rotate)}
            style={{ transform: open ? 'rotate(0deg)' : 'rotate(180deg)' }}
            onClick={() => setOpen(!open)}>
            <img src={NavBarToggleIcon} alt="toggle" />
          </IconButton>
          <Menu isOpen={open} />
        </Drawer>
      </Hidden>
    </nav>
  );
}
