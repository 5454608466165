import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Paper } from "@material-ui/core";

interface ITotalBalance {
  userData?: any;
}

const useStyles = makeStyles((theme: Theme) =>
({
  root: {
    padding: theme.spacing(3),
    margin: theme.spacing(3, 0),
    borderRadius: theme.spacing(2),
  },
}));

export const GrandTotalBalance = ({ userData }: ITotalBalance) => {
  const { formatNumberByCurrency } = useCurrencyStore();
  const classes = useStyles();
  return (
    <>
      {userData && (
        <Paper className={classes.root}>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item>
              <Typography variant={"h3"} color="primary">
                Grand Total:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={"h3"} color="secondary">
                {formatNumberByCurrency(userData.totalBalanceUSD)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};
