import { useState, useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import useAuthStore from "@hooks/globalStores/useAuthStore";
import PrivateRoute from "@shared/PrivateRoute/PrivateRoute";

import Dashboard from "@pages/Dashboard/Dashboard";
import { NFTs } from "@pages/NFTs/NFTs";
import { Transactions } from "@pages/Transactions/Transactions";
import { Analytics } from "@pages/Analytics/Analytics";
import { Reports } from "@pages/Reports/Reports";
import { Snapshots } from "@pages/Snapshots/Snapshots";
import { Research } from "@pages/Research/Research";
// import { Exchange } from "@pages/Exchange/Exchange";
import { Trade } from "@pages/Trade/Trade";
import { Borrow } from "@pages/Borrow/Borrow";
import { Rewards } from "@pages/Rewards/Rewards";
import { Settings } from "@pages/Settings/Settings";

import { DrawerLayout } from "./Drawer/Drawer";
import { AppBarLayout } from "./AppBar/AppBar";

import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

const drawerWidth = 264;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
  },
  content: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: 45,
    },
    height: "100vh",
    minHeight: "100vh",
    background: "#F4F2F0",
    display: "flex",
    overflowY: "scroll",
    flexGrow: 1,
    scrollbarColor: "rgba(128, 129, 145, 0.5) #fff",
    scrollbarWidth: "thin",
  },
}));

export function AppLayout() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const { isAuthorized, currentUser } = useAuthStore();
  const { boot, update } = useIntercom();

  useEffect(() => {
    boot();
    if (isAuthorized) {
      update({ name: currentUser?.name, email: currentUser?.email });
    } else {
      update();
    }
  }, [boot, update, isAuthorized, currentUser]);

  return (
    <div className={classes.root}>
      <AppBarLayout mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} drawerWidth={drawerWidth} />
      <DrawerLayout mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} drawerWidth={drawerWidth} />
      <main className={classes.content}>
        <Switch>
          <PrivateRoute path={"/d/overview"} component={Dashboard} />
          <PrivateRoute path={"/d/nfts"} component={NFTs} />
          <PrivateRoute path={"/d/transactions"} component={Transactions} />
          <PrivateRoute path={"/d/analytics"} component={Analytics} />
          <PrivateRoute path={"/d/reports"} component={Reports} />
          <PrivateRoute path={"/d/snapshots"} component={Snapshots} />
          <PrivateRoute path={"/d/research"} component={Research} />
          <PrivateRoute path={"/d/trade"} component={Trade} />
          <PrivateRoute path={"/d/borrow"} component={Borrow} />
          <PrivateRoute path={"/d/rewards"} component={Rewards} />
          <PrivateRoute path={"/d/settings"} component={Settings} />
          <Redirect to="/" />
        </Switch>
      </main>
    </div>
  );
}
