import api from "./api";

const request = api('wallets');

export const getBalance: any = async (dateFrom: string, isAuthorized: boolean, walletId: string, parsedSelectedWallets: string) => {
  console.log('getBalance', dateFrom, isAuthorized, walletId, parsedSelectedWallets);
  if (walletId) {
    // public wallet address
    return await request(`${walletId}/balances?before=${dateFrom}`);
  } else if (isAuthorized) {
    // logged in user with selection of wallet addresses
    return await request(`balances?before=${dateFrom}${parsedSelectedWallets?.replace('?', '&')}`);
  }
};

