/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";

export function useMediaQuery(query: string): boolean {
  if (
    typeof window === `undefined` ||
    typeof window.matchMedia === `undefined`
  ) {
    return false;
  }

  const mediaQuery = window.matchMedia(query);
  const [match, setMatch] = useState(mediaQuery.matches);

  useEffect(() => {
    const handleMatch = () => {
      setMatch(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleMatch);
    return () => mediaQuery.removeEventListener("change", handleMatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return match;
}
