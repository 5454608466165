import ReactDOM from "react-dom";
import App from "./App";
import { CssBaseline, StylesProvider, ThemeProvider } from "@material-ui/core";
import { createGenerateId } from 'react-jss'

import canvasTheme from "./theme/canvasTheme";
import './fonts/CeraPro/CeraPro.css';

const generateClassName = createGenerateId()

ReactDOM.render(
    <ThemeProvider theme={canvasTheme}>
      <StylesProvider generateClassName={generateClassName}>
        <CssBaseline />
        <App />
      </StylesProvider>
    </ThemeProvider>
  ,
  document.getElementById("root")
);
