import React, { useState } from "react";
import clsx from "clsx";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Menu, MenuItem, useMediaQuery } from "@material-ui/core";

import ArrowDown from "@assets/images/arrowDown.svg";
import CanvasButton from "../../../../shared/UI/CanvasButton";

const useStyles = makeStyles((theme) => ({
  root: {},
  btnItem: {
    marginRight: 6,
    height: 30,
    padding: "0 13px",
    background: "#F4F2F0",
    color: "#333042",
    minWidth: 43,
    "&:hover": {
      color: "#fff",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  active: {
    background: "#F37222",
    color: "#fff",
  },
}));

interface ICanvasFilterBtns {
  data: { name: string }[];
  selected: string;
  onSelect: (value: { name: string; from?: string; to?: string }) => void;
  disabled?: boolean;
  isLoading?: boolean;
}

export default function DashboardHistogramButtons({ data, selected, onSelect, disabled, isLoading }: ICanvasFilterBtns) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileResolution = useMediaQuery(theme.breakpoints.down("xs"));
  const isMDResolution = useMediaQuery(theme.breakpoints.down("md"));
  const [btns] = useState(() => {
    if (!data.some((item) => item.name === "filter")) data.push({ name: "filter" });
    if (!data.some((item) => item.name === "other")) data.push({ name: "other" });
    return data;
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const filterInMobileCase = (item) => {
    if (isMobileResolution) {
      return item.name === "filter";
    }

    if (isMDResolution) {
      return item.name === selected || item.name === "other";
    }

    return !["other", "filter"].includes(item.name);
  };

  const filterDropdownInMobileCase = (item) => {
    if (isMobileResolution) {
      return item.name && !["other", "filter"].includes(item.name);
    }

    if (isMDResolution) {
      return item.name !== selected && !["other", "filter"].includes(item.name);
    }

    return !["other", "filter"].includes(item.name);
  };

  return (
    <div>
      <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {data &&
          btns.filter(filterDropdownInMobileCase).map((item, index) => (
            <MenuItem
              key={item.name + index}
              selected={selected === item.name}
              onClick={() => {
                onSelect(item);
                setAnchorEl(null);
              }}
            >
              {item.name}
            </MenuItem>
          ))}
      </Menu>
      {data &&
        btns.filter(filterInMobileCase).map((item, index) => (
          <CanvasButton
            size="small"
            disabled={disabled}
            loading={selected === item.name && isLoading}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              if (["other", "filter"].includes(item.name)) {
                setAnchorEl(event.currentTarget);
              } else {
                onSelect(item);
              }
            }}
            className={clsx(classes.btnItem, selected === item.name && classes.active)}
            key={index + item.name}
          >
            {item.name}
            {["other", "filter"].includes(item.name) && <img style={{ marginLeft: 5 }} src={ArrowDown} alt='arrow' />}
          </CanvasButton>
        ))}
    </div>
  );
}
