import { Button, CircularProgress, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";

import { WelcomeMessage } from "@shared/Layouts/ModuleLayout/UserGreeting/WelcomeMessage";
import ArrowDown from "@assets/images/arrowDown.svg";
import useAuthStore from "@hooks/globalStores/useAuthStore";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import React, { useState } from "react";
import { ChartSwitchWithLabel } from "@pages/Dashboard/components/DashboardDiagram/DashboardPieChart/DashboardPieChart";
import useHideZeros from "@hooks/globalStores/useHideZeros";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: 90,
    border: "1px solid #EAE6E2",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    cursor: "pointer",
  },
  currencySelector: {
    "&>div": {
      background: "transparent",
      height: 30,
      border: "1px solid #EAE6E2",
      borderRadius: 8,
      width: 65,
      minWidth: 65,
      padding: "10px 10px 10px 12px",
      display: "flex",
      alignItems: "center",
      paddingRight: "10px !important",
      color: "rgb(51, 48, 66)",
      "&:focus": {
        background: "transparent",
      },
    },
  },
  greetingRoot: {
    display: "flex",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  rightGreetingWrapper: {
    display: "flex",
  },
  switch: {
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      display: "none",
      margin: 0,
    },
  },
}));

export const CurrencySelector = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { rates, selectedCurrency, setSelectedCurrency, loading } = useCurrencyStore();

  const renderList = () => {
    if (loading) {
      return <CircularProgress size={25} />;
    }

    return (
      <>
        <Typography style={{ marginRight: 8, color: "#333042" }}>{selectedCurrency}</Typography>
        <img style={{ transition: "all .5s" }} src={ArrowDown} alt="" />
      </>
    );
  };

  return (
    <div>
      <Button className={classes.root} onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>
        {renderList()}
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {rates &&
          Object.keys(rates).map((key, index) => (
            <MenuItem
              selected={key === selectedCurrency}
              key={index}
              onClick={() => {
                setSelectedCurrency(key);
                setAnchorEl(null);
              }}
            >
              {key}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export const UserGreeting = () => {
  const { isAuthorized, currentUser } = useAuthStore();
  const { setIsHideZero, isHideZero } = useHideZeros();
  const classes = useStyles();

  return (
    <div style={{ justifyContent: !isAuthorized ? "flex-end" : "space-between" }} className={classes.greetingRoot}>
      {currentUser.isAuthorized && <WelcomeMessage username={currentUser.firstName ? currentUser.firstName : currentUser.name} />}
      <div className={classes.rightGreetingWrapper}>
        <ChartSwitchWithLabel checked={isHideZero} className={classes.switch} onChange={(e) => setIsHideZero(e.target.checked)} description={"Hide zero balance tokens"} />
        <CurrencySelector />
      </div>
    </div>
  );
};
