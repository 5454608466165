import React from 'react';

import {
    Button,
    ButtonProps,
    CircularProgress,
} from "@material-ui/core";


interface BtnProps extends ButtonProps {
    children: React.ReactNode;
    loading?: boolean;
    disabled?: boolean;
}

const loaderSize: {
    [value: string]: number
} = {
    large: 30,
    medium: 20,
    small: 15,
};

export default function CanvasButton({ children, loading, variant, disabled, ...props }: BtnProps) {
    return  <Button variant={variant ? variant : 'contained'}
                    disabled={disabled}
                    color="primary"
                    {...props}
                    onClick={(e) => !loading && props.onClick && props.onClick(e)} >
        {!loading && children}
        {loading &&  <CircularProgress
            color={variant === 'outlined' ? 'primary' : 'secondary'}
            size={loaderSize[props.size ? props.size : 'large']}
        />}
    </Button>
}
