import React, { ReactElement, ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SummaryItem from '@pages/Authentication/components/SummaryInfo/SummaryItem';
import { Grid } from "@material-ui/core";

interface Props {
  title: string;
  data: any;
  filterChild?: ReactNode;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '40px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '40px 0px 100px',
    },
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Cera Pro',
    fontSize: '28px',
    fontStyle: 'medium',
  },
  categoriesBox: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 21.43px)',
    '@media (max-width: 350px)': {
      flexWrap: 'nowrap',
      overflow: 'auto',
    },
  },
}),
);

export default function DashboardSummaryTotal({ title, data }: Props): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.head}>
        <span className={classes.title}>{title}</span>
      </div>
      <Grid container spacing={3} className={classes.categoriesBox}>
        {data.map((item: any, index: number) => (
          <React.Fragment key={index}>
            {item.value > 0 && (
              <Grid item xl={3} lg={4} md={6} xs={12}>
                <SummaryItem item={item} />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </div>
  );
}
