import { ReactElement, useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useCurrencyStore from "@hooks/globalStores/useCurrencyStore";
import { utilsService } from "@services/utils.service";
interface Props {
  network: {
    name: string;
    value: number;
    image?: string;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    maxWidth: "381.38px",
    minWidth: "328px",
    height: "110px",
    background: "#FFFFFF",
    boxShadow: "0 4px 1px -3px #b3b1ad",
    //   boxShadow: '0px 4px 20px -5px rgba(0, 0, 0, 0.1)',
    borderRadius: "20px",
    padding: "27px",
    display: "flex",
    columnGap: "21px",
    alignItems: "center",
    margin: "0 0 30px 21.43px",
    boxSizing: "border-box",
  },
  image: {
    maxWidth: "56px",
    maxHeight: "56px",
  },
  textContainer: {
    fontFamily: "Cera Pro",
    fontStyle: "Medium",
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontSize: "18px",
    fontWeight: 500
  },
  value: {
    fontSize: "22px",
  },
}));

export default function Network({ network }: Props): ReactElement {
  const classes = useStyles();
  const [name, setName] = useState(network.name);
  const { formatNumberByCurrency } = useCurrencyStore();

  useEffect(() => {
    setName(utilsService.getNetworkName(network.name));
  }, [network.name]);

  return (
    <div className={classes.container}>
      <div>
        <img src={network?.image} className={classes.image} alt={`${network.name}`} />
      </div>
      <div className={classes.textContainer}>
        <span className={classes.name}>{name}</span>
        <span className={classes.value}>{formatNumberByCurrency(network.value)}</span>
      </div>
    </div>
  );
}
