import React from "react";

import ModuleLayout from "../../shared/Layouts/ModuleLayout/ModuleLayout";
import { RewardsTabInfo } from "@pages/Rewards/RewardsTabInfo/RewardsTabInfo";
import { OverviewContent } from "@pages/Rewards/RewardsTabInfo/OverviewContent/OverviewContent";

export const Rewards = () => {
    const TAB_DATA = [
        {
            name: 'Overview',
            value: 'overview',
            content: <OverviewContent />,
        },
        {
            name: 'Leaderboard',
            value: 'leaderboard',
            content: <div>leaderboard</div>,
        },
        {
            name: 'History',
            value: 'history',
            content: <div>History</div>,
        },
        {
            name: 'Vote',
            value: 'vote',
            content: <div>Vote</div>,
        },
        {
            name: 'Giveaways',
            value: 'giveaways',
            content: <div>Giveaways</div>,
        },
        {
            name: 'Token',
            value: 'token',
            content: <div>Token</div>,
        },
    ];

    return (
        <ModuleLayout>
                <RewardsTabInfo data={TAB_DATA} />
        </ModuleLayout>
    );
};
