import useSWR from 'swr';
import api from "@services/api";
import useWalletStore from "@hooks/globalStores/useWalletStore";

const request = api("wallets");
const authorizedBalanceFetcher = (_, categoryName: string, queries?: string) =>
    request(`/balances/category/${categoryName.toLowerCase()}${queries ? queries : ''}`);

let retryTime = 5000;

const options = {
    refreshInterval: 0,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 5 times.
        if (retryCount >= 5) return;

        // Retry after n seconds.
        setTimeout(() => {
            revalidate({ retryCount });
            retryTime+= 3000;
        }, retryTime);
    },
};

export default function useGetBalancesByCategory(category: string) {
    const { parsedSelectedWallets } = useWalletStore();

    const passArgs = () => {
        if (category && parsedSelectedWallets) {
            return ['getBalancesByCategory', category, parsedSelectedWallets.replace('?', '&')];
        }

       if (category) {
           return ['getBalancesByCategory', category];
       }

       return null;
    }

    const { data, error, isValidating } = useSWR(passArgs(), authorizedBalanceFetcher, options);


    return {
        userData: data,
        isLoading: isValidating,
        error: error,
    };
}
