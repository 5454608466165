/* eslint-disable react-hooks/rules-of-hooks */
import { Table } from "element-react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Bowser from "bowser";
import clsx from "clsx";

import { CategoryTotalBalance } from "../CategoryTotalBalance";

import "@pages/Dashboard/components/Tables/CategoryTable.scss";
import React, { useMemo } from "react";
import useHideZeros from "@hooks/globalStores/useHideZeros";
import { utilsService } from "@services/utils.service";

interface Props {
  dataArray: any[] | any;
  columns: any;
  categoryNameArray: string[];
  title?: string;
  titleValue: number;
  className?: string;
  style?: React.CSSProperties;
}

export const DashboardTable = ({
  dataArray = [],
  columns,
  categoryNameArray = [],
  title,
  titleValue,
  className,
  style,
}: Props) => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isSafari = browser.getBrowser().name === "Safari";

  const useStyles = makeStyles((theme: Theme) => ({
    tableCanvasContainer: {
      padding: 24,
      borderRadius: "20px",
      marginTop: 25,
      background: "#fff",
      boxShadow: "0 4px 1px -3px #b3b1ad",
    },
  }));

  const classes = useStyles();
  const { isHideZero } = useHideZeros();

  const dataToDisplay = useMemo(() => {
    if (isHideZero) {
      return dataArray.map((tableItem) => {
        let results: any = [];
        tableItem.forEach((tokenItem) => {
          if (Number(tokenItem.balance) > 0) {
            results.push(tokenItem as any);
          }

          if (tokenItem.isEndRow) {
            results.push(tokenItem as any);
          }
        });

        return results;
      });
    }

    return dataArray;
  }, [isHideZero, dataArray]);

  return (
    <div style={style} className={classes.tableCanvasContainer}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        {title && <CategoryTotalBalance title={title} value={titleValue} />}
      </div>
      {dataToDisplay.length > 0 &&
        dataToDisplay.map((data: any, index: number) => {
          return (
            <React.Fragment key={`tableIndashboard${index}`}>
              {categoryNameArray?.[index] && (
                <div className={"network-type"}>{utilsService.capitalizeLetter(categoryNameArray[index])}</div>
              )}
              <div className={clsx("dashboard-table", className)}>
                <Table
                  className={clsx({ "el-table-Safari": isSafari })}
                  key={`table-${5324685425}`}
                  style={{
                    width: "100%",
                    background: "transparent",
                    height: "100%",
                    border: "none",
                    marginBottom: "30px",
                  }}
                  columns={columns.map((item: any) => ({
                    ...item,
                    columnKey: `${title}${categoryNameArray[index]}`.toLowerCase(),
                  }))}
                  data={data}
                />
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
};
