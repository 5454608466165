import React from "react";

import { UserGreeting } from "./UserGreeting/UserGreeting";
import DashboardWallets from "./DashboardWallets/DashboardWallets";
import { TotalBalance } from "./TotalBalance/TotalBalance";
import useAuthStore from "@hooks/globalStores/useAuthStore";
import { makeStyles } from "@material-ui/core";
import { ChartSwitchWithLabel } from "@pages/Dashboard/components/DashboardDiagram/DashboardPieChart/DashboardPieChart";
import useHideZeros from "@hooks/globalStores/useHideZeros";

interface IModuleLayout {
  children: React.ReactNode;
  options?: {
    wallets?: boolean;
    totalBalanceUSD?: number;
    updatedAt?: string;
    isLoading?: boolean;
    userData?: any;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5, 5, 0, 5),
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 2, 0, 2),
    },
  },
  switchWrapper: {
    display: "none",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
  },
}));

const ModuleLayout = ({ children, options }: IModuleLayout) => {
  const { isAuthorized } = useAuthStore();
  const classes = useStyles();
  const { setIsHideZero, isHideZero } = useHideZeros();

  return (
    <div className={classes.root}>
      {isAuthorized && <UserGreeting />}
      <div className={classes.switchWrapper}>
        <ChartSwitchWithLabel
          checked={isHideZero}
          onChange={(e) => setIsHideZero(e.target.checked)}
          description={"Hide zero balance tokens"}
        />
      </div>
      {
        (typeof options?.totalBalanceUSD === 'number') &&
        (typeof options?.isLoading === 'boolean') &&
        <TotalBalance totalBalanceUSD={options.totalBalanceUSD} updatedAt={options?.updatedAt || ''} isLoading={options.isLoading} />
      }
      {isAuthorized && options?.wallets && <DashboardWallets walletsLoading={options.isLoading} />}
      {children}
    </div>
  );
};

export default ModuleLayout;
