import React from 'react';

import CloseIcon from "@assets/images/close-icon.svg";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';

import { Dialog, IconButton, Typography, useMediaQuery } from "@material-ui/core";
import CanvasButton from "./CanvasButton";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dialog: {
        borderRadius: 20,
    },
    root: {
        borderRadius: 20,
        minHeight: 100,
        minWidth: 481,
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 481,
            minWidth: 50,
        },
        position: 'relative',
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #E4E4E4',
        height: 70,
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        padding: '0 24px',
        backgroundColor: '#fff'
    },
    contentWrapper: {
        margin: '27px 0 20px',
        padding: theme.spacing(0, 2),
    },
    actionWrapper: {
      padding: theme.spacing(0, 3, 3, 3),
    },
}));

interface ModalProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    onClose?: () => void;
    content: React.ReactElement;
    options: {
        title: string;
        maxWidth?:  "xs" | "sm" | "md" | "lg" | "xl";
        className?: string;
        style?: React.CSSProperties;
        renderTitle?: React.ReactNode;
        contentStyles?: React.CSSProperties;
        classes?: any;
        action?: {
            title: string;
            onClick?: () => void;
            onSubmit?: () => void;
            loading?: boolean;
        },
    };
}

export default function CanvasModal({ open, setOpen, content, options, onClose }: ModalProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const handleClose = () => {
        if(onClose) {
            onClose();
            return;
        }
        setOpen(false);
    };

    return  <Dialog
        classes={{ root: clsx(classes.dialog, options.className), ...options.classes }}
        fullScreen={fullScreen}
        maxWidth={options.maxWidth}
        open={open}
        scroll={"paper"}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
    >
        <Fade in={open}>
            <form className={classes.root}
                  style={options.style}
                  onSubmit={options?.action?.onSubmit}>
                <div className={classes.titleWrapper}>
                    {options.renderTitle ? options.renderTitle : <Typography variant="h3">{options.title}</Typography>}
                    <IconButton onClick={handleClose}><img src={CloseIcon} alt="close-btn" /></IconButton>
                </div>
                <div className={classes.contentWrapper} style={options.contentStyles}>{content}</div>
                {options?.action?.title && (
                    <div className={classes.actionWrapper}>
                        <CanvasButton loading={options.action.loading}
                                      type={options.action.onSubmit ? 'submit' : 'button'}
                                      fullWidth={true} size="large" onClick={options.action.onClick}>
                            {options.action.title}
                        </CanvasButton>
                    </div>
                )}
            </form>
        </Fade>
    </Dialog>
}
