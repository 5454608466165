import clsx from "clsx";

import "./SortIcon.scss";

interface SortIconProps {
    active: boolean;
    direction: 'asc' | 'desc';
}

export default function SortIcon(props: SortIconProps) {

    const upClasses = clsx(props.active && props.direction === "asc" && "is-active");
    const downClasses = clsx(props.active && props.direction === "desc" && "is-active");

    return (
        <span className={"sort-icon"}>
      <svg className={upClasses} width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.6285 0.538451C4.66072 0.569842 4.7985 0.688367 4.91183 0.798774C5.62461 1.44607 6.79127 3.13465 7.14738 4.01845C7.20461 4.15267 7.32572 4.49201 7.3335 4.67332C7.3335 4.84705 7.2935 5.01266 7.21238 5.1707C7.09905 5.3677 6.92072 5.52573 6.71016 5.61233C6.56405 5.66807 6.12683 5.75466 6.11905 5.75466C5.64072 5.84126 4.8635 5.88889 4.00461 5.88889C3.18627 5.88889 2.44072 5.84126 1.95516 5.77036C1.94739 5.76224 1.40405 5.67565 1.21794 5.58094C0.877941 5.40721 0.66683 5.06787 0.66683 4.70471L0.66683 4.67332C0.675163 4.43681 0.886274 3.93944 0.894052 3.93944C1.25072 3.10326 2.36016 1.45364 3.09738 0.790656C3.09738 0.790656 3.28683 0.603938 3.40516 0.522756C3.57516 0.396112 3.78572 0.333331 3.99627 0.333331C4.23127 0.333331 4.45016 0.40423 4.6285 0.538451Z" fill="#808191"/>
      </svg>
      <svg className={downClasses} width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.3715 5.68378C3.33928 5.65239 3.2015 5.53386 3.08817 5.42346C2.37539 4.77616 1.20873 3.08758 0.852615 2.20378C0.795393 2.06956 0.674282 1.73022 0.666504 1.54891C0.666504 1.37518 0.706504 1.20957 0.787615 1.05153C0.900948 0.854532 1.07928 0.696498 1.28984 0.609904C1.43595 0.554159 1.87317 0.467564 1.88095 0.467564C2.35928 0.38097 3.1365 0.333344 3.99539 0.333344C4.81373 0.333344 5.55928 0.38097 6.04484 0.451869C6.05262 0.459987 6.59595 0.546582 6.78206 0.641294C7.12206 0.815023 7.33317 1.15436 7.33317 1.51752V1.54891C7.32484 1.78542 7.11373 2.28279 7.10595 2.28279C6.74928 3.11897 5.63984 4.76859 4.90262 5.43157C4.90262 5.43157 4.71317 5.61829 4.59484 5.69947C4.42484 5.82612 4.21428 5.8889 4.00373 5.8889C3.76873 5.8889 3.54984 5.818 3.3715 5.68378Z" fill="#808191"/>
      </svg>
    </span>
    );
};
